import React from 'react';
import { Link, withRouter } from 'react-router-dom'
import EventBus from 'eventbusjs';
import * as routes from '../../config/routes';
import * as prices from '../../config/prices';
import { API } from '../../config/config';
import { auth } from '../../firebase/firebase';
import withAuthorization from '../withAuthorization';
import AuthService from '../Authentication/Auth';
import { withTranslation } from 'react-i18next';
import { checkNum } from '../../helpers/Helper';
import BillingForm from '../Sites/BillingForm';

const INITIAL_VALIDATORS = {
	billingname: { isInvalid: false, message: '' },
	billingcompany: { isInvalid: false, message: '' },
	billingemail: { isInvalid: false, message: '' },
	billingaddress: { isInvalid: false, message: '' },
	city: { isInvalid: false, message: '' },
	zip: { isInvalid: false, message: '' },
	einvoice_address: { isInvalid: false, message: '' },
	einvoice_operator: { isInvalid: false, message: '' },
	isValid: true
}

// Set default values for new fields
const DEFAULT_CONTRACT = {
	name: '',
    type: 'domain',
    desc: '',
	published: false,
	billing: 'bill',
	billingmonth: '',
	billingname: '',
	billingcompany: '',
	billingemail: '',
	billingperiod: '12',
	sendingmethod: 'online',
	billingaddress: '',
	billingaddress2: '',
	city: '',
	zip: '',
	reference: '',
	einvoice_address: '',
	einvoice_operator: '',
	monthlyprice: 0.0
}

class EditContract extends React.Component {

	constructor(props) {
		super(props);
		this.validations = { ...INITIAL_VALIDATORS };
		this.submitted = true;
		this.state = {     
			contract: { ...DEFAULT_CONTRACT },
			planprice: 0,
			totalprice: 0,
			error: null,
			success: null,
			confirm: false,
			loading: false
		}
	}

	onSubmit = (e) => {
		e.preventDefault();
		this.setState({ loading: true });
		this.submitted = true;

		let error = {};
		let success = {};
		const { t } = this.props;
		const valid = this.validateForm();
		if (valid) {
			let { contract } = this.state;
			error.message = t("error.unknown")
			success.message = t("custom.saved", {ns: "Admin"})
			
			auth.currentUser.getIdToken().then(idtoken => {
				contract.idtoken = idtoken;
				contract.userid = AuthService.userdata.bonsaituser.id;
				fetch(API + "/billings/custom/update?id=" + contract.id, {
					headers:{
						'Content-Type': 'application/json',
					},
					method: 'POST',
					body: JSON.stringify(contract),
				})
				.then(results => results.json())
				.then(data => {
					if (data.success) {
						this.setState({ success, loading: false })
					} else {
						this.handleErrors(data);
					}
				})
				.catch(() => {
					this.setState({ error, loading: false })
				});
			});
		} else {
			error.message = t("error.validate");
			this.setState({ error, loading: false });
		}
	}


	onDeleteContract = () => {

		this.setState({ confirm: !this.state.confirm });

		const { t } = this.props;
		const { id } = this.state.contract;

        if (confirm(t('custom.confirmdelete', {ns: "Admin"}))) {
            auth.currentUser.getIdToken().then(idtoken => {
                const json = { idtoken: idtoken }
                fetch(API + "/billings/custom?id=" + id, {
                    method: 'DELETE',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(json)
                })
                .then(results => results.json())
                .then(sitedeleted => {
                    if (sitedeleted.success) {
                        this.props.history.push(routes.CONTRACTS_CUSTOM);
                    } else {
                        let error = {};
                        error.message = t("error.unknown");
                        this.setState({ error });
                    }
                });
            });
        }
	}


	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		let copy = {...this.state.contract};
		copy[name] = value;

		if (this.state.contract.billing !== copy.billing || this.state.contract.sendingmethod !== copy.sendingmethod) {
			this.resetValidators();
		}

		// if billingmonth change period also
		if (name=='billingmonth') {
			if (this.countLetter(value, '/')==0) {
				copy.billingperiod = '12'
			} else if (this.countLetter(value, '/')==1) {
				copy.billingperiod = '6'
			} else if (this.countLetter(value, '/')==3) {
				copy.billingperiod = '3'
			} else if (this.countLetter(value, '/')>4) {
				copy.billingperiod = '1'
			}
		}

		this.setState({ contract: copy }, () => {
			if (this.submitted) {
				this.validateForm();
			}
		});
	}

	handleAnyPriceInputChange = (event) => {
		const target = event.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		let copy = {...this.state.contract};
		//value = checkNum(value);
		copy[name] = value;
		this.setState({ contract: copy }, () => {
		});
	}

	validateForm = () => {
		let validators = { ...INITIAL_VALIDATORS }
		const { t } = this.props;
		//const validations = validateBilling(validators, this.state.site, t);
		//this.validations = validations;
		const validations = { ...INITIAL_VALIDATORS }; 
		this.validations = validations;
		this.setState({ validated: true });
		return validations.isValid;
	}

	resetValidators = () => {
		this.validations = { ...INITIAL_VALIDATORS };
		this.setState({ error: null });
	}

    countLetter = (str, letter) => {
		var letter_Count = 0;
		for (var position = 0; position < str.length; position++) {
			if (str.charAt(position) == letter) {
				letter_Count += 1;
			}
		}
		return letter_Count;
	}

    getContract = (contractid) => {
		const isAuthenticated = AuthService.isAuthenticated;
    	const authUser = AuthService.getuser();
        const { id } = authUser.bonsaituser;

        auth.currentUser.getIdToken().then(idtoken => {
            const json = { idtoken: idtoken }
            fetch(API + `/billings/custom/get?id=${contractid}&userid=${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(json)
            })
                .then(results => results.json())
                .then(result => {
                    console.log(result)
                    if (result.success) {
                        const contractobject = Object.assign({ ...DEFAULT_CONTRACT }, result.contract);
                        this.setState({ contract: contractobject, loading: false });
                    } else {
                        this.handleErrors(result);
                    }
                })
                .catch(error => (error));
        });
    }

	componentDidMount() {
		this.submitted = false;

        const { contractid } = this.props.match.params;
        if (contractid) {
            //console.log(contractid)
            this.getContract(contractid);
        }

        // open side menu
		EventBus.dispatch("sidemenu-changed", true);
	}

	render() {

		const authUser = AuthService.getuser();
		const { contract, error, success} = this.state
		const { t } = this.props;
		//const inputprops = { placeholder: t("add_domains") }

		return (
			<React.Fragment>
				
				<div className="container">
					<div className="card mt-4">
						<div className="card-header bg-secondary-dark highlight text-white">
                            {contract.id
                            ? <>{t("custom.edit", {ns: "Admin"})}</>
                            : <>{t("custom.add", {ns: "Admin"})}</>
                            }
                        </div>
						<div className="card-body">
								
                            <form className="contract-admin-form" onSubmit={this.onSubmit}>

                                { error &&
                                    <div className="alert alert-danger mt-3">
                                        {error.message + " "}
                                    </div>
                                }

                                { success &&
                                    <div className="alert alert-success mt-3">
                                        {success.message + " "}
                                    </div>
                                }

                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="form-group">
                                            <label htmlFor="name">{t("custom.name", {ns: "Admin"})}</label>
                                            <input id="name" name="name" value={contract.name} onChange={this.handleInputChange} type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-4 text-right">
                                        <button className="btn btn-outline-danger btn-sm mt-1" type="button" onClick={this.onDeleteContract}>{t("custom.delete", {ns: "Admin"})}</button>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="form-group">
                                            <label htmlFor="name">{t("custom.type", {ns: "Admin"})}</label>
                                            <select id="contract-form-type" className="custom-select" name="type" value={contract.type} onChange={this.handleInputChange}>
                                                <option value="domain">{t("custom.type-domain", {ns: "Admin"})}</option>
                                                <option value="site">{t("custom.type-site", {ns: "Admin"})}</option>
                                                <option value="other">{t("custom.type-other", {ns: "Admin"})}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-8 mb-3">
                                        <div className="form-group">
                                            <label htmlFor="desc">{t("custom.desc", {ns: "Admin"})}</label>
                                            <input id="desc" name="desc" value={contract.desc} onChange={this.handleInputChange} type="text" className="form-control" />
                                        </div>
                                    </div>
                                </div>

                                <React.Fragment>

                                    <BillingForm 
                                        t={t} 
                                        site={contract}
                                        handleInputChange={this.handleInputChange}
                                        handleAnyPriceInputChange={this.handleAnyPriceInputChange}
                                        error={error}
                                        authUser={authUser}
                                        validations={this.validations}
                                        planprice={contract.monthlyprice}
                                        totalprice={contract.monthlyprice}
                                        prices={prices}
                                    />

                                </React.Fragment>

                                <div>
                                    <hr/>
                                    <button className="btn btn-secondary btn-lg mr-2" type="submit">{t("custom.save", {ns: "Admin"})}</button>
                                    <Link exact="true" to={routes.CONTRACTS_CUSTOM} className="btn btn-link" style={{float: "right"}}>{t("custom.cancel", {ns: "Admin"})}</Link>
                                </div>

                                { success &&
                                    <div className="alert alert-success mt-3">
                                        {success.message + " "}
                                    </div>
                                }

                            </form>


                       </div>

					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withAuthorization(withRouter(withTranslation(['Sites', 'Admin'])(EditContract)));
