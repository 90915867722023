import React from 'react';
import { withRouter, Redirect } from 'react-router-dom'
import { withTranslation } from "react-i18next";
import TermsOfService from '../../includes/TermsOfService';
import * as routes from '../../config/routes';
import AuthService from './Auth';
import EventBus from 'eventbusjs';

import { validateActivateAccount } from '../../helpers/Helper';

const ActivateAccountPage = (props) =>
    <div className="container mt-5">
        <ActivateAccountForm {...props} />
    </div>

const INITIAL_VALIDATORS = {
    email: { isInvalid: false, message: '' },
    pwd: { isInvalid: false, message: '' },
    pwd2: { isInvalid: false, message: '' },
    termsofservice: { isInvalid: false, message: '' },
    isValid: true
}

const INITIAL_STATE = {
    email: '',
    pwd: '',
    pwd2: '',
    termsofservice: false,
    modalOpen: true,
    error: null,
    loading: false
};

class ActivateAccountForm extends React.Component {
    constructor() {
        super();
        this.submitted = false;
        this.validations = { ...INITIAL_VALIDATORS }
        this.state = { ...INITIAL_STATE };
    }

    handleInputChange = (event) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        const name = event.target.name;
        this.setState({ [name]: value }, () => {
            if (this.submitted) {
                this.validateForm();
            }
        });
    }

    onSubmit = (e) => { 
        e.preventDefault();

        this.submitted = true;
        const isValid = this.validateForm();

        if (isValid) {
            this.setState({ loading: true });
            const { email, pwd } = this.state;
            const { redirectto } = this.props.location.state;
            const oldUser = AuthService.getolduser();

            const credentials = {
                pwd: pwd,
                id: oldUser.id,
                email: email,
                name: oldUser.name
            }

            AuthService.updateToFirebase(credentials)
            .then(() => {
                this.props.history.push(redirectto);
            })
            .catch(error => {
                this.setState({ error, loading: false });
            });
        } else {
            this.forceUpdate();
        }
    }

    toggleModal = () => {
        this.setState({ modalOpen: !this.state.modalOpen });
    }

    validateForm = () => {
        const { t } = this.props;
        const initialValidations = { ...INITIAL_VALIDATORS }
        const validations = validateActivateAccount(initialValidations, this.state, t)
        this.validations = validations;
        this.setState({ validated: true });
        return validations.isValid;
    }

    componentWillMount() {
        AuthService.redirectToReferer = false;
        EventBus.dispatch("should-redirect", AuthService.redirectToReferer);
    }

    componentDidMount() {
        const oldUser = AuthService.getolduser();
        if (oldUser && oldUser.email) {
            this.setState({ email: oldUser.email });
        }
    }

    render() {

        const { t } = this.props;
        const {
            email,
            pwd,
            pwd2,
            error,
            termsofservice,
            modalOpen,
            loading
        } = this.state;

        const isDisabled = loading;
        const oldUser = AuthService.getolduser();
        let usersites;

        if (oldUser == null) {
            return <Redirect to={routes.SIGN_IN} />
        }

        if (oldUser.usersites && oldUser.usersites.length > 0) {
            usersites =
            <div>
                <small className="form-text text-muted mb-3"><strong>Huom!</strong> Sähköpostiosoitteellasi löytyi useita käyttäjätunnuksia. Seuraavien sivustojen käyttöoikeudet yhdistetään:</small>
                <ul style={{ fontSize: '0.9em', paddingLeft: '15px' }}>
                { 
                    oldUser.usersites.map((site, i) => {
                        return <li className="text-muted" key={i}>{site.domains}</li>
                    })
                }
                </ul>
            </div>
        }

        return (
            <div style={{ maxWidth: '350px', margin: 'auto' }}>
                <TermsOfService modalOpen={modalOpen} toggleModal={this.toggleModal} t={t} oldUser={oldUser}/>
                <h3 className="mb-3">{t("create_new_password")}</h3>
                <form onSubmit={this.onSubmit}>
                    
                    {usersites}

                    <div className="form-group">
                        <label htmlFor="email">{t("email")}</label>
                        <input id="email" name="email" value={email} onChange={this.handleInputChange} type="email" className="form-control" />
                        { this.validations.email.isInvalid && <span className="srv-validation-message slideanim slide">{this.validations.email.message}</span> }
                    </div>

                    <div className="form-group">
                        <label htmlFor="pwd">{t("password")}</label>
                        <input id="pwd" name="pwd" value={pwd} onChange={this.handleInputChange} type="password" className="form-control" />
                        { this.validations.pwd.isInvalid && <span className="srv-validation-message slideanim slide">{this.validations.pwd.message}</span> }
                    </div>
                    <div className="form-group">
                        <label htmlFor="pwd2">{t("password_again")}</label>
                        <input id="pwd2" name="pwd2" value={pwd2} onChange={this.handleInputChange} type="password" className="form-control" />
                        { this.validations.pwd2.isInvalid && <span className="srv-validation-message slideanim slide">{this.validations.pwd2.message}</span> }
                    </div>
                    <div className="form-group">
                        <div className="custom-control custom-checkbox">
                            <input id="termsofservice" type="checkbox" className="custom-control-input" name="termsofservice" onChange={this.handleInputChange} checked={termsofservice} />
                            <label className="custom-control-label mr-1" htmlFor="termsofservice">{t("accept") + " "}</label>
                            <a href="https://www.bonsait.fi/sopimusehdot" rel="noopener noreferrer" target="_blank">{t("terms")}</a>
                        </div>
                        { this.validations.termsofservice.isInvalid && <span className="srv-validation-message slideanim slide">{this.validations.termsofservice.message}</span> }
                    </div>
                    <small className="form-text text-muted mb-3">{t("email_info_txt")}</small>
                    <button className="btn btn-primary btn-block" disabled={isDisabled} type="submit">
                    { loading 
                        ?  <i className="fa-solid fa-spinner fa-spin text-white"></i>
                        : <span>{t("activate_newuser")}</span>
                    }
                    </button>
                    { error && 
                        <div className="alert alert-danger mt-3">{error.message}</div> 
                    }                  
                </form>
            </div>
        );
    }
}

export default withTranslation("Authentication")(withRouter(ActivateAccountPage));
