import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import AuthService from '../Authentication/Auth';
import { API } from '../../config/config';
import { auth } from '../../firebase/firebase';
import * as routes from '../../config/routes';


const INITIAL_STATE = {
    error: null,
    success: false,
    domain: ''
}

const AcceptInvitePage = (props) =>
    <div className="container mt-5">
        <AcceptInvite {...props} />
    </div>

class AcceptInvite extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE }
    }

    handleErrors = (result) => {
        const { t } = this.props;
        let error = {};

        switch (result.message) {
            case 'Invite link expired':
                error.message = t("invite.expired")
                break;
            case 'Authentication failed':
                error.message = t("invite.auth_error")
                break;
            case 'Invite not found':
                error.message = t("invite.notfound")
                break;
            default:
                error.message = t("error_unkown")
        }
        
        this.setState({ error });
    }

    acceptInvite = (token) => {
        auth.currentUser.getIdToken().then(idtoken => {
            const formdata = {
                token: token,
                idtoken: idtoken
            }
            fetch(API + "/acceptinvite", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formdata),
            })
            .then(results => results.json())
            .then(result => {
                if (result.success) {
                    this.setState({ success: true });
                    if (result.domain) {
                        this.setState({ domain: result.domain });
                    }
                } else {
                    this.handleErrors(result);
                }
            })
            .catch(error => console.log(error));
        }); 
    }

    checkQS = () => {
        const qs = this.props.location.search;
        if (qs !== "") {
            const token = qs.split("=");
            if (token[0] === '?token' && AuthService.isAuthenticated) {
                this.acceptInvite(token[1]);
            }
        }
    }

    componentDidMount() {
        this.checkQS();
    }

    componentDidUpdate(prevProps) {
        const { isAuthenticated } = this.props;
        if (prevProps.isAuthenticated !== isAuthenticated) {
            this.checkQS();
        }
    }

    render() { 

        const { error, success, domain } = this.state;
        const { t } = this.props;

        if (error) {
            return (
                <div className="text-center">
                    <i className="fa-solid fa-exclamation-circle fa-4x mb-4 text-danger"></i>
                    <h4>{error.message}</h4>
                </div>
            );
        }

        if (AuthService.isAuthenticated && success) {
            return (
                <div className="text-center">
                    <i className="fa-solid fa-check fa-4x mb-4 text-success"></i>
                    <h4>{t("invite.accepted")}</h4>
                    { domain &&
                        <a href={`http://${domain}/login`} target="_blank" rel="noopener noreferrer" className="btn btn-primary mt-3">{t("editsite")}</a>
                    }
                    <Link to={routes.WEBSITES} className="btn btn-link mt-3 ml-2">{t("browse_sites")}</Link>
                </div>
            );
        }
        
        if (!AuthService.isAuthenticated) {
            return <Redirect to={{ 
                pathname: routes.SIGN_IN, 
                state: {
                    redirectto: routes.ACCEPT_INVITE + this.props.location.search
                } }} />
        }

        return <div></div>
        
    }
}
 
export default withRouter(withTranslation("Authentication")(AcceptInvitePage));