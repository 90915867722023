import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

let config = {
    apiKey: "AIzaSyC9A4sdu8oPXWM1zsOgHAfhKJRxStD7rgo",
    authDomain: "bonsait-eb743.firebaseapp.com",
    databaseURL: "https://bonsait-eb743.firebaseio.com",
    projectId: "bonsait-eb743",
    storageBucket: "bonsait-eb743.appspot.com",
    messagingSenderId: "806858489951",
    appId: "1:806858489951:web:00af7f76af8f4939"
};

if (window.location.hostname && window.location.hostname.indexOf('localhost')>-1) {
    config = {
        apiKey: "AIzaSyBoScMx4eskFhvxKxY-Pkq_R69k2wMDRT0",
        authDomain: "bonsait-dev.firebaseapp.com",
        databaseURL: "https://bonsait-dev.firebaseio.com",
        projectId: "bonsait-dev",
        storageBucket: "bonsait-dev.appspot.com",
        messagingSenderId: "466151278601",
        appId: "1:466151278601:web:3529256c1f6e6564"
    };
}

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

let messaging = null;
if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
}

const firebaseapp = firebase;
const auth = firebase.auth();
const db = firebase.firestore();

export {
    auth,
    messaging,
    firebaseapp,
    db
};
