import React from 'react';
import { Redirect, withRouter, Link} from 'react-router-dom'
import { withTranslation } from "react-i18next";
import { API } from '../../config/config';
import { auth } from '../../firebase/firebase';

import * as routes from '../../config/routes';

import EventBus from 'eventbusjs';
import Loader from '../../includes/Loader';
import withAuthorization from '../withAuthorization';
import AuthService from '../Authentication/Auth';

class BillingCustom extends React.Component {

	constructor(props) {
        super(props);
        this.state = {
            loading: false,
			contracts: []
		}
    }

	fetchContracts = () => {

        this.setState({ 
            loading: true 
        });

        auth.currentUser.getIdToken().then(idtoken => {
            const json = { idtoken: idtoken }
            fetch(API + "/billings/custom/list", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(json)
            })
            .then(results => results.json())
            .then(result => {
                this.setState({ 
                    contracts: result.contracts,
                    loading: false 
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
        });
    }

	// Create our number formatter.
	formatter = new Intl.NumberFormat('fi', {
		style: 'currency',
		currency: 'EUR',
		// These options are needed to round to whole numbers if that's what you want.
		//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
		//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	});

    calcPrice = (contract) => {
		let price = contract.monthlyprice*contract.billingperiod
		return this.formatter.format(price)
    }

	calcTotalPrice = () => {
		let total = 0.00;
		this.state.contracts.forEach(contract => {
			total = total + (contract.monthlyprice * contract.billingperiod);
		});
		return total
    }

	componentDidMount() {
        this.fetchContracts();
		// open side menu
		EventBus.dispatch("sidemenu-changed", true);
    }

	render() {
		const authUser = AuthService.getuser();
		const { loading, contracts} = this.state
		const { t } = this.props;

		return (
			<>
				<div className="container">
					<div className="card mt-4">
						<div className="card-header bg-secondary-dark highlight text-white">{t("custom.title")}</div>
						<div className="card-body">
                            <div className="mb-3">
                                <Link to={routes.CONTRACTS_NEW} className="btn btn-default btn-sm">{t("custom.add")}</Link>
                            </div>

							{loading 
							? 
								<div className="text-center">
									<Loader size="3x" classes="text-primary" />
								</div>
							: 
								<>
								{(contracts) ?
									<div className="billinglist">

										<div className="row mb-1">
											<div className="col-md-4"><strong>{t("custom.name")}</strong></div>
                                            <div className="col-md-3"><strong>{t("custom.customer")}</strong></div>
                                            <div className="col-md-2"><strong>{t("custom.type")}</strong></div>
                                            <div className="col-md-3 text-right"><strong>{t("contractbilling.price")}</strong></div>
										</div>
										<hr />
										{contracts.map((contract, i) => {
											return (
												<div key={i}>
													<div className="row mb-4">
														<div className="col-md-4">
															<Link className="title-link" to={routes.CONTRACTS_EDIT + `/${contract.id}`}>{contract.name}</Link><br/>
                                                            <small>{t("contractbilling.billingmonth")} {contract.billingmonth}</small>
														</div>
                                                        <div className="col-md-3">
															{contract.billingname}<br/>
															<small>{contract.billingcompany}</small>
														</div>
                                                        <div className="col-md-2">
                                                            {t("custom.type-"+contract.type)}
														</div>
														<div className="col-md-3 text-right">
                                                            {this.calcPrice(contract)}<br/>
															<small>{this.formatter.format(contract.monthlyprice)} * {contract.billingperiod} kk</small>
														</div>
													</div>
													<hr />
												</div>
											)
										})}

                                        <div className="row mb-1 ">
											<div className="col-md-8"></div>
											<div className="col-md-2 text-right">
												{t("contractbilling.total")}<br/>
												{t("contractbilling.vat")}<br/>
												<strong>{t("contractbilling.totalwithvat")}</strong>
											</div>
											<div className="col-md-2 text-right">
												{this.formatter.format(this.calcTotalPrice())}<br/>
												{this.formatter.format((this.calcTotalPrice()/100)*24)}<br/>
												{this.formatter.format(this.calcTotalPrice()*1.24)}
											</div>
										</div>

									</div>
                                : <p className="mt-3">{t("custom.nocontracts")}</p>
								}
								</>
							}

						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withAuthorization(withRouter(withTranslation("Admin")(BillingCustom)));