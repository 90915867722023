import React from 'react';
import { withRouter } from 'react-router-dom'
import withAuthorization from './withAuthorization';
import { withTranslation } from 'react-i18next';
import { API } from '../config/config';
import EmployeeList from './EmployeeList';
import { auth } from '../firebase/firebase';
import withLoading from './withLoading';
import * as routes from '../config/routes';
import langcodes from '../config/language-codes';
import AuthService from './Authentication/Auth';
import EventBus from 'eventbusjs';

const ListWithLoading = withLoading(EmployeeList);

class ResellerPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            employees: null,
            company: AuthService.userdata.bonsaituser.company,
            defaultdomain: AuthService.userdata.bonsaituser.defaultdomain,
            defaultsitelang: AuthService.userdata.bonsaituser.defaultsitelang,
            email: '',
            employeeError: null,
            managerdataError: null,
            loading: true
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value });
    }

    deleteEmployee = (id, index) => {
        const { t } = this.props;

        let agreed = false;
        const r = window.confirm(t("confirm_delete"));
        if (r === true) {
            agreed = true;
        } else {
            agreed = false;
        }

        if (agreed) {
            let userid = "";
            if (id) {
                userid = id;
            }
            // Empty users manager field here
            auth.currentUser.getIdToken().then(idtoken => {
                const json = { idtoken: idtoken }
                fetch(API + "/managersusers?userid=" + userid, {
                    headers:{
                        'Content-Type': 'application/json',
                    },
                    method: 'DELETE',
                    body: JSON.stringify(json)
                })
                .then(results => results.json())
                .then(result => {
                    if (result.type === 'user') {
                        const employees = [...this.state.employees]; // make a separate copy of the employees
                        employees.splice(index, 1);
                        this.setState({ employees });
                    } else if (result.type === 'temp') {
                        const tempusers = [...this.state.tempusers]; // make a separate copy of the tempusers
                        tempusers.splice(index, 1);
                        this.setState({ tempusers });
                    }
                })
                .catch(error => console.log(error));
            });
        }
    }

    onAddEmployee = (e) => {
        e.preventDefault();

        this.setState({ loading: true });
        const { t } = this.props;
    
        auth.currentUser.getIdToken().then(idtoken => {
            const { email } = this.state;
            const formdata = {
                accessto: email,
                idtoken: idtoken
            }
            fetch(API + "/managersusers", {
                headers:{
                    'Content-Type': 'application/json',
                },
                method: 'PUT',
                body: JSON.stringify(formdata),
            })
            .then(results => results.json())
            .then(result => {
                if (result.success) {
                    this.fetchUsers();
                } else {
                    let employeeError = {};
                    if (result.error === 'HAS_ACCESS') {
                        employeeError.message = t("error.hasaccess")
                    } else {
                        employeeError.message = t("error.general")
                    }
                    this.setState({ employeeError, loading: false });
                }
            })
            .catch(error => {
                console.log(error)
                this.setState({ loading: false });
            });
        });
    }

    onUpdateUser = (e) => {
        e.preventDefault();
        const { company, defaultdomain, defaultsitelang } = this.state;
        const { history, t } = this.props;
        
        auth.currentUser.getIdToken().then(idtoken => {
            const managerinfo = {
                company: company,
                defaultdomain: defaultdomain,
                defaultsitelang: defaultsitelang,
                idtoken: idtoken
            }
            return fetch(API + "/editmanager", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(managerinfo)
            })
        })
        .then(results => results.json())
        .then(result => {
            if (result.success) {
                AuthService.userdata.bonsaituser.company = company;
                AuthService.userdata.bonsaituser.defaultdomain = defaultdomain;
                AuthService.userdata.bonsaituser.defaultsitelang = defaultsitelang;
                EventBus.dispatch("userdata-changed", true);
                history.push(routes.WEBSITES);
            } else {
                const managerdataError = { message: t("errormsg") }
                this.setState({ managerdataError });
            }
        });
    }

    fetchUsers = () => {
        auth.currentUser.getIdToken().then(idtoken => {
            const json = { idtoken: idtoken }
            fetch(API + "/managersusers", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(json)
            })
            .then(results => results.json())
            .then(result => {
                this.setState({ 
                    employees: result.subordinates, 
                    tempusers: result.tempusers,
                    loading: false 
                }) 
            })
            .catch(error => {
                console.log(error)
                this.setState({ loading: false });
            });
        });
    }
    
    componentDidMount() {
        this.fetchUsers();

        // open side menu
		EventBus.dispatch("sidemenu-changed", true);
    }

    render() { 

        const { t } = this.props;
        const { employees, tempusers, loading, managerdataError, employeeError } = this.state;
        const authUser = AuthService.getuser();

        if (!authUser.bonsaituser) return null;

        return (
            <div className="container mt-4">
                <div className="row">
                    <div className="col-md-6">
                        <div className="card mb-4">
                            <div className="card-header bg-secondary-dark highlight text-white">
                                {t("edit_manager_info")}
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.onUpdateUser}>
                                    <div className="form-group">
                                        <label htmlFor="company">{t("company")}</label>
                                        <input id="company" name="company" onChange={this.handleInputChange} type="text" className="form-control" defaultValue={AuthService.userdata.bonsaituser.company} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="defaultdomain">{t("defaultdomain")}</label>
                                        <input id="defaultdomain" name="defaultdomain" onChange={this.handleInputChange} type="text" className="form-control" defaultValue={AuthService.userdata.bonsaituser.defaultdomain} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="title">{t("defaultsitelang")}</label>
                                        <select id="site-form-defaultsitelang" className="custom-select" name="defaultsitelang" onChange={this.handleInputChange} defaultValue={AuthService.userdata.bonsaituser.defaultsitelang}>
                                            {
                                                langcodes.map((langcode, index) => {
                                                    return (
                                                        <option key={index} value={langcode.code}>{langcode.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    { managerdataError && 
                                        <div className="alert alert-danger mt-3">{managerdataError.message}</div> 
                                    }
                                    <button className="btn btn-primary mr-1">{t("save")}</button>
                                    <button className="btn btn-link float-right" type="button" onClick={() => this.props.history.goBack(-1)}>{t("cancel")}</button> 
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="card">
                            <div className="card-header bg-secondary-dark highlight text-white">{t("add_employees")}</div>
                            <div className="card-body">
                                <form onSubmit={this.onAddEmployee}>
                                    <div className="form-group">
                                        <div className="input-group mb-3">
                                            <input id="email" name="email" type="email" className="form-control" onChange={this.handleInputChange} placeholder={t("email")} required />
                                            <div className="input-group-append">
                                                <button className="btn btn-primary" type="submit">{t("add")}</button>
                                            </div>
                                        </div>
                                        { employeeError && 
                                            <div className="alert alert-danger mt-3">{employeeError.message}</div> 
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <ListWithLoading isLoading={loading} employees={employees} tempusers={tempusers} t={t} delete={this.deleteEmployee} />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default withAuthorization(withRouter(withTranslation("Employees")(ResellerPage)));
