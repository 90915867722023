export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const SITEAUTH = '/sitelogin';
export const LANDING = '/landing';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/forgotten-password';
export const RESET_PASSWORD = '/resetpassword';
export const UPDATE_BILLING = '/updatebilling';
export const WEBSITES = '/sites';
export const WEBSITE = '/site/:siteid';
export const WEBSITE_HOME = '/site';
export const RESELLER = '/reseller-account';
export const SITEITEM = '/websites/:sitename';
export const PUBLISH = '/websites/publish/:siteid';
export const PUBLISH_HOME = '/websites/publish';
export const ACCEPT_INVITE = '/accept-invite';
export const NEW_WEBSITE = '/create-website';
export const ACTIVATE_ACCOUNT = '/activate-account';
export const START_TRIAL = '/start-trial';
export const TRIAL_REGISTER = '/register-trial';
export const NEW_DOMAIN_PLAIN = '/new-domain';
export const NEW_DOMAIN = '/new-domain/:siteid';
export const CONNECT_DOMAIN_PLAIN = '/connect-domain';
export const CONNECT_DOMAIN = '/connect-domain/:siteid';
export const DOMAINS = '/domains/';
export const DOMAIN = '/domains/:domainid';
export const CONTRACTS = '/contracts/';
export const CONTRACTS_STATS = '/contracts/stats';
export const CONTRACTS_FREEBIRDS = '/contracts/freebirds';
export const CONTRACTS_SMS = '/contracts/sms';
export const CONTRACTS_PROVISION = '/contracts/provision';
export const CONTRACTS_CUSTOM = '/contracts/custom';
export const CONTRACTS_EDIT = '/contracts/editcontract';
export const CONTRACTS_NEW = '/contracts/editcontract/:contractid';
export const CONTRACT = '/contracts/:contractid';
