import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import '../css/App.css';

import * as routes from '../config/routes';
import LandingPage from './LandingPage';
import SignInPage from './Authentication/SignInPage';
import SignUpPage from './Authentication/SignUpPage';
import AccountPage from './Users/AccountPage';
import ForgottenPwd from './Authentication/ForgottenPwd';
import Websites from './Sites/WebsitesPage';
import Website from './Sites/WebsitePage';
import Billing from './Admin/Billing';
import NewDomain from './Domains/NewDomain';
import ConnectDomain from './Sites/ConnectDomain';
import ResellerPage from './ResellerPage';
import NewWebsite from './Sites/NewWebsitePage';
import Site from './Sites/Site';
import Header from '../includes/Header';
import SideNav from '../includes/SideNav';
import appHOC from './appHOC';
import PublishPage from './Sites/PublishPage';
import AcceptInvitePage from './Sites/AcceptInvite';
import ActivateAccountPage from './Authentication/ActivateAccount';
import StartTrial from './Trial/StartTrial';
import ComponentNotfound from './404';
import SiteAuthentication from './Authentication/SiteAuthentication';
import ResetPassword from './Authentication/ResetPassword';
import UpdateBilling from './Users/UpdateBilling';
import BillingStats from './Admin/BillingStats';
import WithoutBilling from './Admin/WithoutBilling';
import BillingSMS from './Admin/BillingSMS';
import BillingCustom from './Admin/BillingCustom';
import EditContract from './Admin/EditContract';
import Provision from './Admin/Provision';

const App = (props) => {
    const { isLoading } = props;

    if (isLoading) {
        return <div></div>
    }
    
    return (
        <Router>
            <div>
                <SideNav {...props} />
                <div className={props.menuOpen ? "sidenav-margin main-content pb-5" : "main-content pb-5"}>
                    <Header {...props} />
                    <Switch>
                        <Route exact path="/" render={() => <Redirect to={routes.WEBSITES}/>} />
                        <Route exact path={routes.LANDING} component={LandingPage} />
                        <Route exact path={routes.SIGN_IN} component={SignInPage} />
                        <Route exact path={routes.SIGN_UP} render={() => <SignUpPage {...props} />} />
                        <Route exact path={routes.ACCOUNT} render={AccountPage} />
                        <Route exact path={routes.PASSWORD_FORGET} component={ForgottenPwd} />
                        <Route exact path={routes.RESET_PASSWORD} component={ResetPassword} />
                        <Route exact path={routes.UPDATE_BILLING} render={UpdateBilling} />
                        <Route exact path={routes.RESELLER} component={ResellerPage} />
                        <Route exact path={routes.NEW_WEBSITE} component={NewWebsite} />
                        <Route exact path={routes.SITEITEM} component={Site} />
                        <Route exact path={routes.PUBLISH} component={PublishPage} />
                        <Route exact path={routes.ACTIVATE_ACCOUNT} component={ActivateAccountPage} />
                        <Route exact path={routes.START_TRIAL} component={StartTrial} />
                        <Route exact path={routes.ACCEPT_INVITE} render={() => <AcceptInvitePage {...props} />} />
						<Route exact path={routes.NEW_DOMAIN} render={NewDomain} />
						<Route exact path={routes.CONNECT_DOMAIN} render={ConnectDomain} />
                        <Route exact path={routes.CONTRACTS} component={Billing} />
                        <Route exact path={routes.CONTRACTS_STATS} component={BillingStats} />
                        <Route exact path={routes.CONTRACTS_SMS} component={BillingSMS} />
                        <Route exact path={routes.CONTRACTS_PROVISION} component={Provision} />
                        <Route exact path={routes.CONTRACTS_FREEBIRDS} component={WithoutBilling} />
                        <Route exact path={routes.CONTRACTS_CUSTOM} component={BillingCustom} />
                        <Route exact path={routes.CONTRACTS_NEW} component={EditContract} />
                        <Route exact path={routes.WEBSITE} render={Website} />
						<Route exact path={routes.WEBSITES} render={() => <Websites {...props} />} />
                        <Route exact path={routes.SITEAUTH} render={() => <SiteAuthentication {...props} />} />
                        <Route component={ComponentNotfound} />
                    </Switch>
                </div>
            </div>
        </Router>
    );
}
	

export default appHOC(App);
