import React from 'react';

const Loader = (props) => {

    const { size, classes } = props;

    return (
        <div className="text-center">
            <i className={'fa-solid fa-spinner fa-spin fa-' + {size} + ' ' + {classes}}></i>
        </div>
    );
}


export default Loader;