import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Trans } from 'react-i18next';

const TermsOfService = (props) =>
    <Modal isOpen={props.modalOpen} toggle={props.toggleModal} size="md">
        <ModalHeader toggle={props.toggleModal}>
            <Trans i18nKey="termsofservice.heading">
                Hei {props.oldUser.name}
            </Trans>
        </ModalHeader>
        <ModalBody style={{fontSize: '0.9em'}}>
            <p>{props.t("termsofservice.text")}</p>
            <p>{props.t("termsofservice.info")} <a href="https://www.bonsait.fi/uudetominaisuudet" target="_blank" rel="noopener noreferrer">bonsait.fi/uudetominaisuudet</a> {props.t("termsofservice.infocontinued")} <a href="https://www.bonsait.fi/tuotetuki" rel="noopener noreferrer">bonsait.fi/tuotetuki</a></p>
            <p>{props.t("termsofservice.regards")}</p>
        </ModalBody>
        <ModalFooter>
            <button className="btn btn-default" onClick={props.toggleModal}>{props.t("close")}</button>
        </ModalFooter>
    </Modal>

export default TermsOfService;