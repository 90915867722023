import React from 'react';

const Plans = (props) => {

    const { account, t, prices, authUser } = props;

    return (
        <div>
            <div className="account-table text-center">
                <div className="row">
					{/*<div className="col-lg-3 col-md-6">
                        <div className={account === "XS" ? "card plan-card active mb-3" : "card plan-card mb-3"} style={{minHeight: "400px", cursor: "pointer"}} onClick={() => props.handlePlan("XS")}>
                            <div className={account === "XS" ? "plan selected card-header bg-primary text-white" : "plan card-header bg-light-gray"}>
                                <p className="m-0">Bonsait-XS</p>
                                <span>{prices.XS} €</span><small> / {t("billing.months")}</small>
                            </div>
                            <ul className="list-group list-group-flush" style={{fontSize: "0.85rem"}}>
                                <li className="list-group-item p-2">{t("plan.cms")}</li>
								<li className="list-group-item p-2">{t("plan.ssl")}</li>
                                <li className="list-group-item p-2">1 {t("plan.lang")}</li>
                                <li className="list-group-item p-2">{t("plan.support")}</li>
                            </ul>
                        </div>
                    </div>*/}
                    <div className="col-lg-4 col-md-6">
                        <div className={account === "S" ? "card plan-card active mb-3" : "card plan-card mb-3"} style={{minHeight: "400px", cursor: "pointer"}} onClick={() => props.handlePlan("S")}>
                            <div className={account === "S" ? "plan selected card-header bg-primary text-white" : "plan card-header bg-light-gray"}>
                                <p className="m-0">Bonsait-S</p>
                                <span>{prices.S} €</span><small> / {t("billing.months")}</small>
                            </div>
                            <ul className="list-group list-group-flush" style={{fontSize: "0.85rem"}}>
                                <li className="list-group-item p-2">{t("plan.cms")}</li>
								<li className="list-group-item p-2">{t("plan.ssl")}</li>
								<li className="list-group-item p-2">{t("plan.blog")}</li>
								<li className="list-group-item p-2">1 {t("plan.lang")}</li>
                                <li className="list-group-item p-2">{t("plan.support")}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 center">
                        <div className={account === "M" ? "card plan-card active mb-3" : "card plan-card mb-3"} style={{minHeight: "400px", cursor: "pointer"}} onClick={() => props.handlePlan("M")}>
                            <div className={account === "M" ? "plan selected card-header bg-primary text-white" : "plan card-header bg-light-gray"}>
                                <p className="m-0">Bonsait-M</p>
                                <span>{prices.M} €</span><small> / {t("billing.months")}</small>
                            </div>
                            <ul className="list-group list-group-flush" style={{fontSize: "0.85rem"}}>
                                <li className="list-group-item p-2">{t("plan.cms")}</li>
								<li className="list-group-item p-2">{t("plan.ssl")}</li>
                                <li className="list-group-item p-2">{t("plan.register")}</li>
                                <li className="list-group-item p-2">{t("plan.intra")}</li>
                                <li className="list-group-item p-2">{t("plan.blog")}</li>
								{/*<li className="list-group-item p-2">{t("plan.faas")}</li>*/}
                                <li className="list-group-item p-2">2 {t("plan.langmulti")}</li>
                                <li className="list-group-item p-2">{t("plan.supportprio")}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className={account === "L" ? "card plan-card active mb-3" : "card plan-card mb-3"} style={{minHeight: "400px", cursor: "pointer"}} onClick={() => props.handlePlan("L")}>
                            <div className={account === "L" ? "plan selected card-header bg-primary text-white" : "plan card-header bg-light-gray"}>
                                <p className="m-0">Bonsait-L</p>
                                <span>{prices.L} €</span><small> / {t("billing.months")}</small>
                            </div>
                            <ul className="list-group list-group-flush" style={{fontSize: "0.85rem"}}>
                                <li className="list-group-item p-2">{t("plan.cms")}</li>
								<li className="list-group-item p-2">{t("plan.ssl")}</li>
                                <li className="list-group-item p-2">{t("plan.newsletters")}</li>
                                <li className="list-group-item p-2">{t("plan.register")}</li>
                                <li className="list-group-item p-2">{t("plan.intra")}</li>
								<li className="list-group-item p-2">{t("plan.blog")}</li>
								{/* <li className="list-group-item p-2">{t("plan.faas")}</li>*/}
                                <li className="list-group-item p-2">{t("plan.langunlimit")}</li>
                                <li className="list-group-item p-2">{t("plan.supportprio")}</li>
                            </ul>
                        </div>
                    </div>
					{/* 
                    <div className="col-lg-3 col-md-6">
                        <div className={account === "XL" ? "card plan-card active mb-3" : "card plan-card mb-3"} style={{minHeight: "400px", cursor: "pointer"}} onClick={() => props.handlePlan("XL")}>
                            <div className={account === "XL" ? "plan selected card-header bg-primary text-white" : "plan card-header bg-light-gray"}>
                                <p className="m-0">Bonsait-XL</p>
                                <span>{prices.XL} €</span><small> / {t("billing.months")}</small>
                            </div>
                            <ul className="list-group list-group-flush" style={{fontSize: "0.85rem"}}>
								{authUser.bonsaituser.role !== 'user' && 
								<li className="list-group-item p-2">{t("plan.commission")} <strong>{prices.DEFAULT_PROFIT_XL} €</li>
								}
                                <li className="list-group-item p-2">{t("plan.cms")}</li>
								<li className="list-group-item p-2">{t("plan.ssl")}</li>
                                <li className="list-group-item p-2">{t("plan.webshop")}</li>
                                <li className="list-group-item p-2">{t("plan.newsletters")}</li>
                                <li className="list-group-item p-2">{t("plan.register")}</li>
                                <li className="list-group-item p-2">{t("plan.intra")}</li>
                                <li className="list-group-item p-2">{t("plan.blog")}</li>
                                <li className="list-group-item p-2">{t("plan.langunlimit")}</li>
                                <li className="list-group-item p-2">{t("plan.supportprio")}</li>
                            </ul>
                        </div>
                    </div>
					*/}
                </div>
            </div>
        </div>
    );
}

export default Plans;