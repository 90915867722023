import React from 'react';
import { withTranslation } from 'react-i18next';
import withAuthorization from '../withAuthorization';
import withLoading from '../withLoading';
import WebsiteList from './WebsiteList';
import SubHeader from '../../includes/SubHeader';
import { API } from '../../config/config';
import { auth } from '../../firebase/firebase';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DatePicker from 'react-date-picker';
import AuthService from '../Authentication/Auth';
import moment from 'moment';
import defPageImg from '../../images/default.jpg';
import EventBus from 'eventbusjs';

const Sitelist = withLoading(WebsiteList);

class Websites extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {
                total: 0,
                totalunpublished: 0,
                found: 0,
                sites: [],
                sitestoaccess: []
            },
            loading: false,
            showmore: false,
            error: null,
            keywordfilter: '',
            sortby: 'created',
			viewtype: 'cards',
            reverse: false,
            publishedfilter: false,
            doublesfilter: false,
            viewDropdownOpen: false,
			sortDropdownOpen: false,
			limitDropdownOpen: false,
            advancedSearch: false,
            pStartdate: '',
            pEnddate: '',
            cStartdate: '',
            cEnddate: '',
            account: '',
            billingperiod: '',
			limit: 21,
        }
    }

    onLoadSites = () => {
        this.setState({ showmore: false });
        let start = 0
        this.fetchSites(start, this.state.limit, true);
    }

    onLoadMore = () => {
        this.setState({ showmore: false });
        const { data } = this.state;
        let start = data.sites.length;
        let limit = data.sites.length + this.state.limit;
        this.fetchSites(start, limit, false);
    }

    fetchSites = (start, limit, replace) => {
        let { publishedfilter, doublesfilter, keywordfilter, sortby, reverse, pStartdate, pEnddate, cStartdate, cEnddate, account, billingperiod } = this.state;

        auth.currentUser.getIdToken().then(idtoken => {
            const json = { idtoken: idtoken }
            fetch(API + "/sites?start=" + start + "&limit=" + limit + "&search=" + keywordfilter + "&showonlyunpublished=" + publishedfilter + "&showdoubles=" + doublesfilter + "&pstart=" + this.formatDate(pStartdate) + "&pend=" + this.formatDate(pEnddate) + "&cstart=" + this.formatDate(cStartdate) + "&cend=" + this.formatDate(cEnddate) + "&account=" + account + "&billingperiod=" + billingperiod + "&sortby=" + sortby + "&reverse=" + reverse, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(json)
            })
            .then(results => results.json())
            .then(result => {
                this.handleResult(result, start, limit, replace);
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
        });
    }

    handleResult = (result, start, limit, replace) => {
        const total = start + limit;
        let data = { ...this.state.data }
        data.total = result.total;
        data.totalunpublished = result.totalunpublished;
        data.found = result.found;

        if (replace) {
            data.sites = result.sites;
            data.sitestoaccess = result.sitestoaccess;
        } else {
            Array.prototype.push.apply(data.sites, result.sites);
            Array.prototype.push.apply(data.sitestoaccess, result.sitestoaccess);
        }

        if (data.sites.length < total) {
            this.setState({ showmore: false });
        } else {
            this.setState({ showmore: true });
        }

        this.setState({ data, loading: false });

    }

    handleInputChange = (e) => {
        this.setState({ 
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value },
        () => this.onLoadSites());
    }

    handlepStart = value => this.setState({ pStartdate: value }, () => this.onLoadSites());
    handlepEnd = value => this.setState({ pEnddate: value }, () => this.onLoadSites());
    handlecStart = value => this.setState({ cStartdate: value }, () => this.onLoadSites());
    handlecEnd = value => this.setState({ cEnddate: value }, () => this.onLoadSites());

    handleImageError = (e) => {
        return e.target.src = defPageImg;
    }

    formatDate = (date) => {
        let formatted = "";
        if (moment(date).isValid()) {
            const momentdate = moment(date, moment.ISO_8601);
            formatted = moment(momentdate).format("DD.MM.YYYY");
        }
        return formatted;
    }

	toggleLimitDropdown = () => {
        this.setState({
            limitDropdownOpen: !this.state.limitDropdownOpen
        });
    }

	handleLimit = (limit) => {
        this.setState({ limit }, () => {
			localStorage.setItem('viewlimit', limit);
            this.onLoadSites();
        });
    }

	toggleViewDropdown = () => {
        this.setState({
            viewDropdownOpen: !this.state.viewDropdownOpen
        });
    }

	handleView = (viewtype) => {
        this.setState({ viewtype }, () => {
			localStorage.setItem('viewtype', viewtype);
            this.onLoadSites();
        });
    }

    toggleSortDropdown = () => {
        this.setState({
            sortDropdownOpen: !this.state.sortDropdownOpen
        });
    }

    handleSort = (sortby, reverse) => {
        this.setState({ sortby, reverse }, () => {
            this.onLoadSites();
        });
    }

    componentDidMount() {

        this.setState({ loading: true });
        let keyword = '';
        
        if (this.props.keyword) {
            keyword = this.props.keyword;
        }

		let vt = localStorage.getItem('viewtype');
		if (!vt) {
			vt = 'cards'
		}
		this.setState({ viewtype: vt });

        let newlimit = 21;
		let ltstr = localStorage.getItem('viewlimit');
		if (ltstr) {
            const parsed = parseInt(ltstr);
            if (!isNaN(parsed)) { 
                newlimit = parsed;
            }
        }
		this.setState({ limit: newlimit });


        this.setState({ keywordfilter: keyword }, () => {
            this.onLoadSites();
		});
		
		// open side menu
		EventBus.dispatch("sidemenu-changed", true);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.keyword !== this.props.keyword) {
            this.setState({ keywordfilter: this.props.keyword }, () => {
                this.onLoadSites();
            });
        } 
    }

    render() {

        const { data, loading, error, showmore, keywordfilter, viewDropdownOpen, sortDropdownOpen, limitDropdownOpen, advancedSearch, pStartdate, pEnddate, cStartdate, cEnddate, viewtype, account, billingperiod } = this.state;
        const { t } = this.props;

        const authUser = AuthService.getuser();
        
        return (
            <div>
                <SubHeader total={data.total} found={data.found} totalunpublished={data.totalunpublished} keywordfilter={keywordfilter} authUser={authUser} />
                <div className="container">
                    <button className="btn btn-sm btn-transparent text-medium-gray float-right ml-2" onClick={() => this.setState({ advancedSearch: !this.state.advancedSearch })}>{t("sortby.advanced")}</button>
					<Dropdown size="sm" isOpen={sortDropdownOpen} toggle={this.toggleSortDropdown} className="float-right ml-3">
                        <DropdownToggle color="default" caret className="btn-transparent text-medium-gray">
                        {t("sortby.title")}
                        </DropdownToggle>
                        <DropdownMenu end>
                            <DropdownItem onClick={() => this.handleSort('name', !this.state.reverse)}>{t("sortby.name")}</DropdownItem>
                            <DropdownItem onClick={() => this.handleSort('publishedAt', !this.state.reverse)}>{t("sortby.published")}</DropdownItem>
                            <DropdownItem onClick={() => this.handleSort('created', !this.state.reverse)}>{t("sortby.created")}</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
					<Dropdown size="sm" isOpen={limitDropdownOpen} toggle={this.toggleLimitDropdown} className="float-right ml-3">
                        <DropdownToggle color="default" caret className="btn-transparent text-medium-gray">
                        {t("limit.title")}
                        </DropdownToggle>
                        <DropdownMenu end>
                            <DropdownItem onClick={() => this.handleLimit(21)}>21</DropdownItem>
                            <DropdownItem onClick={() => this.handleLimit(42)}>42</DropdownItem>
							<DropdownItem onClick={() => this.handleLimit(63)}>63</DropdownItem>
							<DropdownItem onClick={() => this.handleLimit(84)}>84</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
					<Dropdown size="sm" isOpen={viewDropdownOpen} toggle={this.toggleViewDropdown} className="float-right ml-3">
                        <DropdownToggle color="default" caret className="btn-transparent text-medium-gray">
                        {t("viewby.title")}
                        </DropdownToggle>
                        <DropdownMenu end>
                            <DropdownItem onClick={() => this.handleView('cards')}>{t("viewby.cards")}</DropdownItem>
                            <DropdownItem onClick={() => this.handleView('list')}>{t("viewby.list")}</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    
                    <div className="form-group">
                        <div className="custom-control custom-checkbox custom-control-inline">
                            <input type="checkbox" id="published-filter" name="publishedfilter" className="custom-control-input" onChange={this.handleInputChange} />
                            <label className="custom-control-label published-filter-label text-medium-gray" htmlFor="published-filter">{t("showonlyunpublished")}</label>
                        </div>
                        <div className="custom-control custom-checkbox custom-control-inline">
                            <input type="checkbox" id="doubles-filter" name="doublesfilter" className="custom-control-input" onChange={this.handleInputChange} />
                            <label className="custom-control-label doubles-filter-label text-medium-gray" htmlFor="doubles-filter">{t("showdoubles")}</label>
                        </div>
                    </div>
                    { advancedSearch &&
                        <div className="bg-white p-3 mb-3" style={{ border: '1px solid rgba(0, 0, 0, 0.125)' }}>
                            <div className="row">
                                <div className="col-lg-3">
                                    <h5>{t("advancedsearch.publishedperiod")}</h5>
                                    <div className="form-group">
                                        <label htmlFor="publishedstart">{t("restrictby.startdate")}</label>
                                        <DatePicker onChange={this.handlepStart} className="bonsait-datepicker" locale="fi-FI" name="pStartdate" value={pStartdate} tileClassName="bonsait-datepicker-tile" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="publishedstart">{t("restrictby.enddate")}</label>
                                        <DatePicker onChange={this.handlepEnd} className="bonsait-datepicker" locale="fi-FI" name="pEnddate" value={pEnddate} tileClassName="bonsait-datepicker-tile"/>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <h5>{t("advancedsearch.createdperiod")}</h5>
                                    <div className="form-group">
                                        <label htmlFor="publishedstart">{t("restrictby.startdate")}</label>
                                        <DatePicker onChange={this.handlecStart} className="bonsait-datepicker" locale="fi-FI" name="pStartdate" value={cStartdate} tileClassName="bonsait-datepicker-tile"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="publishedstart">{t("restrictby.enddate")}</label>
                                        <DatePicker onChange={this.handlecEnd} className="bonsait-datepicker" locale="fi-FI" name="pEnddate" value={cEnddate} tileClassName="bonsait-datepicker-tile"/>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <h5>{t("advancedsearch.account")}</h5>
                                    <div className="custom-control custom-radio">
                                        <input type="radio" id="accountRadioEmpty" name="account" className="custom-control-input" value="" checked={account === ''} onChange={this.handleInputChange} />
                                        <label className="custom-control-label" htmlFor="accountRadioEmpty">{t("advancedsearch.all")}</label>
                                    </div>
                                    <div className="custom-control custom-radio">
                                        <input type="radio" id="accountRadio" name="account" className="custom-control-input" value="S" checked={account === 'S'} onChange={this.handleInputChange} />
                                        <label className="custom-control-label" htmlFor="accountRadio">S</label>
                                    </div>
                                    <div className="custom-control custom-radio">
                                        <input type="radio" id="accountRadio2" name="account" className="custom-control-input" value="M" checked={account === 'M'} onChange={this.handleInputChange} />
                                        <label className="custom-control-label" htmlFor="accountRadio2">M</label>
                                    </div>
                                    <div className="custom-control custom-radio">
                                        <input type="radio" id="accountRadio3" name="account" className="custom-control-input" value="L" checked={account === 'L'} onChange={this.handleInputChange} />
                                        <label className="custom-control-label" htmlFor="accountRadio3">L</label>
                                    </div>
                                    <div className="custom-control custom-radio">
                                        <input type="radio" id="accountRadio4" name="account" className="custom-control-input" value="XL" checked={account === 'XL'} onChange={this.handleInputChange} />
                                        <label className="custom-control-label" htmlFor="accountRadio4">XL</label>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <h5>{t("advancedsearch.billingperiod")}</h5>
                                    <div className="custom-control custom-radio">
                                        <input type="radio" id="billingperiodRadioEmpty" name="billingperiod" className="custom-control-input" value="" checked={billingperiod === ''} onChange={this.handleInputChange} />
                                        <label className="custom-control-label" htmlFor="billingperiodRadioEmpty">{t("advancedsearch.all")}</label>
                                    </div>
                                    <div className="custom-control custom-radio">
                                        <input type="radio" id="billingperiodRadio" name="billingperiod" className="custom-control-input" value="3" checked={billingperiod === '3'} onChange={this.handleInputChange} />
                                        <label className="custom-control-label" htmlFor="billingperiodRadio">3 {t("months")}</label>
                                    </div>
                                    <div className="custom-control custom-radio">
                                        <input type="radio" id="billingperiodRadio2" name="billingperiod" className="custom-control-input" value="6" checked={billingperiod === '6'} onChange={this.handleInputChange} />
                                        <label className="custom-control-label" htmlFor="billingperiodRadio2">6 {t("months")}</label>
                                    </div>
                                    <div className="custom-control custom-radio">
                                        <input type="radio" id="billingperiodRadio3" name="billingperiod" className="custom-control-input" value="12" checked={billingperiod === '12'} onChange={this.handleInputChange} />
                                        <label className="custom-control-label" htmlFor="billingperiodRadio3">12 {t("months")}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <Sitelist isLoading={loading} data={data} t={t} userdata={authUser.bonsaituser} renderSingleSite={this.renderSingleSite} handleImageError={this.handleImageError} viewtype={viewtype}/>
                    { showmore === true &&
                        <button className="btn btn-primary btn-block btn-lg mb-4" onClick={this.onLoadMore}>{t("loadmore")}</button>
                    }
                    
                    { error && <h4 className="text-center">{error.message}</h4> }
                </div>
            </div>
        );
    }
}

export default withAuthorization(withTranslation("Dashboard")(Websites));
