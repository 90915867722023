import React from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import AuthService from './Auth';
import BonsaitLogo from '../../images/bonsait-logo-wg.png';
import { API } from '../../config/config';
import { withTranslation } from 'react-i18next';
import * as routes from '../../config/routes';
import Loader from '../../includes/Loader';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import isEmail from 'validator/lib/isEmail';

const INITIAL_STATE = {
    email: '',
    password: '',
    theme: '',
    error: null,
    loading: false,
    lftheme: false
};

class SiteAuthentication extends React.Component {
    constructor(props) {
        super(props);
        this.showlogin = true;
        this.siteid = '';
        this.referer = '';
        this.state = { ...INITIAL_STATE }

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }

    handleInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({ [name]: value });
    }

    handleErrors = (result) => {
        const { t } = this.props;
        let error = {};
        switch (result.message) {
            case 'Trial expired':
                error.message = t("trial.expired")
                break;
            case 'Demo expired':
                error.message = t("trial.expired")
                break;
            case 'Unauthorized':
                error.message = t("error_unauthorized")
                break;
            default:
                error.message = t("error_invalidcredentials")
        }

        // Quick fix for firebase errors...
        if (result.code) {
            switch (result.code) {
                case 'auth/invalid-email':
                    error.message = t("validations.email")
                    break;
                case 'auth/user-disabled':
                    error.message = t("error_userdisabled")
                    break;
                case 'auth/user-not-found':
                    error.message = t("error_usernotfound")
                    break;
                case 'auth/wrong-password':
                    error.message = t("error_invalidcredentials")
                    break;
                default:
                    error.message = t("error_unkown")
            }
        }

        this.setState({ error, loading: false })
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({ loading: true, error: null });

        const { email, password } = this.state;
        const { t } = this.props;

        if (email && password) {
            const validEmail = isEmail(email);
            
            let credentials = {
                email: email,
                password: password,
                credentialtype: ''
            }

            if (validEmail) {
                credentials.credentialtype = 'email'
            }

            let authdata = {
                siteid: this.siteid,
                referer: this.referer
            }
            AuthService.login(credentials).then(() => {
                if (!this.unmount) {
                    return AuthService.siteauth(authdata);
                }
            })
            .catch(error => {
                this.handleErrors(error)
            })
        } else {
            const error = { message: t("check_credentials") };
            this.setState({ error, loading: false });
        }
    }


    handleGoogle = () => {

        let theme = this.state.theme;
        this.setState({ 
            ...INITIAL_STATE, 
            theme: theme,
            loading: true 
        });

        let authdata = {
            siteid: this.siteid,
            referer: this.referer
        }
        
        AuthService.authenticateGoogle().then(() => {
            return AuthService.siteauth(authdata);
        })
        .catch(error => { 
            this.handleErrors(error)
        });
    }

    handleFacebook = () => {

        let theme = this.state.theme;
        this.setState({ 
            ...INITIAL_STATE, 
            theme: theme,
            loading: true 
        });

        let authdata = {
            siteid: this.siteid,
            referer: this.referer
        }

        AuthService.authenticateFacebook().then(() => {
            return AuthService.siteauth(authdata);
        })
        .catch(error => { 
            this.handleErrors(error)
        });
    }

    getUrlParameter = (sParam) => {
        var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'), sParameterName, i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : sParameterName[1];
            }
        }
    }

    componentWillUnmount() {
        this.unmount = true;
    }

    componentDidMount() {

        this.setState({ lftheme: true });

        this.siteid = this.getUrlParameter('siteid');
        this.referer = this.getUrlParameter('referer');

        const { t } = this.props;

        if (this.siteid && this.referer) {
            fetch(API + `/sitelogin?siteid=${this.siteid}&referer=${this.referer}`)
            .then(results => results.json())
            .then(response => {
                this.showlogin = true;
                this.setState({ 
                    theme: response.theme,
                    referer: response.referer,
                    lftheme: false
                });
            })
        } else {
            this.showlogin = false;
            const error = { message: t("error_unkown") }
            this.setState({ 
                error,
                lftheme: false
            });
        }
    }

    render() { 

        const { t } = this.props;
        const isDisabled = this.state.loading;

        if (this.state.lftheme) {
            return <Loader size="3x" classes="text-primary mt-4" />
        }

        if (!this.showlogin) {
            return (
                <div className="text-center mt-4">
                    <i className="fa-solid fa-exclamation-circle fa-4x mb-4 text-danger"></i>
                    <h2>{this.state.error && this.state.error.message}</h2>
                </div>
            );
        }

        if (AuthService.redirectToReferer) {
            return <Redirect to={{ 
                pathname: routes.ACTIVATE_ACCOUNT, 
                state: {
                    oldusername: AuthService.olduser.username, 
                    credentialtype: AuthService.olduser.credentialtype,
                    name: AuthService.olduser.name,
                    redirectto: routes.SITEAUTH + this.props.location.search
                } }} />
        }

        return ( 
            <div className={`login-wrapper ${this.state.theme}`}>
                <div className="login_container">
                    <form onSubmit={this.handleSubmit} id="login_form">
                        { this.state.theme === 'bonsait' || !this.state.theme
                            ? <img src={BonsaitLogo} alt="bonsait" className="logo-img" />
                            : 
                            <div>
                                <img src={`https://www.bonsait.fi/public/themes/${this.state.theme}/images/logo-wg.png`} alt="bonsait" className="logo-img" />
                                <link rel="stylesheet" type="text/css" href={`https://www.bonsait.fi/public/themes/${this.state.theme}/stylesheets/main.css`} />
                            </div>
                        }
                        <div className="form-group">
                            <div className="input-group input-group-lg">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <i className="fa-solid fa-user"></i>
                                    </span>
                                </div>
                                <input type="text" className="form-control input-lg" name="email" value={this.state.email} placeholder={t("email_or_username")} onChange={this.handleInputChange} autoFocus />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="input-group input-group-lg">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" style={{ fontSize: '1.1rem' }}>
                                        <i className="fa-solid fa-key"></i>
                                    </span>
                                </div>
                                <input type="password" className="form-control input-lg" name="password" value={this.state.password} placeholder={t("password")} onChange={this.handleInputChange} />
                            </div>
                        </div>
                        <div className="submit_section m-b">
                            <button className="btn btn-success btn-block btn-lg" disabled={isDisabled} type="submit">
                            { this.state.loading 
                                ? <Loader size="1x" classes="text-white" />
                                : <span>{t("signin")}</span>
                            }
                            </button>
                        </div>

                        { this.state.error && 
                            <div className="alert alert-warning mt-3">{this.state.error.message}</div> 
                        }

                        <div className="sitelogin-divider">
                            <span>{t("or")}</span>
                        </div>

                        <div className="social-login">
                            <button type="button" className="btn btn-block btn-social btn-google" disabled={isDisabled} onClick={this.handleGoogle}>
                                <div className="social-icon-google"></div>
                                { this.state.loading 
                                    ? <Loader size="1x" classes="text-white" />
                                    : <span>{t("connect_to_google")}</span>
                                }
                            </button>
                            {/* 
                            <div className="social-login-right">
                                <button type="button" className="btn btn-block btn-social btn-facebook" disabled={isDisabled} onClick={this.handleFacebook}>
                                <div className="d-flex align-items-center justify-content-center">
                                    <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                                </div>
                                { this.state.loading 
                                    ? <Loader size="1x" classes="text-white" />
                                    : <span>{t("signin")}</span>
                                }
                                </button>
                            </div>
                            */}
                        </div>

                        <div className="social-login-clear"></div>

                        <div className="help-block">
                            <Link to={routes.PASSWORD_FORGET} style={{fontSize: '0.9rem'}}>{t("forgot_pw")}</Link>
                            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="float-right" size="sm">
                                <DropdownToggle color="default" className="siteauth-lang-dropdown">
                                    {t("languages.select")}
                                    <i className="fa-solid fa-earth-americas ml-2" style={{ verticalAlign: 'middle' }}></i>
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <DropdownItem onClick={() => this.props.changeLanguage('en')}>
                                        <span>{t("languages.english")}</span>
                                    </DropdownItem>
                                    <DropdownItem onClick={() => this.props.changeLanguage('fi')}>
                                        <span>{t("languages.finnish")}</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <div className="help-login-clear"></div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
 
export default withRouter(withTranslation("Authentication")(SiteAuthentication));