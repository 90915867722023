import React from 'react';
import { Redirect, withRouter } from 'react-router-dom'
import { withTranslation } from "react-i18next";
import { API } from '../../config/config';
import { SERVER_IP, SERVER_CNAME } from '../../config/config';
import { auth } from '../../firebase/firebase';

import EventBus from 'eventbusjs';
import Loader from '../../includes/Loader';
import withAuthorization from '../withAuthorization';
import AuthService from '../Authentication/Auth';
import SubHeader from '../../includes/SubHeader';
import punycode from 'punycode/';

class ConnectDomain extends React.Component {

	constructor(props) {
        super(props);
        this.state = {
            site: null,
            loading: true,
			error: null,
			isvalid: true,
			isPunycode: false,
			connectdomain: '',
		}
    }

	validateDomain = () => {
		const regexp = /^[a-zA-Z0-9][a-zA-Z0-9-.]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/i;
		if (regexp.test(punycode.toASCII(this.state.connectdomain))) {
			// if (this.state.connectdomain && this.state.connectdomain.indexOf('.')>-1) {
			this.setState({ isvalid: true });
			return true;
		} else {
			this.setState({ isvalid: false });
			return false;
		}
	}	

	getSite = (siteid) => {
		const authUser = AuthService.getuser();
        const { id } = authUser.bonsaituser;

        auth.currentUser.getIdToken().then(idtoken => {
            const json = { idtoken: idtoken }
            fetch(API + `/getsite?siteid=${siteid}&userid=${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(json)
            })
			.then(results => results.json())
			.then(result => {
				if (result.id) {
					this.missingbilling = result.missingbilling
					let defaultsite = { ...this.state.site };
					
					const siteobject = Object.assign({ ...defaultsite }, result);
					this.setState({ site: siteobject, loading: false });
				} else {
					this.handleErrors(result);
				}
			})
			.catch(error => (error));
        });
    }

	handleErrors = (result) => {
        const { t } = this.props;
        let error = {};

        switch (result.message) {
            case 'Already published':
                error.message = t("already_published")
                break;
            case 'Unauthorized publisher':
                error.message = t("unauthorized_publishing")
                break;
            case 'Missing billing information"':
                error.message = t("missing_billing")
                break;
            default:
                error.message = t("error.unknown")
        }

        this.setState({ loading: false, error });
    }

	addExistingDomain = () => {
		const authUser = AuthService.getuser();
        const { id } = authUser.bonsaituser;

		const valid = this.validateDomain();
		if (valid) {
			auth.currentUser.getIdToken().then(idtoken => {
				const json = { 
					idtoken: idtoken,
					siteid: this.state.site.id,
					connectdomain: punycode.toASCII(this.state.connectdomain)
				}
				fetch(API + `/sites/adddomain?siteid=${this.state.site.id}&userid=${id}`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(json)
				})
				.then(results => results.json())
				.then(result => {
					this.props.history.push(`/site/${result.id}`);
				})
				.catch(error => (error));
			});
		}
    }

	handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
			connectdomain: value
		});
    }

	componentDidMount() {
        const { siteid } = this.props.match.params;
        this.getSite(siteid);

		// open side menu
		EventBus.dispatch("sidemenu-changed", true);
    }

	render() {

		const authUser = AuthService.getuser();
		const { site, loading, error, connectdomain, isvalid} = this.state
		const { t } = this.props;

		if (loading) {
            return (
                <div className="text-center mt-4">
                    <Loader size="3x" classes="text-primary" />
                </div>
            );
        }

		if (error) {
            return (
                <div className="text-center mt-4">
					<i className="fa-solid fa-circle-exclamation fa-4x mb-4 text-danger"></i>
                    <h4>{error.message}</h4>
                </div>
            );
        }

		return (
			<React.Fragment>
				<SubHeader sitename={site.title} authUser={authUser} newdomain={true}/>
				<div className="container">
					<div className="card mt-4">
						<div className="card-header bg-secondary-dark highlight text-white">{t("domain.connectdomain_title")}</div>
						<div className="card-body">
							<div className="form-group mb-4">
								<label htmlFor="domain">{t("domain.connectdomain_domain")}</label>
								<input id="domain" name="domain" value={connectdomain} onChange={this.handleInputChange} type="text" className={isvalid ? "form-control" : "form-control is-invalid"} />
								<small id="emailHelp" className="form-text text-muted">{t("domain.connectdomain_domain_help")}</small>
							</div>

							<React.Fragment>

								<h5>{t("domain.connectdomain_domain_info_title")}</h5>
								<p>{t("domain.connectdomain_domain_info_text")}</p>

								<div className="card mb-4 bg-light-gray">
									<div className="card-body">
										<p className="card-text">{t("domain.connectdomain_domain_info_a_record")}<br/><strong>{SERVER_IP}</strong></p>
										<p className="card-text">{t("domain.connectdomain_domain_info_cname")}<br/><strong>{SERVER_CNAME}</strong></p>
									</div>
								</div>

								<p><i>{t("domain.connectdomain_domain_info_text2")}</i></p>
							</React.Fragment>

						</div>
						<div className="card-footer bg-white">
							<button className="btn btn-secondary" onClick={this.addExistingDomain}>{t("domain.connectdomain_submit_btn")}</button>
                            <button className="btn btn-link float-right" onClick={() => this.props.history.goBack(-1)}>{t("cancel")}</button>
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withAuthorization(withRouter(withTranslation("Sites")(ConnectDomain)));