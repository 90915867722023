import React from 'react';
import Loader from '../includes/Loader';

const withLoading = (Component) => {
    return function WithLoadingComponent({ isLoading, ...props }) {
        if (!isLoading) return (<Component {...props} />);
        return ( 
            <Loader size="3x" classes="text-primary" />
        );
    }
}

export default withLoading;