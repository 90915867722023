import React from 'react';
import { withTranslation } from "react-i18next";
import AuthService from '../Authentication/Auth';
import SignUpForm from '../Authentication/SignUpForm';
import { validateSignUp } from '../../helpers/Helper';

const INITIAL_VALIDATORS = {
    fullname: { isInvalid: false, message: '' },
    email: { isInvalid: false, message: '' },
    pwd: { isInvalid: false, message: '' },
    pwd2: { isInvalid: false, message: '' },
    termsofservice: { isInvalid: false, message: '' },
    isValid: true
}

const INITIAL_STATE = {
    fullname: '',
    email: '',
    pwd: '',
    pwd2: '',
    termsofservice: false,
    error: null,
    termserror: false,
    loading: false,
};

class StepOne extends React.Component { 
    constructor(props) {
        super(props);
        this.submitted = false;
        this.validations = { ...INITIAL_VALIDATORS }
        this.state = { ...INITIAL_STATE };
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value }, () => {
            if (this.submitted) {
                this.validateForm();
            }
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ termserror: false });

        this.submitted = true;
        const isValid = this.validateForm();

        if (isValid) {
            this.setState({ loading: true });

            const { fullname, email, pwd } = this.state;
            const formdata = {
                name: fullname,
                email: email,
                passwd: pwd,
                token: ''
            }

            AuthService.register(formdata).catch(error => { 
                this.setState({ error, loading: false })
            });
        } else {
            this.forceUpdate();
        }
    }

    handleGoogle = () => {
        this.setState({ termserror: false });
        if (this.state.termsofservice) {
            this.setState({ ...INITIAL_STATE, loading: true });
            AuthService.authenticateGoogle().catch(error => { 
                this.setState({ error, loading: false })
            });
        } else {
            this.setState({ termserror: true });
        }
    }
    
    handleFacebook = () => {
        this.setState({ termserror: false });
        if (this.state.termsofservice) {
            this.setState({ ...INITIAL_STATE, loading: true });
            let token = '';
            AuthService.authenticateFacebook(token).catch(error => { 
                this.setState({ error, loading: false })
            });
        } else {
            this.setState({ termserror: true });
        }
    }

    validateForm = () => {
        const { t } = this.props;
        const initialValidations = { ...INITIAL_VALIDATORS }
        const validations = validateSignUp(initialValidations, this.state, t)
        this.validations = validations;
        this.setState({ validated: true });
        return validations.isValid;
    }

    componentDidMount() {
        this.checkAuth();
    }

    componentDidUpdate(prevProps) {
        const { isAuthenticated } = this.props;
        if (prevProps.isAuthenticated !== isAuthenticated) {
            this.checkAuth();
        }
    }

    checkAuth = () => {
        const { nextStep } = this.props;
        if (AuthService.isAuthenticated) {
            nextStep();
        }
    }

    render() {

        const {
            fullname,
            email,
            pwd,
            pwd2,
            error,
            termsofservice,
            termserror,
            loading
        } = this.state;

        return(
            <SignUpForm
                handleFacebook={this.handleFacebook}
                handleGoogle={this.handleGoogle}
                handleSubmit={this.handleSubmit}
                handleInputChange={this.handleInputChange}
                validations={this.validations}
                loading={loading}
                error={error}
                termserror={termserror}
                fullname={fullname}
                termsofservice={termsofservice}
                pwd={pwd}
                pwd2={pwd2}
                email={email}
            />
        );

    }
}

export default withTranslation("Authentication")(StepOne);