import React from 'react';
import * as routes from '../../config/routes';
import { withTranslation } from "react-i18next";
import Loader  from '../../includes/Loader';
import { Link } from 'react-router-dom';

const SignUpForm = (props) => {

    const { t } = props;
    const buttonDisabled = props.loading;

    return (
        <div className="signin-container">
            <div className="sign-in-with-email">
                <form onSubmit={props.handleSubmit} noValidate autoComplete="off">
                    <div className="form-group">
                        <label htmlFor="fullname">{t("name")}</label>
                        <input id="fullname" name="fullname" value={props.fullname} onChange={props.handleInputChange} type="text" className={props.validations.fullname.isInvalid ? "form-control is-invalid" : "form-control"} placeholder={t("name_placeholder")} autoFocus />
                        { props.validations.fullname.isInvalid  && <span className="srv-validation-message slideanim slide">{props.validations.fullname.message}</span> }
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">{t("email")}</label>
                        <input id="email" name="email" value={props.email} onChange={props.handleInputChange} type="email" className={props.validations.email.isInvalid ? "form-control is-invalid" : "form-control"} />
                        { props.validations.email.isInvalid  && <span className="srv-validation-message slideanim slide">{props.validations.email.message}</span> }
                    </div>
                    <div className="form-group">
                        <label htmlFor="pwd">{t("password")}</label>
                        <input id="pwd" name="pwd" value={props.pwd} onChange={props.handleInputChange} type="password" className={props.validations.pwd.isInvalid ? "form-control is-invalid" : "form-control"} />
                        { props.validations.pwd.isInvalid  && <span className="srv-validation-message slideanim slide">{props.validations.pwd.message}</span> }
                    </div>
                    <div className="form-group">
                        <label htmlFor="pwd2">{t("password_again")}</label>
                        <input id="pwd2" name="pwd2" value={props.pwd2} onChange={props.handleInputChange} type="password" className={props.validations.pwd2.isInvalid ? "form-control is-invalid" : "form-control"} />
                        { props.validations.pwd2.isInvalid  && <span className="srv-validation-message slideanim slide">{props.validations.pwd2.message}</span> }
                    </div>
                    <div className="form-group">
                        <div className="custom-control custom-checkbox">
                            <input id="termsofservice" type="checkbox" className="custom-control-input" name="termsofservice" onChange={props.handleInputChange} checked={props.termsofservice} />
                            <label className="custom-control-label mr-1" htmlFor="termsofservice">{t("accept") + " "}</label>
                            <a className="text-secondary" href="https://www.bonsait.fi/sopimusehdot" rel="noopener noreferrer" target="_blank">{t("terms")}</a>
                        </div>
                        { props.validations.termsofservice.isInvalid  && <span className="srv-validation-message slideanim slide">{props.validations.termsofservice.message}</span> }
                    </div>
                    <button className="btn btn-primary btn-block" disabled={buttonDisabled} type="submit">
                    { props.loading 
                        ? <Loader size="1x" classes="text-white" />
                        : <span>{t("signup")}</span>
                    }
                    </button>
                    { props.error && 
                        <div className="alert alert-danger mt-3">{props.error.message}</div> 
                    }
                </form>
                <div className="text-center mt-3">
                    <Link className="text-secondary-dark" to={routes.SIGN_IN} style={{fontSize: '0.9rem'}}>{t("already_registered")}</Link>
                </div>
            </div>
            <div className="divider"></div>
            <p className="text-divider d-md-none"><span>{t("or")}</span></p>
            <div className="sign-in-social">
                <div className="row text-center mb-4">
                    <div className="col-md-12 col-sm-6">
                        <button className="btn btn-social btn-google btn-block mb-3" onClick={props.handleGoogle} disabled={buttonDisabled}>
                            <div className="social-icon-google"></div>
                            {t("connect_to_google")}
                        </button>
                    </div>
                    {/*
                    <div className="col-md-12 col-sm-6">
                        <button className="btn btn-social btn-facebook btn-block" onClick={props.handleFacebook} disabled={buttonDisabled}>
                        <div className="d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                        </div>
                        {t("connect_to_fb")}
                        </button>
                    </div>
                    */}
                    { props.termserror &&
                        <div className="col-md-12 col-sm-6">
                            <div className="alert alert-danger alert-small mt-3">{t("error_terms")}</div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default SignUpForm;