import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as routes from '../config/routes';

const ComponentNotfound = (props) => 
    <div className="container text-center mt-4">
        <i className="fa-solid fa-circle-exclamation fa-4x text-danger"></i>

        <h1 className="mt-3">
            <Trans i18nKey="404.title">
                Sivua
                {"'" + props.location.pathname + "'"}
                ei löytynyt
            </Trans>
        </h1>
        <Trans i18nKey="404.trymove">
            Yritä siirtyä palvelun
            <Link to={routes.WEBSITES}>etusivulle</Link>
        </Trans>
    </div>

export default withTranslation("Navigation")(ComponentNotfound);