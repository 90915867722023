import React from 'react';
import Loader from '../../includes/Loader';
import { withTranslation } from "react-i18next";
import { validatePasswordChange } from '../../helpers/Helper';
import { API } from '../../config/config';
import { Link } from 'react-router-dom';
import * as routes from '../../config/routes';

const INITIAL_VALIDATORS = {
    password: { isInvalid: false, message: '' },
    password2: { isInvalid: false, message: '' },
    isValid: true
}

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.c = '';
        this.u = '';
        this.validations = { ...INITIAL_VALIDATORS };
        this.submittted = false;
        this.state = {  
            error: null,
            success: false,
            loading: false,
            password: '',
            password2: ''
        }
    }

    handleInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({ [name]: value }, () => {
            if (this.submittted)  {
                this.validateForm();
            }
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({ error: null, loading: true });
        this.submittted = true;
        const isValid = this.validateForm();

        if (isValid) {
            const formdata = {
                u: this.u,
                c: this.c,
                password: this.state.password,
                password2: this.state.password2
            }

            fetch(API + `/resetpassword`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formdata)
            })
            .then(results => results.json())
            .then(result => {
                if (result.success) {
                    this.setState({ success: true, loading: false });
                } else {
                    const { t } = this.props;
                    const error = { message: t("forgottenpassword.error") }
                    this.setState({ success: false, error, loading: false });
                }
            })
            .catch(error => console.log(error));
        } else {
            this.setState({ loading: false });
        }
            
    }

    validateForm = () => {
        const { password, password2 } = this.state;
        const { t } = this.props;
        let validators = { ...INITIAL_VALIDATORS }
        const validations = validatePasswordChange(password, password2, validators, t);
        this.validations = validations;
        this.setState({ validated: true });
        return validations.isValid;
    }

    getUrlParameter = (sParam) => {
        var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'), sParameterName, i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : sParameterName[1];
            }
        }
    }

    componentDidMount() {
        this.u = this.getUrlParameter("u");
        this.c = this.getUrlParameter("c");
    }


    render() { 

        const { password, password2, error, success, loading } = this.state;
        const { t } = this.props;

        if (success) {
            return (
                <div className="container text-center mt-4">
                    <i className="fa-solid fa-check fa-4x mb-4 text-success"></i>
                    <h4>{t("forgottenpassword.success")}</h4>
                    <Link className="text-secondary-dark" to={routes.SIGN_IN} className="btn btn-primary mt-4">{t("signin")}</Link>
                </div>
            );
        }

        return (
            <div className="container mt-4">
                <div className="mx-auto" style={{maxWidth: '350px'}}>
                    <h4>{t("forgottenpassword.title")}</h4>
                    <form onSubmit={this.onSubmit} >
                        <div className="form-group">
                            <input className="form-control" type="password" name="password" placeholder={t("password")} value={password} onChange={this.handleInputChange} autoFocus />
                            { this.validations.password.isInvalid  && <span className="srv-validation-message slideanim slide">{this.validations.password.message}</span> }
                        </div>
                        <div className="form-group">
                            <input className="form-control" type="password" name="password2" placeholder={t("password_again")} value={password2} onChange={this.handleInputChange} />
                            { this.validations.password2.isInvalid  && <span className="srv-validation-message slideanim slide">{this.validations.password2.message}</span> }
                        </div>
                        { error &&
                            <div className="alert alert-danger mt-3">
                                {error.message}
                            </div>                            
                        }
                        <button className="btn btn-primary btn-block" disabled={loading}>
                        { loading 
                            ? <Loader size="1x" classes="text-white" />
                            : <span>{t("forgottenpassword.title")}</span>
                        }
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}
 
export default withTranslation("Authentication")(ResetPassword);;