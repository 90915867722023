import React from 'react';

const StepThree = (props) => {

    const { siteURL, t, authUser } = props;

    return (
        <div className="text-center">
            <h2 className="mt-5">{t("trial.created")}</h2>
            <p>{t("trial.instruction")}: <a href={'http://' + siteURL + '/login'} target="_blank" rel="noopener noreferrer">{'http://' + siteURL}</a></p>
            { authUser && authUser.providerData[0].providerId === 'password' &&
                <p>{t("username") + ': ' + authUser.email}</p>
            }
            <a href={'http://' + siteURL + '/login'} className="btn btn-primary" target="_blank" rel="noopener noreferrer">{t("trial.movetoeditor")}</a>
            <div className="mt-5">
                <p>{t("trial.supportinfo")} <a href="https://www.bonsait.fi/tuotetuki" target="_blank" rel="noopener noreferrer">https://www.bonsait.fi/tuotetuki</a></p>
            </div>
        </div>
    );
}

export default StepThree;