import React from 'react';
import { Link, withRouter } from 'react-router-dom'
import { withTranslation } from "react-i18next";
import { API } from '../../config/config';
import { SERVER_IP, SERVER_CNAME } from '../../config/config';
import * as routes from '../../config/routes';
import { auth } from '../../firebase/firebase';
import moment from 'moment';
import Moment from 'react-moment';

import EventBus from 'eventbusjs';
import Loader from '../../includes/Loader';
import withAuthorization from '../withAuthorization';
import AuthService from '../Authentication/Auth';

class WithoutBilling extends React.Component {

	constructor(props) {
        super(props);
        this.state = {
            loading: false,
			billings: []
		}
    }

	fetchBillings = () => {
        auth.currentUser.getIdToken().then(idtoken => {
            const json = { idtoken: idtoken }
            fetch(API + "/withoutbilling", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(json)
            })
            .then(results => results.json())
            .then(result => {
				this.setState({ 
					billings: result.billings,
					loading: false 
				});
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
        });
    }

	componentDidMount() {
		this.fetchBillings();
		// open side menu
		EventBus.dispatch("sidemenu-changed", true);
    }

	render() {
		const authUser = AuthService.getuser();
		const { loading, billings} = this.state
		const { t } = this.props;

		return (
			<>
				<div className="container">
					<div className="card mt-4">
						<div className="card-header bg-secondary-dark highlight text-white">{t("freebirds.title")}</div>
						<div className="card-body">

							{loading 
							? 
								<div className="text-center mt-4">
									<Loader size="3x" classes="text-primary" />
								</div>
							: 
								<>
								{(billings && billings.length > 0) &&
									<div className="billinlist">

										<div className="row mb-1 ">
											<div className="col-md-4">{t("freebirds.sitename")}</div>
											<div className="col-md-4">{t("freebirds.linkedsite")}</div>
											<div className="col-md-4">{t("freebirds.customername")}</div>
										</div>
										<hr />
										{billings.map((billing, i) => {
											return (
												<div key={billing.id}>
													<div className="row mb-2">
														<div className="col-md-4">
															{billing.contracttype=='site' 
															? <><Link className="title-link" to={routes.WEBSITE_HOME + `/${billing.id}`}>{billing.title} {billing.account && <>({billing.account})</>}</Link><br/></>
															: <><Link className="title-link" to={routes.CONTRACTS_EDIT + `/${billing.id}`}>{billing.title} {billing.account && <>({billing.account})</>}</Link><br/></>
															}
															{billing.modified &&
                                                            <small>{t("freebirds.sitemodified")} <Moment fromNow>{billing.modified.$date}</Moment></small>
                                                            }
														</div>
														<div className="col-md-4">
                                                            {billing.isdouble &&
                                                            <><i className={'fa fa-check-circle-o'}></i></>
                                                            }
														</div>
														<div className="col-md-4">
                                                            {billing.billingname}<br/>
															<small>{billing.billingcompany}</small>
														</div>
													</div>
													<hr />
												</div>
											)
										})}

									</div>
								}
								</>
							}

						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withAuthorization(withRouter(withTranslation("Admin")(WithoutBilling)));