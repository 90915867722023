import React from 'react';
import { checkNum } from '../../helpers/Helper';

const BillingForm = (props) => {

	// Create our number formatter.
	const formatter = new Intl.NumberFormat('fi', {
		//style: 'currency',
		//currency: 'EUR',
		// These options are needed to round to whole numbers if that's what you want.
		minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
		//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	});


	return (
		<div>
			<h4>{props.t("billing.name")}</h4>

			<div className="form-row">
				<div className="col">
					<div className="form-group">
						<label>{props.t("billing.method")}</label>
						<div className="custom-control custom-radio">
							<input type="radio" id="billing-bill" name="billing" value="bill" className="custom-control-input" onChange={props.handleInputChange} checked={props.site.billing === 'bill'} />
							<label className="custom-control-label" htmlFor="billing-bill">{props.t("billing.bill")}</label>
						</div>
						<div className="custom-control custom-radio">
							<input type="radio" id="billing-creditcard" name="billing" value="creditcard" className="custom-control-input" onChange={props.handleInputChange} checked={props.site.billing === 'creditcard'} disabled />
							<label className="custom-control-label" htmlFor="billing-creditcard">{props.t("billing.creditcard")}</label>
						</div>
					</div>
				</div>
				<div className="col">
					<div className="form-group">
						<label>{props.t("billing.sendingmethod")}</label>
						<div className="custom-control custom-radio">
							<input type="radio" id="sendingmethod-online" name="sendingmethod" value="online" className="custom-control-input" onChange={props.handleInputChange} checked={props.site.sendingmethod === 'online'} />
							<label className="custom-control-label" htmlFor="sendingmethod-online">{props.t("billing.online")}</label>
						</div>
						<div className="custom-control custom-radio">
							<input type="radio" id="sendingmethod-email" name="sendingmethod" value="email" className="custom-control-input" onChange={props.handleInputChange} checked={props.site.sendingmethod === 'email'} />
							<label className="custom-control-label" htmlFor="sendingmethod-email">{props.t("billing.email")}</label>
						</div>
						<div className="custom-control custom-radio">
							<input type="radio" id="sendingmethod-mail" name="sendingmethod" value="mail" className="custom-control-input" onChange={props.handleInputChange} checked={props.site.sendingmethod === 'mail'} />
							<label className="custom-control-label" htmlFor="sendingmethod-mail">{props.t("billing.mail")}</label>
						</div>
					</div>
	
				</div>
				<div className="col">
					<div className="form-group">
						<label>{props.t("billing.period")}</label>
						<div className="custom-control custom-radio">
							<input type="radio" id="bp-12" name="billingperiod" value="12" className="custom-control-input" onChange={props.handleInputChange} checked={props.site.billingperiod === '12'} />
							<label className="custom-control-label" htmlFor="bp-12">12 {props.t("billing.months")}</label>
						</div>
						<div className="custom-control custom-radio">
							<input type="radio" id="bp-6" name="billingperiod" value="6" className="custom-control-input" onChange={props.handleInputChange} checked={props.site.billingperiod === '6'} />
							<label className="custom-control-label" htmlFor="bp-6">6 {props.t("billing.months")}</label>
						</div>
						<div className="custom-control custom-radio">
							<input type="radio" id="bp-3" name="billingperiod" value="3" className="custom-control-input" onChange={props.handleInputChange} checked={props.site.billingperiod === '3'} />
							<label className="custom-control-label" htmlFor="bp-3">3 {props.t("billing.months")}</label>
						</div>
						<div className="custom-control custom-radio">
							<input type="radio" id="bp-1" name="billingperiod" value="1" className="custom-control-input" onChange={props.handleInputChange} checked={props.site.billingperiod === '1'} />
							<label className="custom-control-label" htmlFor="bp-1">1 {props.t("billing.months")}</label>
						</div>
					</div>
				</div>
			</div>

			{props.authUser && props.authUser.bonsaituser && props.authUser.bonsaituser.role === 'admin' &&
			<div className="alert alert-warning mt-4" role="alert">

				<div className="row">
					<div className="col-md-6">

						<div className="form-group">
							<label htmlFor="billingmonth">{props.t("billing.month")}</label>
							<select id="site-form-billingmonth" className="custom-select" name="billingmonth" value={props.site.billingmonth} onChange={props.handleInputChange}>
								<option value="" disabled hidden>{props.t("billing.selectmonth")}</option>
								<option value="none">{props.t("billing.nobilling")}</option>
								<optgroup label="12 kk">
									<option value="1">12kk, 1</option>
									<option value="2">12kk, 2</option>
									<option value="3">12kk, 3</option>
									<option value="4">12kk, 4</option>
									<option value="5">12kk, 5</option>
									<option value="6">12kk, 6</option>
									<option value="7">12kk, 7</option>
									<option value="8">12kk, 8</option>
									<option value="9">12kk, 9</option>
									<option value="10">12kk, 10</option>
									<option value="11">12kk, 11</option>
									<option value="12">12kk, 12</option>
								</optgroup>
								<optgroup label="6 kk">
									<option value="1/7">6kk, 1/7</option>
									<option value="2/8">6kk, 2/8</option>
									<option value="3/9">6kk, 3/9</option>
									<option value="4/10">6kk, 4/10</option>
									<option value="5/11">6kk, 5/11</option>
									<option value="6/12">6kk, 6/12</option>
								</optgroup>
								<optgroup label="3 kk">
									<option value="1/4/7/10">3kk, 1/4/7/10</option>
									<option value="2/5/8/11">3kk, 2/5/8/11</option>
									<option value="3/6/9/12">3kk, 3/6/9/12</option>
								</optgroup>
								<optgroup label="1 kk">
									<option value="1/2/3/4/5/6/7/8/9/10/11/12">1kk, 1/2/3/4/5/6/7/8/9/10/11/12</option>
								</optgroup>
							</select>
						</div>

					</div>
					<div className="col-md-6">

						<div className="form-group">
							<label htmlFor="domain">{props.t("billing.selectstartmonth")}</label>
							<select id="admin-form-contractmonth" className="custom-select" name="contractmonth" value={props.site.contractmonth} onChange={props.handleInputChange}>
							<option value="" disabled hidden>{props.t("billing.selectmonth")}</option>
								<option value="none">{props.t("billing.nobilling")}</option>
								<optgroup label="12 kk">
									<option value="1">12kk, 1-12</option>
									<option value="2">12kk, 2-1</option>
									<option value="3">12kk, 3-2</option>
									<option value="4">12kk, 4-3</option>
									<option value="5">12kk, 5-4</option>
									<option value="6">12kk, 6-5</option>
									<option value="7">12kk, 7-6</option>
									<option value="8">12kk, 8-7</option>
									<option value="9">12kk, 9-8</option>
									<option value="10">12kk, 10-9</option>
									<option value="11">12kk, 11-10</option>
									<option value="12">12kk, 12-11</option>
								</optgroup>
								<optgroup label="6 kk">
									<option value="1/7">6kk, 1-6 / 7-12</option>
									<option value="2/8">6kk, 2-7 / 8-1</option>
									<option value="3/9">6kk, 3-8 / 9-2</option>
									<option value="4/10">6kk, 4-9 / 10-3</option>
									<option value="5/11">6kk, 5-10 / 11-4</option>
									<option value="6/12">6kk, 6-11 / 12-5</option>
								</optgroup>
								<optgroup label="3 kk">
									<option value="1/4/7/10">3kk, 1-3 / 4-6 / 7-9 / 10-12</option>
									<option value="2/5/8/11">3kk, 2-4 / 5-7 / 8-10 / 11-1</option>
									<option value="3/6/9/12">3kk, 3-5 / 6-8 / 9-11 / 12-2</option>
								</optgroup>
								<optgroup label="1 kk">
									<option value="1+0">+0kk</option>
									<option value="1+1">+1kk</option>
									<option value="1+2">+2kk</option>
								</optgroup>
							</select>
						</div>

					</div>

				</div>

				<div className="row">
					<div className="col-md-6">
						<label htmlFor="monthlyprice">{props.t("billing.pricingbasis")}</label>
						<p>
							Bonsait-{props.site.account} {props.prices[props.site.account]} &euro;/kk<br/>
							{props.site.useagreedprice
							? <>{props.t('billing.agreedprice')}: {props.site.agreedprice} &euro;/kk</>
							: <>{props.t('billing.noagreedprice')}</>
							}
						</p>
					</div>
					<div className="col-md-6">
						<div className="form-group">
							<label htmlFor="monthlyprice">{props.t("billing.monthlyprice")}</label>
							<div className="input-group">
								<div className="input-group-prepend">
									<span className="input-group-text bg-light-gray">€</span>
								</div>
								<input id="monthlyprice" type="text" className="form-control" name="monthlyprice" value={props.site.monthlyprice} onChange={props.handleAnyPriceInputChange} />
								<div className="input-group-append">
									<span className="input-group-text bg-light-gray">{props.t("billing.agreedprice_kk")}</span>
								</div>
							</div>
							
							
						</div>
					</div>
				</div>

			</div>
			}

			{props.site.billing === 'bill' &&
				<React.Fragment>
					<div className="form-group">
						<label htmlFor="billingname">{props.t("billing.receiver")}</label>
						<input id="billingname" type="text" className={props.validations.billingname.isInvalid ? "form-control is-invalid" : "form-control"} name="billingname" value={props.site.billingname} onChange={props.handleInputChange} />
						{props.validations.billingname.isInvalid && <span className="srv-validation-message slideanim slide">{props.validations.billingname.message}</span>}
					</div>
					<div className="form-group">
						<label htmlFor="billingcompany">{props.t("billing.company_ssn")}</label>
						<input id="billingcompany" type="text" className={props.validations.billingcompany.isInvalid ? "form-control is-invalid" : "form-control"} name="billingcompany" value={props.site.billingcompany} onChange={props.handleInputChange} />
						{props.validations.billingcompany.isInvalid && <span className="srv-validation-message slideanim slide">{props.validations.billingcompany.message}</span>}
					</div>
					<div className="form-group">
						<label htmlFor="billingaddress">{props.t("billing.address")}</label>
						<input id="billingaddress" type="text" className={props.validations.billingaddress.isInvalid ? "form-control is-invalid" : "form-control"} name="billingaddress" value={props.site.billingaddress} onChange={props.handleInputChange} />
						{props.validations.billingaddress.isInvalid && <span className="srv-validation-message slideanim slide">{props.validations.billingaddress.message}</span>}
					</div>
					<div className="form-group">
						<label htmlFor="billingaddress2">{props.t("billing.address") + " 2"}</label>
						<input id="billingaddress2" type="text" className="form-control" name="billingaddress2" value={props.site.billingaddress2} onChange={props.handleInputChange} />
					</div>
					<div className="form-row">
						<div className="col">
							<div className="form-group">
								<label htmlFor="zip">{props.t("billing.zip")}</label>
								<input id="zip" className={props.validations.zip.isInvalid ? "form-control is-invalid" : "form-control"} name="zip" value={props.site.zip} onChange={props.handleInputChange} />
								{props.validations.zip.isInvalid && <span className="srv-validation-message slideanim slide">{props.validations.zip.message}</span>}
							</div>
						</div>
						<div className="col">
							<div className="form-group">
								<label htmlFor="city">{props.t("billing.city")}</label>
								<input id="city" className={props.validations.city.isInvalid ? "form-control is-invalid" : "form-control"} name="city" value={props.site.city} onChange={props.handleInputChange} />
								{props.validations.city.isInvalid && <span className="srv-validation-message slideanim slide">{props.validations.city.message}</span>}
							</div>
						</div>
					</div>

					<div className="form-group">
						<label htmlFor="reference">{props.t("billing.reference")}</label>
						<input id="reference" type="text" className="form-control" name="reference" value={props.site.reference} onChange={props.handleInputChange} />
					</div>
					

					{props.site.sendingmethod === 'online' &&
						<React.Fragment>
							<h5 className="mb-1 mt-3">{props.t("billing.einvoice_title")}</h5>
							<div>
								<div className="form-group">
									<label htmlFor="einvoice_address">{props.t("billing.einvoice_address")}</label>
									<input id="einvoice_address" className={props.validations.einvoice_address.isInvalid ? "form-control is-invalid" : "form-control"} name="einvoice_address" value={props.site.einvoice_address} onChange={props.handleInputChange} />
									{props.validations.einvoice_address.isInvalid && <span className="srv-validation-message slideanim slide">{props.validations.einvoice_address.message}</span>}
								</div>
								<div className="form-group">
									<label htmlFor="einvoice_operator">{props.t("billing.einvoice_operator")}</label>
									<input id="einvoice_operator" className={props.validations.einvoice_operator.isInvalid ? "form-control is-invalid" : "form-control"} name="einvoice_operator" value={props.site.einvoice_operator} onChange={props.handleInputChange} />
									{props.validations.einvoice_operator.isInvalid && <span className="srv-validation-message slideanim slide">{props.validations.einvoice_operator.message}</span>}
								</div>
							</div>
						</React.Fragment>
					}

					{props.site.sendingmethod === 'email' &&
						<React.Fragment>
							<h5 className="mb-1 mt-3">{props.t("billing.email_title")}</h5>
							<div className="form-group">
								<label htmlFor="billingemail">{props.t("billing.emailaddress")}</label>
								<input id="billingemail" type="text" className={props.validations.billingemail.isInvalid ? "form-control is-invalid" : "form-control"} name="billingemail" value={props.site.billingemail} onChange={props.handleInputChange} />
								{props.validations.billingemail.isInvalid && <span className="srv-validation-message slideanim slide">{props.validations.billingemail.message}</span>}
							</div>
						</React.Fragment>
					}

				</React.Fragment>
			}
		</div>
	);
}

export default BillingForm;