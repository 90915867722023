import React from 'react';
import { Redirect, withRouter } from 'react-router-dom'
import * as routes from '../../config/routes';
import * as prices from '../../config/prices';
import { withTranslation } from "react-i18next";
import { auth } from '../../firebase/firebase';
import withAuthorization from '../withAuthorization';
import { API } from '../../config/config';

import langcodes from '../../config/language-codes';

import { stringtoFilename, checkFilename, getPlanPrice, checkDefaultProfit, checkNum, validateBilling } from '../../helpers/Helper';
import Loader from '../../includes/Loader';
import AuthService from '../Authentication/Auth';
import Models from './Models';
import Plans from './Plans';
import BillingForm from './BillingForm';
import DatePicker from 'react-date-picker';
import EventBus from 'eventbusjs';

const INITIAL_VALIDATORS = {
    billingname: { isInvalid: false, message: '' },
    billingcompany: { isInvalid: false, message: '' },
    billingemail: { isInvalid: false, message: '' },
    billingaddress: { isInvalid: false, message: '' },
    city: { isInvalid: false, message: '' },
    zip: { isInvalid: false, message: '' },
    einvoice_address: { isInvalid: false, message: '' },
    einvoice_operator: { isInvalid: false, message: '' },
    newdomain: { isInvalid: false, message: '' },
    customdomains: { isInvalid: false, message: '' },
    isValid: true
}

class NewWebsite extends React.Component {
    constructor(props) {
        super(props);
        this.validations = { ...INITIAL_VALIDATORS };
        this.submitted = false;
        this.state = { 
            title: '', 
            name: '',
            expires: null,
            published: false,
            domain: '',
            pendingdomains: '',
            validdomain: '',
            firstlang: (AuthService.userdata && AuthService.userdata.bonsaituser.defaultsitelang) ? AuthService.userdata.bonsaituser.defaultsitelang : 'en',
            firstlangtitle: (AuthService.userdata && AuthService.userdata.bonsaituser.defaultsitelang) ? this.getLangTitle(AuthService.userdata.bonsaituser.defaultsitelang) : 'English',
            model: 'empty',
            theme: AuthService.userdata ? AuthService.userdata.bonsaituser.themes[0].code : '',
            manager: (AuthService.userdata && AuthService.userdata.bonsaituser.managers.length<2) ? AuthService.userdata.bonsaituser.managers[0].id : '',
            account: 'M',
            billing: 'bill',
            billingname: AuthService.userdata ? AuthService.userdata.displayName : '',
            billingcompany: '',
            billingemail: AuthService.userdata ? AuthService.userdata.email : '',
            billingperiod: '12',
            sendingmethod: 'online',
            billingaddress: '',
            billingaddress2: '',
            city: '',
            zip: '',
            einvoice_address: '',
            einvoice_operator: '',
            monthlyprice: prices.DEFAULT_PROFIT_M,
            nocommission: true,
            planprice: 0,
            totalprice: 0,
            error: null,
            submitDisabled: false,
            loading: false
        }
    }

    onCreateSite = (event) => {
        event.preventDefault();
        this.setState({ error: null, submitDisabled: true });

        const authUser = AuthService.getuser();
        const managers = authUser.bonsaituser.managers;

        let isValid = this.validateForm();
        if (managers && managers.length > 0) {
            isValid = true;
        }

        const { t, history } = this.props;
        let formdata = this.state;
        this.submitted = true;

        if (isValid && formdata.billing !== "creditcard" && checkFilename(formdata.name)) {

            this.setState({ loading: true });
            // delete useless from formdata
            delete formdata.error;
            delete formdata.submitDisabled;
            delete formdata.validdomain;
            delete formdata.planprice;
            delete formdata.totalprice;
            
            auth.currentUser.getIdToken().then(idtoken => {
                formdata.idtoken = idtoken;
                fetch(API + "/sites", {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'PUT',
                    body: JSON.stringify(formdata),
                })
                .then(results => results.json())
                .then(sitecreated => {
                    if (sitecreated.success) {
                        history.push(routes.WEBSITES);
                    } else {
                        this.setState({ error: t("errormsg"), submitDisabled: false, loading: false });
                        window.scrollTo(0, 0);
                    }
                });
            });
        } else {
            let error = t("error.validate");
            this.setState({ error, submitDisabled: false });
        }
    }

    onCheckSite = () => {
        this.setState({ error: null });

        const { t } = this.props;
        const { name, title } = this.state;
        const authUser = AuthService.getuser();
        let formdata = {};

        auth.currentUser.getIdToken().then(idtoken => {
            formdata.idtoken = idtoken;
            fetch(API + "/check?name=" + stringtoFilename(name), {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formdata)
            })
            .then(results => results.json())
            .then(data => {
                if (data.site) {
                    this.setState({ 
                        error: t("alreadyexists"), 
                        domain: '' 
                    });
                    window.scrollTo(0, 0);
                } else {
                    const trimmed = title.replace(/[^\w]/gi, '').toLowerCase();
                    const defaultdomain = authUser.bonsaituser ? authUser.bonsaituser.defaultdomain : '.bonsait.fi';
                    const domainstr = trimmed + defaultdomain;
                    this.setState({
                        domain: domainstr, 
                        validdomain: trimmed, 
                    });
                }
            })
            .catch(error => console.log(error));
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'billing' || name === 'sendingmethod') {
            if (this.state.billing !== value || this.state.sendingmethod !== value) {
                this.resetValidators();
            }
        }

        this.setState({ [name]: value }, () => {
            if (this.submitted) {
                this.validateForm();
            }
            if (name === 'monthlyprice') {
                this.handleMonthlyPrice(this.state.account);
            }
            /*
            if (name === 'nocommission') {
                this.setState({ monthlyprice: this.getDefaultProfit(this.state.account) });
                this.handleMonthlyPrice(this.state.account);
            }
            */
        });
    }

    handleDefaultlangChange = (event) => {
        const target = event.target;
        this.setState({ 
            firstlang: target.value,
            firstlangtitle: this.getLangTitle(target.value)
        });
        
    }

    handlePlan = (plan) => {
        this.setState({ account: plan });
        /*
        this.setState({ 
            account: plan, 
            monthlyprice: this.getDefaultProfit(plan) 
        }, () => {
            this.handleMonthlyPrice(plan);
        });
        */
    }

    handleModel = (model) => {
        this.setState({ model: model });
    }

    handleName = (event) => {
        const targetname = event.target.name;
        const value = event.target.value;
        const trimmedvalue = stringtoFilename(value);
        this.setState({ 
            [targetname]: value,
            name: trimmedvalue
        });
    }

    handleExpire = value => this.setState({ expires: value });

    handleMonthlyPrice = (plan) => {
        const authUser = AuthService.getuser();
        const role = authUser.bonsaituser.role;
        const { monthlyprice } = this.state;

        let price = getPlanPrice(role, plan, prices);
        let total = 0;

        total = price + checkNum(monthlyprice);
        this.setState({ 
            planprice: price,
            totalprice: total
        });
    }

    getDefaultProfit = (plan) => {
        return checkDefaultProfit(plan, prices);
    }

    componentDidMount() {
        const { account } = this.state;
		this.handleMonthlyPrice(account);

		// open side menu
		EventBus.dispatch("sidemenu-changed", true);

    }

    validateForm = () => {
        let validators = { ...INITIAL_VALIDATORS }
        const { t } = this.props;
        const validations = validateBilling(validators, this.state, t);
        this.validations = validations;
        this.setState({ validated: true });
        return validations.isValid;
    }

    resetValidators = () => {
        this.validations = { ...INITIAL_VALIDATORS };
        this.setState({ error: null });
    }

    getLangTitle = (code) => {
        let name = code;
        langcodes.map((langcode, index) => {
            if (langcode.code==code) {
                name = langcode.name;
            }
        })
        return name;
    }

    render() {
        const { 
            title, 
            expires,
            domain,
            validdomain,
            firstlang,
            model,
            account,
            theme,
            manager,
            error,
            submitDisabled,
            planprice,
            totalprice,
            loading
        } = this.state;

        const isInvalid = 
            title.length < 3 ||
            domain.length < 2 ||
            model === '' ||
            account === '' ||
            submitDisabled;

        const authUser = AuthService.getuser();
        const { t } = this.props;
        const sitecopy = this.props.location.state;

        if (loading) {
            return (
                <div className="container text-center mt-5">
                    <h3 className="mb-5">{t("creating_your_site")}</h3>
                    <Loader size="3x" classes="text-primary mt-2" />
                </div>
            );
            
        }

        return (
            <div className="container">
                <div className="card mt-4">
                    <div className="card-header bg-secondary-dark highlight text-white">{t("newsite")}</div>
                    <div className="card-body">
                        <form onSubmit={this.onCreateSite}>
                            <div className="form-group">
                                <label htmlFor="title">{t("sitename")}</label>
                                <input id="site-form-title" className="form-control" type="text" name="title" onChange={this.handleName} onBlur={this.onCheckSite} value={title}/>
                            </div>

                            { authUser.bonsaituser.managers &&
                                <div className="form-group">
                                    <label htmlFor="publishedstart">{t("expires")}</label>
                                    <DatePicker onChange={this.handleExpire} className="bonsait-datepicker" locale="fi-FI" value={expires} tileClassName="bonsait-datepicker-tile" />
                                </div>
                            }
    
                            { error &&
                                <div className="alert alert-danger mt-3">{error}</div>
                            }

                            <div className="row">
                                <div className="col-md-6">
                                    <label htmlFor="title">{t("domain.main")}</label>
                                    <div className="input-group mb-3">
                                        <div className="input-group-append">
                                            <span className="input-group-text bg-light">{validdomain}{authUser.bonsaituser.defaultdomain}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="title">{t("firstlang_title")}</label>
                                        <select id="site-form-firstlang" className="custom-select" name="firstlang" onChange={this.handleDefaultlangChange} value={firstlang}>
                                            {
                                                langcodes.map((langcode, index) => {
                                                    return (
                                                        <option key={index} value={langcode.code}>{langcode.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    
                                </div>
                            </div>
    
                            
    
                            <Models sitecopy={sitecopy} handleModel={this.handleModel} model={model} t={t} />
                            
                            { authUser.bonsaituser.themes &&
                                <div className="form-group">
                                    <label htmlFor="title">{t("admintheme")}</label>
                                    <select id="site-form-admintheme" className="custom-select" name="theme" onChange={this.handleInputChange} value={theme}>
                                        { 
                                            authUser.bonsaituser.themes.map((theme, index) => {
                                                return (
                                                    <option key={index} value={theme.name}>{theme.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            }
    
                            { authUser.bonsaituser.managers &&
                                <div className="form-group">
                                    <label htmlFor="title">{t("manager")}</label>
                                    <select id="site-form-manager" className="custom-select" name="manager" onChange={this.handleInputChange} value={manager}>
                                        <option value="">{t("notchosen")}</option>
                                        {
                                            authUser.bonsaituser.managers.map((manager, index) => {
                                                return (
                                                    <option key={index} value={manager.id}>{manager.company}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            }
    
                            <Plans handlePlan={this.handlePlan} account={account} authUser={authUser} t={t} prices={prices} />
                            
                            { !authUser.bonsaituser.managers &&
                                <BillingForm 
                                    t={t} 
                                    site={this.state}
                                    handleInputChange={this.handleInputChange}
                                    error={error}
                                    authUser={authUser}
                                    validations={this.validations}
                                    planprice={planprice}
                                    totalprice={totalprice}
                                />
                            }

                            { error &&
                                <div className="alert alert-danger mt-3">
                                    {error}
                                </div>
                            }
							<hr/>
                            <button className="btn btn-secondary btn-lg mr-2" type="submit" disabled={isInvalid}>{t("create")}</button> 
                            <button className="btn btn-link" style={{float: "right"}} type="button" onClick={() => this.props.history.goBack(-1)}>{t("cancel")}</button> 
                        </form>
                    </div>                
                </div>
            </div>
        );
    }
}

export default withAuthorization(withRouter(withTranslation("Sites")(NewWebsite)));

            