import React from 'react';
import { Redirect, withRouter } from 'react-router-dom'
import { withTranslation } from "react-i18next";
import { API } from '../../config/config';
import { auth } from '../../firebase/firebase';
import moment from 'moment';
import Moment from 'react-moment';

import EventBus from 'eventbusjs';
import Loader from '../../includes/Loader';
import withAuthorization from '../withAuthorization';
import AuthService from '../Authentication/Auth';

class BillingSMS extends React.Component {

	constructor(props) {
        super(props);
        this.state = {
            loading: false,
            year: "2022",
			billings: []
		}
    }

	fetchBillings = () => {

        this.setState({ 
            loading: true 
        });

        auth.currentUser.getIdToken().then(idtoken => {
            const json = { idtoken: idtoken }
            fetch(API + "/billings/year?year=" + this.state.year, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(json)
            })
            .then(results => results.json())
            .then(result => {
                this.setState({ 
                    billings: result.billings,
                    loading: false 
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
        });
    }

	// Create our number formatter.
	formatter = new Intl.NumberFormat('fi', {
		style: 'currency',
		currency: 'EUR',
		// These options are needed to round to whole numbers if that's what you want.
		//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
		//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	});


	calcTotalPrice = () => {
		let total = 0.00;
		this.state.billings.forEach(billing => {
			total = total + billing.total;
		});
		return total
    }

	componentDidMount() {
        this.fetchBillings();
		// open side menu
		EventBus.dispatch("sidemenu-changed", true);
    }

	render() {
		const authUser = AuthService.getuser();
		const { loading, billings, m} = this.state
		const { t } = this.props;

		return (
			<>
				<div className="container">
					<div className="card mt-4">
						<div className="card-header bg-secondary-dark highlight text-white">{t("statistic.title")}</div>
						<div className="card-body">

							{loading 
							? 
								<div className="text-center mt-4">
									<Loader size="3x" classes="text-primary" />
								</div>
							: 
								<>
								{(billings) &&
									<div className="billinglist">

										<div className="row mb-1 ">
											<div className="col-md-6"><strong>{t("billing.month")}</strong></div>
                                            <div className="col-md-2 text-right"><strong>{t("billing.price")}</strong></div>
                                            <div className="col-md-2 text-right"><strong>{t("billing.vat")}</strong></div>
											<div className="col-md-2 text-right"><strong>{t("billing.totalwithvat")}</strong></div>
										</div>
										<hr />
										{billings.map((billing, i) => {
											return (
												<div key={i}>
													<div className="row mb-6">
														<div className="col-md-6">
															{moment("1." + billing.month + ".2022", "D.M.YYYY").format("MMMM")}
														</div>
														<div className="col-md-2 text-right">
                                                            {this.formatter.format(billing.total)}
														</div>
                                                        <div className="col-md-2 text-right">
                                                            {this.formatter.format((billing.total/100)*24)}
														</div>
                                                        <div className="col-md-2 text-right">
                                                            {this.formatter.format((billing.total*1.24))}
														</div>
													</div>
													<hr />
												</div>
											)
										})}

                                        <div className="row mb-1 ">
											<div className="col-md-8"></div>
											<div className="col-md-2 text-right">
												{t("billing.total")}<br/>
												{t("billing.vat")}<br/>
												<strong>{t("billing.totalwithvat")}</strong>
											</div>
											<div className="col-md-2 text-right">
												{this.formatter.format(this.calcTotalPrice())}<br/>
												{this.formatter.format((this.calcTotalPrice()/100)*24)}<br/>
												{this.formatter.format(this.calcTotalPrice()*1.24)}
											</div>
										</div>

									</div>
								}
								</>
							}

						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withAuthorization(withRouter(withTranslation("Admin")(BillingSMS)));