import React from 'react';
import { Link, withRouter } from 'react-router-dom'
import { withTranslation } from "react-i18next";
import { API } from '../../config/config';
import * as routes from '../../config/routes';
import { auth } from '../../firebase/firebase';
import moment from 'moment';
import Moment from 'react-moment';

import EventBus from 'eventbusjs';
import Loader from '../../includes/Loader';
import withAuthorization from '../withAuthorization';
import AuthService from '../Authentication/Auth';

class Provision extends React.Component {

	constructor(props) {
        super(props);
        this.state = {
            loading: false,
			resellers: [],
            resellerid: null,
			sites: []
		}
    }

	handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
			resellerid: value,
			loading: true
		}, () => {
            this.fetchProvisions();
		});
    }

	fetchProvisions = () => {
        this.setState({ 
            loading: true 
        });
        auth.currentUser.getIdToken().then(idtoken => {
            const json = { idtoken: idtoken }
            fetch(API + "/billings/provisions?resellerid=" + this.state.resellerid + "&year=2023", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(json)
            })
            .then(results => results.json())
            .then(result => {
                this.setState({ 
                    sites: result.sites,
                    loading: false 
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
        });
    }

	fetchResellers = () => {

        this.setState({ 
            loading: true 
        });
        auth.currentUser.getIdToken().then(idtoken => {
            const json = { idtoken: idtoken }
            fetch(API + "/billings/resellers", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(json)
            })
            .then(results => results.json())
            .then(result => {
                this.setState({ 
                    resellers: result.managers,
                    loading: false 
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
        });
    }

	// Create our number formatter.
	formatter = new Intl.NumberFormat('fi', {
		style: 'currency',
		currency: 'EUR',
		// These options are needed to round to whole numbers if that's what you want.
		//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
		//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	});

	calcTotalPrice = () => {
		let total = 0.00;
		this.state.sites.forEach(site => {
			if (this.checkEligible(site)) {
				total = total + this.calcProvision(site);
			}
		});
		return total
    }


	checkEligible = (site) => {
		let isEligible = true;
		if (site.monthlyprice) {
			// If old price no comission
			if (site.monthlyprice==38) {
				isEligible = false;
			} else if (site.monthlyprice==63) {
				isEligible = false;
			}
		}
		if (!site.billingmonth) {
			isEligible = false;
		}
		return isEligible
    }

	calcProvision = (site) => {
		let provision = 0.00;
		if (this.checkEligible(site)) {
			if (site.account=='XS') {
				provision = 3.00;
			} else if (site.account=='S') {
				provision = 6.00;
			} else if (site.account=='M') {
				provision = 11.00;
			} else if (site.account=='L') {
				provision = 11.00;
			} else if (site.account=='XL') {
				provision = 30.00;
			}
		}
		return provision
    }

	componentDidMount() {
		this.fetchResellers();
		// open side menu
		EventBus.dispatch("sidemenu-changed", true);
    }

	render() {
		const authUser = AuthService.getuser();
		const { loading, sites, resellers, resellerid, m} = this.state
		const { t } = this.props;

		return (
			<>
				<div className="container">
					<div className="card mt-4">
						<div className="card-header bg-secondary-dark highlight text-white">{t("provisions.title")}</div>
						<div className="card-body">

							{loading 
							? 
								<div className="text-center mt-4">
									<Loader size="3x" classes="text-primary" />
								</div>
							: 
								<>
								{(resellers) &&
									<div className="form-group mb-4">
										<label htmlFor="resellerid">{t("provisions.selectreseller")}</label>
										<select id="admin-form-reseller" className="custom-select" name="resellerid" value={resellerid} onChange={this.handleChange}>
											<option value="" disabled hidden>{t("provisions.selectreseller")}</option>
											{resellers.map((reselleritem, r) => {
											return (
												<option key={r} value={reselleritem.id}>{reselleritem.company}</option>
											)
										})}
										</select>
									</div>
								}

								{(sites) &&
									<div className="billinglist">

										<div className="row mb-1 ">
											<div className="col-md-4"><strong>{t("provisions.sitename")}</strong></div>
                                            <div className="col-md-2"><strong>{t("provisions.billingmonth")}</strong></div>
                                            <div className="col-md-2 text-right"><strong>{t("provisions.monthlyprice")}</strong></div>
											<div className="col-md-2 text-center"><strong>{t("provisions.eligible")}</strong></div>
											<div className="col-md-2 text-right"><strong>{t("provisions.provisionamount")}</strong></div>
										</div>
										<hr />
										{sites.map((site, i) => {
											return (
												<div key={i}>
													<div className="row mb-6">
														<div className="col-md-4">
															<Link className="title-link" to={routes.WEBSITE_HOME + `/${site.id}`}>{site.title} {site.account && <>({site.account})</>}</Link>
														</div>
														<div className="col-md-2">
															{site.billingmonth}
														</div>
                                                        <div className="col-md-2 text-right">
															{this.formatter.format(site.monthlyprice)}
														</div>
                                                        <div className="col-md-2 text-center">
															{(this.checkEligible(site)) && 
																<i className={'fa fa-check-circle-o'}></i>
															}
														</div>
														<div className="col-md-2 text-right">
															{this.formatter.format(this.calcProvision(site))}
														</div>
													</div>
													<hr />
												</div>
											)
										})}

                                        <div className="row mb-1 ">
											<div className="col-md-8"></div>
											<div className="col-md-2 text-right">
												{t("provisions.total")}<br/>
												{t("provisions.vat")}<br/>
												<strong>{t("provisions.totalwithvat")}</strong>
											</div>
											<div className="col-md-2 text-right">
												{this.formatter.format(this.calcTotalPrice())}<br/>
												{this.formatter.format((this.calcTotalPrice()/100)*24)}<br/>
												{this.formatter.format(this.calcTotalPrice()*1.24)}
											</div>
										</div>

									</div>
								}
								</>
							}

						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withAuthorization(withRouter(withTranslation("Admin")(Provision)));