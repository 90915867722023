import React from 'react';
import { API } from '../../config/config';
import { auth } from '../../firebase/firebase';

class Users extends React.Component {
    constructor() {
        super();
        this.state = {
            permission_to: '',
            administrators: {},
            invitedusers: {},
            error: null
        }
    }

    handleInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({ [name]: value });
    }

    fetchUsers = (siteid) => {
        // fetch site admins
        auth.currentUser.getIdToken().then(idtoken => {
            const json = { idtoken: idtoken }
            fetch(API + "/listusers?id=" + siteid, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(json)
            })
            .then(results => results.json())
            .then(data => this.setState({ administrators: data.users, invitedusers: data.invitedusers }))
            .catch(error => console.log(error) );
        });
    }

    onGrantSiteAccess = (e) => {
        e.preventDefault();
        const { permission_to } = this.state;
        const { siteid, toggleAccessForm, t } = this.props;
        // send permission for site via email
        this.setState({ error: null });

        auth.currentUser.getIdToken().then(idtoken => {
            const formData = {
                'permission_to': permission_to,
                'idtoken': idtoken
            }

            fetch(API + "/grantsiteaccess?siteid=" + siteid, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(formData)
            })
            .then(results => results.json())
            .then(result => { 
                if (result.success) {
                    this.setState({ permission_to: '' });
                    toggleAccessForm();
                    this.fetchUsers(siteid);
                } else {
                    this.setState({ error: t("error.hasaccess") });
                }
            })
            .catch(error => console.log(error));
        });
    }

    onRemoveSiteAccess = (siteuser, siteid, temp) => {
        const { t } = this.props;

        let agreed = false;
        const r = window.confirm(t("confirmuser"));
        if (r === true) {
            agreed = true;
        } else {
            agreed = false;
        } 

        if (agreed) {
            let tempuseremail = "";
            if (temp) {
                tempuseremail = siteuser.email
            }

            auth.currentUser.getIdToken().then(idtoken => {
                const formdata = {
                    siteid: siteid,
                    emailtemp: tempuseremail,
                    idtoken: idtoken
                }
                fetch(API + "/users?id=" + siteuser.id, { 
                    method: "DELETE",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formdata)
                })
                .then(results => results.json())
                .then(response => {
                    if (response.success) {
                        const { administrators, invitedusers } = this.state;
                        if (administrators.indexOf(siteuser) > -1) {
                            administrators.splice(administrators.indexOf(siteuser), 1);
                            this.setState({ administrators });
                        }
                        if (invitedusers.indexOf(siteuser) > -1) {
                            invitedusers.splice(invitedusers.indexOf(siteuser), 1);
                            this.setState({ invitedusers });
                        }
                    } else {
                        throw Error();
                    }
                })
                .catch(() => this.setState({ error: t("errormsg") }));
            });
        }   
    }

    componentDidMount() {
        const { siteid } = this.props;
        this.fetchUsers(siteid);
    }

    render() {

        const { t, showaccessform, toggleAccessForm } = this.props;
        const { administrators, invitedusers, permission_to, error } = this.state;
        const isInvalid = permission_to === '';

        const noadministrators = administrators.length === 0 && invitedusers.length === 0

        return (
            <div className="users-container">
                    { administrators.length > 0 &&
                        administrators.map((siteuser, index) => {
                            return (
                                <div key={index} className="row mb-1 px-2 py-2 user-row">
                                    <div className="col-md-4">
                                        <div className="icon-wrap">
                                            <i className="fa-solid fa-user text-secondary-dark"></i>
                                        </div>
                                        <span style={{fontSize: '0.9em'}}>{siteuser.name}</span>
                                    </div>
                                    <div className="col-md-7">
                                        <span style={{fontSize: '0.9em'}}>{siteuser.email}</span>
                                    </div>
                                    <div className="col-md-1">
                                        <span className="span-link" onClick={() => this.onRemoveSiteAccess(siteuser, this.props.siteid, null)}>
                                            <i className="fa-solid fa-xmark pull-right removable-item-icon text-danger" style={{marginTop: '0.3rem'}}></i>
                                        </span>
                                    </div>
                                </div>
                            )
                        })
                    }
                    
                    { invitedusers.length > 0 && 
                        <hr/>
                    }

                    { invitedusers.length > 0 &&
                        invitedusers.map((siteuser, index) => {
                            return (
                                <div key={index} className="row mb-1">
                                    <div className="col-md-11">
                                        <div className="icon-wrap">
                                            <i className="fa-solid fa-envelope text-secondary-dark"></i>
                                        </div>
                                        <span style={{fontSize: '0.8em'}}>{siteuser.email}</span></div>
                                    <div className="col-md-1">
                                        <span className="span-link" onClick={() => this.onRemoveSiteAccess(siteuser, this.props.siteid, true)}>
                                            <i className="fa-solid fa-xmark pull-right removable-item-icon text-danger" style={{marginTop: '0.3rem'}}></i>
                                        </span>
                                    </div>
                                </div>
                                
                            )
                        })
                    }

                    { noadministrators &&
                        <small className="form-text text-medium-gray ">{t("admins.empty")}</small>
                    }
                    
                    { showaccessform &&
                        <div className="accessform mt-4">
                            <hr/>
                            <div className="form-group">
                                <div className="input-group">
                                    <input type="email" className="form-control" name="permission_to" onChange={this.handleInputChange} value={permission_to} />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary btn-sm" type="button" disabled={isInvalid} onClick={this.onGrantSiteAccess}>{t("admins.permission")}</button>
                                    </div>
                                    
                                </div>
                                <small className="form-text text-muted">{t("admins.desc")}</small>
                            </div>
                        </div>
                    }
                    { error !== null &&
                        <div className="alert alert-warning mt-3">
                            {error}
                        </div>
                    }
                    <button className="btn btn-default btn-sm d-block mt-4" type="button" onClick={toggleAccessForm}>{showaccessform ? t("admins.hide") : t("admins.adduser")}</button>
            </div>
        );
    }
}

export default Users;