import React from 'react';
import AuthService from '../Authentication/Auth';
import Publish from './Publish';
import SignInPage from '../Authentication/SignInPage';
import { withTranslation } from 'react-i18next';

const PublishPage = (props) => {
    const isAuthenticated = AuthService.isAuthenticated;
    const authUser = AuthService.getuser();
    return (
        <div>
        { isAuthenticated
            ? <Publish {...props} authUser={authUser} />
            : <SignInPage/>
        }
        </div>
    );

}

export default withTranslation("Authentication")(PublishPage);