import { db } from '../firebase/firebase';

export const edit = (coll, id, data) => {
    return db.collection(coll).doc(id).set(data, {merge: true});
}

export const save = (coll, data) => {
    return db.collection(coll).add(data);
}

export const update = (coll, id, data) => {
    return db.collection(coll).doc(id).update(data);
}

export const remove = (coll, id) => {
    return db.collection(coll).doc(id).delete();
}

export const findById = (coll, id) => {
    return db.collection(coll).doc(id).get();
}

export const find = (coll, query) => {
    return db.collection(coll).where(query.clause, query.operator, query.param).get();
}



