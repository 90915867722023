import React from 'react';
import { Link } from 'react-router-dom';
import * as routes from '../../config/routes';

const PublishResult = (props) => {

    const { t, publishtype, customdomains, domains } = props;
    let mydomain = domains;
    if (domains.indexOf(',')>-1) {
        mydomain = domains.split(',')[0];
    }

    if (!mydomain) {
        return <p className="text-center">{t("errormsg") + "!"}</p>
    }

    return (
        <div className="container" style={{ maxWidth: '650px' }}>
            {publishtype === 'demo' &&
                <div className="text-center mt-4">
                    <i className="fa-solid fa-check fa-4x mb-4 text-success"></i>
                    <h4>{t("website_is_now_published")}</h4>
                    <p>{t("your_domain")}<br />
                        <a href={'https://' + mydomain} target="_blank" rel="noopener noreferrer">{mydomain}</a>
                    </p>
                    <a href={'https://' + mydomain + '/login'} target="_blank" rel="noopener noreferrer" className="btn btn-primary mb-3 mt-3">{t("movetoeditor")}</a>
                    <Link to={routes.WEBSITES} className="ml-2 btn btn-default">{t("back")}</Link>
                </div>
            }

            {publishtype === 'new' &&
                <div className="text-center mt-4">
                    <i className="fa-solid fa-check fa-4x mb-4 text-success"></i>
                    <h4>{t("website_waiting_for_publish")}</h4>
                    <p>{t("website_waiting_for_publish_own_desc")}</p>
                    <p>{t("while_waiting_txt")}</p>
                    <p>{t("your_domain")}<br />
                        <a href={'https://' + mydomain} target="_blank" rel="noopener noreferrer">{mydomain}</a>
                    </p>
                    <a href={'https://' + mydomain + '/login'} target="_blank" rel="noopener noreferrer" className="btn btn-primary mt-3 mb-3">{t("movetoeditor")}</a>
                    <Link to={routes.WEBSITES} className="ml-2 btn btn-default">{t("back")}</Link>
                </div>
            }

            {publishtype === 'own' &&
                <div className="text-center mt-4">
                    <i className="fa-solid fa-check fa-4x mb-4 text-success"></i>
                    <h4>{t("website_waiting_for_publish")}</h4>
                    <p>{t("website_waiting_for_publish_desc")}</p>
                    <div className="text-left publish-desc pt-3 pb-3 mb-4">
                        {t("ask_redirect")}

                        <div className="mt-2 mb-2">
                            {customdomains.length &&
                                customdomains.map(element => {
                                    return (
                                        <p key={element} style={{ lineHeight: '1', marginBottom: '0' }}><strong>{element}</strong></p>
                                    )
                                })
                            }
                        </div>
                        {t("www_and_without") + ". "}
                        {t("add_spf_namespace")}
                    </div>
                    <p>{t("moreinfo")}</p>
                    <p>{t("your_domain")}<br />
                        <a href={'https://' + mydomain} target="_blank" rel="noopener noreferrer">{mydomain}</a>
                    </p>
                    <a href={'https://' + mydomain + '/login'} target="_blank" rel="noopener noreferrer" className="btn btn-primary mt-3 mb-3">{t("movetoeditor")}</a>
                    <Link to={routes.WEBSITES} className="ml-2 btn btn-link">{t("back")}</Link>
                </div>
            }

            {publishtype === 'existing' &&
                <div className="text-center mt-4">
                    <i className="fa-solid fa-check fa-4x mb-4 text-success"></i>
                    <h4>{t("website_is_now_published")}</h4>
                    <p>{t("your_domain")}<br />
                        <a href={'https://' + mydomain} target="_blank" rel="noopener noreferrer">{mydomain}</a>
                    </p>
                    <a href={'http://' + mydomain + '/login'} target="_blank" rel="noopener noreferrer" className="btn btn-primary mb-3 mt-3">{t("movetoeditor")}</a>
                    <Link to={routes.WEBSITES} className="ml-2 btn btn-default">{t("back")}</Link>
                </div>
            }

        </div>
    );
}

export default PublishResult;