import React from 'react';
import { withTranslation } from "react-i18next";
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import AuthService from '../Authentication/Auth';

const INITIAL_STATE = {
    currentStep: 1,
    siteURL: '',
    error: null,
    loading: false
};

const StartTrialPage = (props) =>
    <div className="container mt-4">
        <h1 className="text-center" style={{ marginBottom: '2.5rem'}}>{props.t("getstarted")}</h1>
        <StartTrial {...props} />
    </div>

class StartTrial extends React.Component { 
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    onClickNext = () => {
        const { currentStep } = this.state;
        this.setState({ currentStep: currentStep + 1 });
    }

    renderResult = (siteURL) => {
        this.setState({ siteURL }, () => this.onClickNext());
    }

    render() {

        const { currentStep, siteURL } = this.state;
        const { t } = this.props;
        let isAuthenticated = false;

        const authUser = AuthService.getuser();

        if (authUser && authUser.uid) {
            isAuthenticated = true;
        }

        return(
            <React.Fragment>
                <div className="steps-form mb-5">
                    <div className="steps-row setup-panel">
                        <div className="steps-step">
                            <span className={currentStep === 1 ? 'btn btn-secondary btn-circle' : 'btn btn-blue-ish btn-circle'}>1</span>
                        </div>
                        <div className="steps-step">
                            <span className={currentStep === 2 ? 'btn btn-secondary btn-circle' : 'btn btn-blue-ish btn-circle'} disabled={true}>2</span>
                        </div>
                        <div className="steps-step">
                            <span className={currentStep === 3 ? 'btn btn-secondary btn-circle' : 'btn btn-blue-ish btn-circle'} disabled={true}>3</span>
                        </div>
                    </div>
                </div>

                { currentStep === 1 &&
                    <StepOne nextStep={this.onClickNext} isAuthenticated={isAuthenticated} />
                }
                { currentStep === 2 &&
                    <StepTwo nextStep={this.onClickNext} authUser={authUser} renderResult={this.renderResult} />
                }
                { currentStep === 3 &&
                    <StepThree siteURL={siteURL} t={t} authUser={authUser}/>
                }
            </React.Fragment>
        );

    }
}

export default withTranslation("Authentication")(StartTrialPage);