import React from 'react';
import punycode from 'punycode/';
import { API } from '../../config/config';
import { auth } from '../../firebase/firebase';
import AuthService from '../Authentication/Auth';

class SendingDomain extends React.Component {
    constructor() {
        super();
        this.state = {
            isDomainInvalid: false,
			senderdomains: [],
            inputdomain: ''
        }
    }

    handleInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({ [name]: value });
    }

    validateDomain = (domain) => {
		const regexp = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/i;
		if (domain && regexp.test(punycode.toASCII(domain))) {
			// if (this.state.connectdomain && this.state.connectdomain.indexOf('.')>-1) {
			this.setState({ isDomainInvalid: false });
			return true;
		} else {
			this.setState({ isDomainInvalid: true });
			return false;
		}
	}	

	connectDomain = () => {
		const authUser = AuthService.getuser();
        const { id } = authUser.bonsaituser;
        const { siteid } = this.props;

		const valid = this.validateDomain(this.state.inputdomain);
		if (valid) {
			auth.currentUser.getIdToken().then(idtoken => {
				const json = { 
					idtoken: idtoken,
					siteid: siteid,
                    userid: id,
					senderdomain: punycode.toASCII(this.state.inputdomain)
				}
				fetch(API + `/sites/activatesenderdomain`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(json)
				})
				.then(results => results.json())
				.then(result => {
                    if (result.domain.domain) {
                        let domains = this.state.senderdomains;
                        let newdomain = {
                            name: result.domain.domain.name,
                            valid: false
                        }
                        domains.push(newdomain)
                        this.setState({
                            senderdomains: domains
                        })
                    }
				})
				.catch(error => (error));
			});
		}
	}

    checkDomain = (domain) => {
        const authUser = AuthService.getuser();
        const { id } = authUser.bonsaituser;
        const { t, siteid } = this.props;

		const valid = this.validateDomain(domain);
		if (valid) {

            // get right domain
            let checkeddomain = null;
            let domainlist = JSON.parse(JSON.stringify(this.state.senderdomains))
            for (var i = 0; i < domainlist.length; i++) { 
                if (domainlist[i].name===domain) { 
                    checkeddomain = domainlist[i];
                }
            }

            if (checkeddomain) {

                checkeddomain.loading = true;
                this.setState({
                    senderdomains: domainlist
                })

                auth.currentUser.getIdToken().then(idtoken => {
                    const json = { 
                        idtoken: idtoken,
                        siteid: siteid,
                        userid: id,
                        senderdomain: punycode.toASCII(checkeddomain.name)
                    }
                    fetch(API + `/sites/getsenderdomain`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(json)
                    })
                    .then(results => results.json())
                    .then(result => {
                        if (result.validated) {
                            checkeddomain.valid = true;
                        }
                        checkeddomain.dnsinfo = result.domain.sending_dns_records
                        checkeddomain.loading = false;

                        this.setState({
                            senderdomains: domainlist
                        })
                        
                    })
                    .catch(error => (error));
                });
            }

		}
    }

    removeDomain = (domain) => {
        const authUser = AuthService.getuser();
        const { id } = authUser.bonsaituser;
        const { t, siteid } = this.props;

		const valid = this.validateDomain(domain);
		if (valid && confirm(t('email.remove_confirm'))) {
			auth.currentUser.getIdToken().then(idtoken => {
				const json = { 
					idtoken: idtoken,
					siteid: siteid,
                    userid: id,
					senderdomain: punycode.toASCII(domain)
				}
				fetch(API + `/sites/removesenderdomain`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(json)
				})
				.then(results => results.json())
				.then(result => {
                    if (result.senderdomains) {
                        this.setState({
                            senderdomains: result.senderdomains.senderdomains
                        })
                    }
				})
				.catch(error => (error));
			});
		}
    }

    componentDidMount() {
        const authUser = AuthService.getuser();
        const { siteid, domains } = this.props;
        const { id } = authUser.bonsaituser;

        if (domains) {
            this.setState({ senderdomains: domains });
        }
    }

    render() {

        const { t } = this.props;
        const { senderdomains, inputdomain, isDomainInvalid } = this.state;

        return (
            <div className="senderdomain-container">
                <h4>{t("email.title")}</h4>
                <p>{t("email.info")}</p>
                
                {senderdomains.length>=2 
                ?
                <div className="row">
                    <div className="col-md-12">
                        <p>{t('email.domainfield_limit')}</p>
                    </div>
                </div>
                :
                <div className="row">
                    <div className="col-md-8">
                        <div className="form-group">
                            <label htmlFor="inputdomain">{t("email.domainfield_label")}</label>
                            <input id="inputdomain" name="inputdomain" value={inputdomain} onChange={this.handleInputChange} type="text" className={isDomainInvalid ? "form-control  is-invalid" : "form-control"} />
                            <small id="emailHelp" className="form-text text-muted">{t("email.domainfield_info")}</small>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <button className="btn btn-outline-secondary" style={{marginTop: 30}} type="button" onClick={this.connectDomain}>{t("email.connect_button")}</button>
                    </div>
                </div>
                }

                <hr/>

                {senderdomains &&
                <>
                    {senderdomains.map((senderdomain, index) => {
                        return (
                            <div key={"senderdomain-" + senderdomain.name}>
                                <div className="row mb-2">
                                    <div className="col-md-8">
                                        <div className="d-flex justify-content-start align-items-center">
                                            {(senderdomain.valid)
                                            ? <><span className="mr-2 bg-success dot-success"></span> <span>{senderdomain.name} / {t("email.dnsvalid")}</span></>
                                            : <><span className="mr-2 bg-warning dot-success"></span> <span>{senderdomain.name} </span> {senderdomain.loading && <i className="ml-2 fa-solid fa-spinner fa-pulse"></i>}</>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-4 text-right">
                                        {!senderdomain.valid &&
                                            <button className="btn btn-default btn-sm mr-3" style={{marginTop: 0}} type="button" onClick={() => this.checkDomain(senderdomain.name)}>{t("email.check_button")}</button>
                                        }
                                        <button className="btn btn-default btn-sm" style={{marginTop: 0}} type="button" onClick={() => this.removeDomain(senderdomain.name)}>{t("email.remove_button")}</button>
                                    </div>
                                </div>

                                {(senderdomain.dnsinfo) &&
                                <div className="row">
                                    <div className="col-md-12 pl-4">
                                        <p>{t("email.dnsinfo")}</p>
                                        <div className="additionaldomains">
                                            <div className="row mb-1 ">
                                                <div className="col-md-1">{t("email.dnshead_type")}</div>
                                                <div className="col-md-4">{t("email.dnshead_hostname")}</div>
                                                <div className="col-md-7">{t("email.dnshead_value")}</div>
                                            </div>
                                            <hr />
                                            {senderdomain.dnsinfo.map((dnsitem, index) => {
                                                return (
                                                    <>
                                                    {(dnsitem.record_type=="TXT") &&
                                                        <>
                                                        <div key={"dnsitem" + index} className="row mb-2">
                                                            <div className="col-md-1">
                                                                <div className="d-flex justify-content-start align-items-center">
                                                                    {dnsitem.valid=="valid"
                                                                    ? <span className="mr-2 bg-success dot-success"></span>
                                                                    : <span className="mr-2 bg-warning dot-success"></span>
                                                                    }
                                                                    <span>TXT</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                {dnsitem.name}
                                                            </div>
                                                            <div className="col-md-7">
                                                                {dnsitem.value}
                                                            </div>
                                                        </div>
                                                        </>
                                                    }
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                }

                                <hr/>

                            </div>
                        )
                    })}
                </>
                }
                
            </div>
        );
    }
}

export default SendingDomain;