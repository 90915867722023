import React from 'react';
import withAuthorization from '../withAuthorization';
import { API } from '../../config/config';
import { auth } from '../../firebase/firebase';
import { checkDefaultProfit, getPlanPrice, validatePublish, formatDomains, checkNum, getExistingDomains } from '../../helpers/Helper';
import { withTranslation } from 'react-i18next';
import BillingForm from './BillingForm';
import Loader from '../../includes/Loader';
import PublishResult from './PublishResult';
import SubHeader from '../../includes/SubHeader';
import AuthService from '../Authentication/Auth';
import Plans from './Plans';
import * as prices from '../../config/prices';
import Moment from 'react-moment';
import moment from 'moment';

const INITIAL_VALIDATORS = {
    billingname: { isInvalid: false, message: '' },
    billingcompany: { isInvalid: false, message: '' },
    billingemail: { isInvalid: false, message: '' },
    billingaddress: { isInvalid: false, message: '' },
    city: { isInvalid: false, message: '' },
    zip: { isInvalid: false, message: '' },
    einvoice_address: { isInvalid: false, message: '' },
    einvoice_operator: { isInvalid: false, message: '' },
    newdomain: { isInvalid: false, message: '' },
    existingdomain: { isInvalid: false, message: '' },
    customdomains: { isInvalid: false, message: '' },
    isValid: true
}

const INITIAL_SITE = {
    billing: 'bill',
    billingname: '',
    billingcompany: '',
    billingemail: '',
    billingaddress: '',
    billingaddress2: '',
    city: '',
    zip: '',
    einvoice_address: '',
    einvoice_operator: '',
    sendingmethod: 'mail',
    domains: '',
    maindomain: '',
    nocommission: true
}

class Publish extends React.Component {

    constructor() {
        super();
        this.missingbilling = true;
        this.validations = { ...INITIAL_VALIDATORS };
        this.submitted = false;
        this.state = {
            site: { ...INITIAL_SITE },
            existingsites: null,
            existingsite: null,
            customdomains: [],
            publishtype: 'demo',
            newdomain: '',
            existingdomain: '',
            published: false,
            planprice: 0,
            totalprice: 0,
            error: null,
            formerror: null,
            loading: true
        }
    }

    onPublish = (e) => {
        e.preventDefault();

        this.setState({ formerror: null });
        this.submitted = true;

        const { id, domains } = this.state.site;
        const { customdomains, publishtype, existingsite, newdomain, existingdomain, site } = this.state;
        const validForm = this.validateForm();
        let formerror = {};

        if (validForm) {
            let domainstr = "";
            let wanteddomain = "";
            if (publishtype === 'demo') {
                wanteddomain = site.maindomain;
                domainstr = site.maindomain
            } else if (publishtype === 'new') {
                wanteddomain = newdomain;
                domainstr = newdomain
            } else if (publishtype === 'own') {
                wanteddomain = existingdomain
                domainstr = existingdomain;
            }

            auth.currentUser.getIdToken().then(idtoken => {
                const userid = this.props.authUser.bonsaituser.id;
                const formdata = {
                    domains: domainstr,
                    publishtype: publishtype,
                    existingsite: existingsite,
                    newdomain: wanteddomain,
                    site: site,
                    userid: userid,
                    idtoken: idtoken
                }

                fetch(API + "/publish?id=" + id, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'POST',
                    body: JSON.stringify(formdata),
                })
                .then(results => results.json())
                .then(result => {
                    if (result.success) {
                        this.setState({ published: true });
                    } else {
                        if (result.message === 'Domain already taken') {
                            formerror.message = this.props.t("domain_already_taken");
                            formerror.existingdomains = result.existingdomains;
                            this.setState({ formerror });
                        } else {
                            this.handleErrors(result);
                        }
                    }
                })
                .catch(error => (error));
            });

        } else {
            const { t } = this.props;
            formerror.message = t("error.validate")
            this.setState({ formerror });
        }
    }

    handlePublishMethodChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        // if existing site load site if needed
        if (value!="existing") {
            this.setState({
                existingsite: null
            });
        }

        this.setState({
            [name]: value,
            customdomains: [],
            formerror: null
        });
    }

    handleExistingsiteChange = (event) => {
        const value = event.target.value;
        this.setState({
            existingsite: value
        });

        // update billing information
        let sitefrom = null;
        if (this.state.existingsites) {
            this.state.existingsites.forEach(item => {
                if (item.id===value) {
                    sitefrom = item;
                }
            });
        }

        if (sitefrom) {
            let copy = { ...this.state.site };

            

            copy.billing = sitefrom.billing;
            if (sitefrom.sendingmethod) {
                copy.sendingmethod = sitefrom.sendingmethod;
            } else {
                copy.sendingmethod = "mail";
            }
            
            copy.billingperiod = sitefrom.billingperiod;
            copy.billingname = sitefrom.billingname;
            copy.billingcompany = sitefrom.billingcompany;
            copy.billingemail = sitefrom.billingemail;
            copy.billingaddress = sitefrom.billingaddress;
            copy.billingaddress2 = sitefrom.billingaddress2;
            copy.city = sitefrom.city;
            copy.zip = sitefrom.zip;
            copy.einvoice_address = sitefrom.einvoice_address;
            copy.einvoice_operator = sitefrom.einvoice_operator;
            copy.monthlyprice = sitefrom.monthlyprice;
            copy.billingstarts = sitefrom.billingstarts;
            copy.contractmonth = sitefrom.contractmonth;
            copy.billingmonth = sitefrom.billingmonth;
            copy.reference = sitefrom.reference;

            //copy.domains = sitefrom.domains;
            //copy.maindomain = sitefrom.maindomain;
            //copy.additionaldomains = sitefrom.additionaldomains;
    
            this.setState({
                site: copy,
                formerror: null
            });
    
        }
        
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'billing' || name === 'sendingmethod') {
            if (this.state.billing !== value || this.state.sendingmethod !== value) {
                this.resetValidators();
            }
        }

        if (name !== 'newdomain' && name !== 'existingdomain') {
            let copy = { ...this.state.site };
            copy[name] = value;

            this.setState({
                site: copy,
                formerror: null
            }, () => {
                if (this.submitted) {
                    this.validateForm();
                }
                if (name === 'monthlyprice') {
                    this.handleMonthlyPrice(copy.account);
                }
                // if (name === 'nocommission') {
                //     copy["monthlyprice"] = this.checkDefaultProfit(copy.account);
                //     this.setState({ site: copy });
                //     this.handleMonthlyPrice(copy.account);
                // }
            });
        } else {
            this.setState({
                [name]: value,
                formerror: null
            }, () => {
                if (this.submitted) {
                    this.validateForm();
                }
            });
        }
    }

    handleDomains = (domains) => {
        let trimmeddomains = [];

        domains.forEach(element => {
            const trimmed = element.replace(/\s/g, '');
            trimmeddomains.push(trimmed);
        });

        this.setState({
            customdomains: trimmeddomains,
            newdomain: '',
            formerror: null
        }, () => {
            if (this.submitted) {
                this.validateForm();
            }
        });
    }

    handlePlan = (plan) => {
        let copy = { ...this.state.site };
        copy["account"] = plan;
        //copy["monthlyprice"] = this.checkDefaultProfit(plan);
        this.setState({ site: copy }, () => {
            this.handleMonthlyPrice(plan);
        });
    }

    handleMonthlyPrice = (plan) => {
        const authUser = AuthService.getuser();
        const role = authUser.bonsaituser.role;
        const { monthlyprice } = this.state.site;

        let price = getPlanPrice(role, plan, prices);
        let total = 0;

        total = price + checkNum(monthlyprice);
        this.setState({
            planprice: price,
            totalprice: total
        });
    }

    handleAnyPriceInputChange = (event) => {
		const target = event.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		let copy = {...this.state.site};
		//value = checkNum(value);
		copy[name] = value;
		this.setState({ site: copy }, () => {
		});
	}

    checkDefaultProfit = (plan) => {
        return checkDefaultProfit(plan, prices);
    }

    handleErrors = (result) => {
        const { t } = this.props;
        let error = {};

        switch (result.message) {
            case 'Already published':
                error.message = t("already_published")
                break;
            case 'Unauthorized publisher':
                error.message = t("unauthorized_publishing")
                break;
            case 'Missing billing information"':
                error.message = t("missing_billing")
                break;
            default:
                error.message = t("error.unknown")
        }

        this.setState({ loading: false, error });
    }

    getSite = (siteid) => {
        const { id } = this.props.authUser.bonsaituser;

        auth.currentUser.getIdToken().then(idtoken => {
            const json = { idtoken: idtoken }
            fetch(API + `/getsiteforpublish?siteid=${siteid}&userid=${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(json)
            })
                .then(results => results.json())
                .then(result => {
                    if (result.id) {
                        this.missingbilling = result.missingbilling
                        let defaultsite = { ...this.state.site };
                        defaultsite["billingemail"] = AuthService.userdata.email || '';
                        defaultsite["billingname"] = AuthService.userdata.displayName || '';
                        defaultsite["theme"] = AuthService.userdata.bonsaituser.themes[0].code || '';
                        
                        const siteobject = Object.assign({ ...defaultsite }, result);
                        this.setState({ site: siteobject, loading: false });
                        this.handleMonthlyPrice(result.monthlyprice);
                    } else {
                        this.handleErrors(result);
                    }
                })
                .catch(error => (error));
        });
    }

    validateForm = () => {
        const { publishtype, customdomains, newdomain, existingdomain, site } = this.state;
        const { t } = this.props;
        let validators = { ...INITIAL_VALIDATORS }
        const validations = validatePublish(validators, site, publishtype, customdomains, newdomain, existingdomain, t);
        this.validations = validations;
        this.setState({ validated: true });
        return validations.isValid;
    }

    fetchExistingSites = () => {

        auth.currentUser.getIdToken().then(idtoken => {
            const json = { idtoken: idtoken }
            fetch(API + "/sites?sortby=name&reverse=true&limit=1000", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(json)
            })
            .then(results => results.json())
            .then(result => {
                this.state.existingsites = result.sites;
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
        });
    }

    resetValidators = () => {
        this.validations = { ...INITIAL_VALIDATORS }
        this.setState({ formerror: null });
    }

    componentDidMount() {
        const { siteid } = this.props.match.params;
        this.getSite(siteid);
        this.fetchExistingSites();
    }

    render() {

        const authUser = AuthService.getuser();
        const { customdomains, publishtype, newdomain, existingdomain, existingsites, existingsite, error, formerror, site, loading, published, planprice, totalprice } = this.state;

        const { t } = this.props;
        const inputprops = {
            placeholder: t("add_domains")
        }

        if (loading) {
            return (
                <div className="text-center mt-4">
                    <Loader size="3x" classes="text-primary" />
                </div>
            );
        }

        if (error) {
            return (
                <div className="text-center mt-4">
                    <i className="fa-solid fa-exclamation-circle fa-4x mb-4 text-danger"></i>
                    <h4>{error.message}</h4>
                </div>
            );
        }

        if (published) {
            return <PublishResult domains={site.maindomain} t={t} publishtype={publishtype} customdomains={customdomains} />
        }

        return (
            <div>
                <SubHeader sitename={site.title} domain={site.domains.split(",")[0]} publishsite={true} />
                <div className="container mt-4">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={this.onPublish} noValidate>
                                <div className="form-group">
                                    <h4>{t("choose_publishmethod")}</h4>
                                    <div className="custom-control custom-radio">
                                        <input type="radio" id="demo" name="publishtype" value="demo" className="custom-control-input" onChange={this.handlePublishMethodChange} checked={publishtype === 'demo'} />
                                        <label className="custom-control-label" htmlFor="demo">{t("publish_with_demo_address")} <span style={{ fontWeight: '600' }}>{site.domains.split(',')[0]}</span></label>
                                    </div>
                                    <div className="custom-control custom-radio">
                                        <input type="radio" id="new" name="publishtype" value="new" className="custom-control-input" onChange={this.handlePublishMethodChange} checked={publishtype === 'new'} />
                                        <label className="custom-control-label" htmlFor="new">{t("publish_with_new_address")}</label>
                                    </div>
                                    <div className="custom-control custom-radio">
                                        <input type="radio" id="own" name="publishtype" value="own" className="custom-control-input" onChange={this.handlePublishMethodChange} checked={publishtype === 'own'} />
                                        <label className="custom-control-label" htmlFor="own">{t("publish_with_custom_domain")}</label>
                                    </div>
                                    <div className="custom-control custom-radio">
                                        <input type="radio" id="existing" name="publishtype" value="existing" className="custom-control-input" onChange={this.handlePublishMethodChange} checked={publishtype === 'existing'} />
                                        <label className="custom-control-label" htmlFor="existing">{t("publish_with_existing_site")}</label>
                                    </div>
                                </div>

                                {publishtype === 'new' &&
                                    <div>
                                        <div className="form-group">
                                            <input type="text" className={this.validations.newdomain.isInvalid ? "form-control is-invalid" : "form-control"} placeholder={t("wanted_domain")} name="newdomain" onChange={this.handleInputChange} value={newdomain} />
                                            <small className="form-text text-muted" style={{ display: 'block' }}>{t("new_domain_info")}</small>
                                        </div>
                                    </div>
                                }

                                {publishtype === 'own' &&
                                    <div>
                                        <div className="form-group">
                                            <div className="form-group">
                                                <input type="text" className={this.validations.existingdomain.isInvalid ? "form-control is-invalid" : "form-control"} placeholder={t("existing_domain")} name="existingdomain" onChange={this.handleInputChange} value={existingdomain} />
                                                <small className="form-text text-muted" style={{ display: 'block' }}>{t("own_domain_info")}</small>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {publishtype === 'existing' &&
                                    <div>
                                        <div className="form-group">
                                            <div className="form-group">
                                                <label htmlFor="domain">{t("existing_site")}</label>
                                                <select id="admin-form-contractmonth" className="custom-select" name="existingsite" value={existingsite} onChange={this.handleExistingsiteChange}>
                                                    <option value="" selected>{t("existing_site_nosite")}</option>
                                                    {existingsites &&
                                                        existingsites.map((item, index) => {
                                                            if (item.published) {
                                                                let publishedStr = "";
                                                                if (item.publishedAt) {
                                                                    publishedStr = ", " + t("published").charAt(0).toLowerCase() + t("published").slice(1) + " " + moment(item.publishedAt.$date).format("DD.MM.YYYY")
                                                                }
                                                                let itemstr = item.title.charAt(0).toUpperCase() + item.title.slice(1) + publishedStr;
                                                                return (
                                                                    <option value={item.id}>{itemstr}</option>
                                                                );
                                                            }
                                                        })
                                                    }
                                                </select>
                                                <small className="form-text text-muted" style={{ display: 'block' }}>{t("existing_site_info")}</small>
                                            </div>
                                        </div>
                                        {(existingsite && existingsites) &&
                                            existingsites.map((item, index) => {
                                                if (existingsite==item.id) {
                                                    let additionaldomainsstr = "";
                                                    let domainindex = 1;
                                                    if (item.additionaldomains) {
                                                        item.additionaldomains.forEach(additionaldomain => {
                                                            additionaldomainsstr = additionaldomainsstr + additionaldomain.domain
                                                            if (domainindex<item.additionaldomains.length) {
                                                                additionaldomainsstr = additionaldomainsstr + ", ";
                                                            }
                                                            domainindex++;
                                                            
                                                        });
                                                    }
                                                    return (
                                                        <p>{t('existing_site_maindomain')} <strong>{item.maindomain}</strong><br/>
                                                        {t('existing_site_additionaldomains')} {additionaldomainsstr}<br/>
                                                        {t('existing_site_doublesitedomains')} {"old." + item.name + ".bonsait.fi"} 
                                                        </p>
                                                    );
                                                }
                                            })
                                        }
                                    </div>
                                }

                                {site.missingbilling &&
                                    <div>
                                        <h4>{t("plan.title")}</h4>
                                        <Plans handlePlan={this.handlePlan} account={site.account} authUser={authUser} t={t} prices={prices} />
                                        <BillingForm
                                            t={t}
                                            site={site}
                                            handleInputChange={this.handleInputChange}
                                            handleAnyPriceInputChange={this.handleAnyPriceInputChange}
                                            error={error}
                                            authUser={this.props.authUser}
                                            validations={this.validations}
                                            planprice={planprice}
                                            totalprice={totalprice}
                                            prices={prices}
                                        />
                                    </div>
                                }

                                {formerror &&
                                    <div className="alert alert-danger mt-3">
                                        {formerror.message + " "}
                                        {getExistingDomains(formerror.existingdomains)}
                                    </div>
                                }

                            </form>
                        </div>
                        <div className="card-footer bg-white">
							<button className="btn btn-secondary btn-lg mr-2" onClick={this.onPublish}>{t("publish")}</button>
							<small className="text-muted">{t("publish_warning")} <strong>{t("cannot")}</strong> {t("cancel_publishing")}</small>
                            <button className="btn btn-link float-right" onClick={() => this.props.history.goBack(-1)}>{t("cancel")}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAuthorization(withTranslation("Sites")(Publish));