import React from 'react';
import { Redirect, withRouter } from 'react-router-dom'
import * as routes from '../../config/routes';
import { withTranslation } from "react-i18next";
import AuthService from './Auth';
import SignUpForm from './SignUpForm';
import { validateSignUp } from '../../helpers/Helper';


const SignUpPage = (props) => {
    return (
        <div className="container mt-10">
            <h1 className="text-center mb-5">{props.t("signup")}</h1>   
            <SignUp {...props} />
        </div>
    );
}

const INITIAL_VALIDATORS = {
    fullname: { isInvalid: false, message: '' },
    email: { isInvalid: false, message: '' },
    pwd: { isInvalid: false, message: '' },
    pwd2: { isInvalid: false, message: '' },
    termsofservice: { isInvalid: false, message: '' },
    isValid: true
}

const INITIAL_STATE = {
    fullname: '',
    email: '',
    pwd: '',
    pwd2: '',
    termsofservice: false,
    error: null,
    termserror: false,
    loading: false,
};

class SignUp extends React.Component {
    constructor() {
        super();
        this.submitted = false;
        this.validations = { ...INITIAL_VALIDATORS }
        this.state = { ...INITIAL_STATE };
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value }, () => {
            if (this.submitted) {
                this.validateForm();
            }
        });
    }

    handleErrors = (result) => {
        const { t } = this.props;
        let error = {};

        switch (result.message) {
            case 'User already exists':
                error.message = t("error_alreadyexists")
                break;
            default:
                error.message = t("error_unkown")
        }

        this.setState({ error, loading: false })
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.submitted = true;
        this.setState({ termserror: false, error: null });
        
        const isValid = this.validateForm();

        if (isValid) {
            this.setState({ loading: true });

            const { fullname, email, pwd } = this.state;
            const formdata = {
                name: fullname,
                email: email,
                passwd: pwd
            }

            AuthService.register(formdata).catch(error => { 
                this.handleErrors(error);
            });
        } else {
            this.forceUpdate();
        }

    }

    handleGoogle = () => {
        this.setState({ termserror: false, error: null });
        if (this.state.termsofservice) {
            this.setState({ ...INITIAL_STATE, loading: true });
            AuthService.authenticateGoogle().catch(error => { 
                this.setState({ error, loading: false })
            });
        } else {
            this.setState({ termserror: true });
        }
    }

    handleFacebook = () => {
        this.setState({ termserror: false, error: null });
        if (this.state.termsofservice) {
            this.setState({ ...INITIAL_STATE, loading: true });
            AuthService.authenticateFacebook().catch(error => { 
                this.setState({ error, loading: false })
            });
        } else {
            this.setState({ termserror: true });
        }
    }

    validateForm = () => {
        const { t } = this.props;
        const initialValidations = { ...INITIAL_VALIDATORS }
        const validations = validateSignUp(initialValidations, this.state, t)
        this.validations = validations;
        this.setState({ validated: true });
        return validations.isValid;
    }

    componentDidMount() {
        const qs = this.props.location.search;
        if (qs !== "") {
            const token = qs.split("=");
            if (token[0] === '?token') {
                AuthService.invitetoken = token[1];
            }
        }
    }
    
    render() {
        const { t } = this.props;
        const { fullname, email, pwd, pwd2, error, termserror, termsofservice, loading } = this.state;

        if (AuthService.isAuthenticated) {
            return <Redirect to={routes.WEBSITES} />
        }

        return (
            <SignUpForm
                handleFacebook={this.handleFacebook}
                handleGoogle={this.handleGoogle}
                handleSubmit={this.handleSubmit}
                handleInputChange={this.handleInputChange}
                validations={this.validations}
                loading={loading}
                error={error}
                termserror={termserror}
                fullname={fullname}
                termsofservice={termsofservice}
                pwd={pwd}
                pwd2={pwd2}
                email={email}
                t={t} 
            />
        );
    }
}

export default withTranslation("Authentication")(withRouter(SignUpPage));
