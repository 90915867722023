import React from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import * as routes from '../../config/routes';
import moment from 'moment';

const checkTrialExpiration = expiredate => {
    const now = moment();
    const then = moment(expiredate, 'DD.MM.YYYY');
    return moment(now).isAfter(then);
}

const planstyle = {
    float: 'right',
    color: '#fff',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    margin: '-5px -5px 0 0',
    fontSize: '20px',
    fontWeight: '600',
    textAlign: 'center',
    textShadow: '1px 1px 0px rgba(0,0,0,0.3)',
    border: '3px solid #fff',
    boxShadow: '0px 0px 4px rgba(0,0,0,0.16)',
    paddingTop: '2px',
}

const WebsiteList = props => {
    const websites  = props.data.sites;
    const sitestoaccess = props.data.sitestoaccess;
    const { t, userdata, renderSingleSite, handleImageError, viewtype } = props;

    if (!websites) return null;

	if (viewtype=='list') {

		return (
			<React.Fragment>
				<div className="row">
					<div className="col-md-12 mb-3">
						<h3>{t("mysites")}</h3>
						{ !websites.length &&
							<div>
								<p>{t("no_websites")}</p>
								<Link to={routes.NEW_WEBSITE} className="btn btn-primary mb-5">
									<i className="fa-solid fa-plus"></i> {t("new_website")}
								</Link>
							</div>
						}
					</div>
				</div>
				{ userdata &&
					websites.map((item, index) => {
						let domain = item.domains ? 'http://' + item.domains.split(',')[0] : '';
						domain = 'http://' + item.maindomain;

						return (
							<div className="row card-row slideanim slide mb-4" key={index}>
								<div className="col-md-2">
									{ item.trialexpires &&
										<div className={checkTrialExpiration(item.trialexpires) ? 'ribbon bg-danger' : 'ribbon'}>{t("sites.trial")}</div>
									}
									{ <img src={'https://www.bonsait.fi/apps/pageimg.groovy?site=' + item.id} alt={item.title} onError={handleImageError} className="img-fluid d-block" />}
								</div>
								<div className="col-md-4">
									<h5 className="card-title">{item.title}</h5>

									<p className="card-text" style={{fontSize: "0.75rem"}}>
										{ item.created &&
											<span>
												{t("created") + " "} 
												<Moment format="DD.MM.YYYY">{item.created.$date}</Moment>,
											</span>
										}
										{ item.modified &&
											<span>
												{" " + t("edited") + " "}
												<Moment fromNow>{item.modified.$date}</Moment>
											</span>
										}    
									</p>
								</div>
								<div className="col-md-1">
									{ !item.published &&
										<span class="badge badge-primary ml-4 float-right">{t("sites.publishedribbon")}</span>
									}
								</div>
								<div className="col-md-1">
									<span className="bg-primary" style={planstyle}>{item.account}</span>
								</div>
								<div className="col-md-2 text-center">
									<a href={domain} target="_blank" rel="noopener noreferrer" className="btn btn-default btn-sm mt-1">{t("preview")}</a>
								</div>
								<div className="col-md-2 text-right">
									<Link to={routes.WEBSITE_HOME + `/${item.id}`} className="btn btn-secondary btn-sm mr-2 mt-1">{t("settings")}</Link>
								</div>
							</div>
						);
					})
				}
				{ userdata && sitestoaccess.length > 0 &&
					<React.Fragment>
						<div className="row">
							<div className="col-md-12 mb-3">
								<h3>{t("sites_access_to")}</h3>
								{ !websites.length &&
									<div>
										<p>{t("no_websites")}</p>
										<Link to={routes.NEW_WEBSITE} className="btn btn-primary mb-5">
											<i className="fa-solid fa-plus"></i> {t("new_website")}
										</Link>
									</div>
								}
							</div>
						</div>
						{ sitestoaccess.map((item, index) => {
							let domain = item.domains ? 'http://' + item.domains.split(',')[0] : '';
							let loginurl = item.domains ? 'http://' + item.domains.split(',')[0] + '/login' : '';

							domain = 'http://' + item.maindomain;
							loginurl = 'http://' + item.maindomain + '/login'

							// ? <button onClick={() => renderSingleSite(false, item.id)} className="btn btn-primary btn-sm mr-2 mt-1">{t("settings")}</button>

							return (
								<div className="row card-row slideanim slide mb-4" key={index}>
									<div className="col-md-2">
										{ item.trialexpires &&
											<div className={checkTrialExpiration(item.trialexpires) ? 'ribbon bg-danger' : 'ribbon'}>{t("sites.trial")}</div>
										}
										{ <img src={'https://www.bonsait.fi/apps/pageimg.groovy?site=' + item.id} alt={item.title} onError={handleImageError} className="img-fluid d-block" />}
									</div>
									<div className="col-md-4">
										<h5 className="card-title">{item.title}</h5>

										<p className="card-text" style={{fontSize: "0.75rem"}}>
											{ item.created &&
												<span>
													{t("created") + " "} 
													<Moment format="DD.MM.YYYY">{item.created.$date}</Moment>,
												</span>
											}
											{ item.modified &&
												<span>
													{" " + t("edited") + " "}
													<Moment fromNow>{item.modified.$date}</Moment>
												</span>
											}    
										</p>
									</div>
									<div className="col-md-1">
										{ !item.published &&
											<span class="badge badge-primary ml-4 float-right">{t("sites.publishedribbon")}</span>
										}
									</div>
									<div className="col-md-1">
										<span className="bg-primary" style={planstyle}>{item.account}</span>
									</div>
									<div className="col-md-2 text-center">
										<a href={domain} target="_blank" rel="noopener noreferrer" className="btn btn-default btn-sm mt-1">{t("preview")}</a>
									</div>
									<div className="col-md-2 text-right">
										{ item.allowmodify !== false
											? <Link to={routes.WEBSITE_HOME + `/${item.id}`} className="btn btn-secondary btn-sm mr-2 mt-1">{t("settings")}</Link>
											: <a href={loginurl} target="_blank" rel="noopener noreferrer" className="btn btn-secondary btn-sm mr-2 mt-1">{t("edit")}</a>
										}
									</div>
								</div>
							);
							
							})
						}
					</React.Fragment>
				}
			</React.Fragment>
		);



	} else {

		return (
			<React.Fragment>
				<div className="row">
					<div className="col-md-12 mb-3">
						<h3>{t("mysites")}</h3>
						{ !websites.length &&
							<div>
								<p>{t("no_websites")}</p>
								<Link to={routes.NEW_WEBSITE} className="btn btn-primary mb-5">
									<i className="fa-solid fa-plus"></i> {t("new_website")}
								</Link>
							</div>
						}
					</div>
					{ userdata &&
						websites.map((item, index) => {
							let domain = item.domains ? 'http://' + item.domains.split(',')[0] : '';
							domain = 'http://' + item.maindomain;
	
							return (
								<div className="col-lg-4 col-md-6" key={index}>
									<div className="card slideanim slide mb-4">
										{ item.trialexpires &&
											<div className={checkTrialExpiration(item.trialexpires) ? 'ribbon2 bg-danger' : 'ribbon2'}>{t("sites.trial")}</div>
										}
										{ !item.published &&
											<div className="ribbon"><span>{t("sites.publishedribbon")}</span></div>
										}
										{ item.isdouble &&
											<div className="ribbon blue"><span>{t("sites.isdoubleribbon")}</span></div>
										}
										<div className="card-body">
											<span className="bg-primary mr-3" style={planstyle}>{item.account}</span>
											<h5 className="card-title">{item.title}</h5>
											<p className="card-text" style={{fontSize: "0.75rem"}}>
											{ item.created &&
												<span>
													{t("created") + " "} 
													<Moment format="DD.MM.YYYY">{item.created.$date}</Moment>,
												</span>
											}
											{ item.modified &&
												<span>
													{" " + t("edited") + " "}
													<Moment fromNow>{item.modified.$date}</Moment>
												</span>
											}    
											</p>
										</div>
										{ <img src={'https://www.bonsait.fi/apps/pageimg.groovy?site=' + item.id} alt={item.title} onError={handleImageError} className="img-fluid d-block plan-card-image" /> }
										<div className="card-body">
											<div className="row">
												<div className="col-md-6">
													<Link to={routes.WEBSITE_HOME + `/${item.id}`} className="btn btn-secondary btn-sm mr-2 mt-1">{t("settings")}</Link>
												</div>
												<div className="col-md-6 text-right">
													 <a href={domain} target="_blank" rel="noopener noreferrer" className="btn btn-default btn-sm mt-1">{t("preview")}</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							);
						})
					}
				</div>
				{ userdata && sitestoaccess.length > 0 &&
				<div className="row">
					<div className="col-md-12">
						<h3>{t("sites_access_to")}</h3>
					</div>
					{ sitestoaccess.map((item, index) => {
						let domain = item.domains ? 'http://' + item.domains.split(',')[0] : '';
						let loginurl = item.domains ? 'http://' + item.domains.split(',')[0] + '/login' : '';

						domain = 'http://' + item.maindomain;
						loginurl = 'http://' + item.maindomain + '/login'
	
						// ? <button onClick={() => renderSingleSite(false, item.id)} className="btn btn-primary btn-sm mr-2 mt-1">{t("settings")}</button>
						
						return (
							<div className="col-lg-4 col-md-6" key={index}>
								<div className="card slideanim slide mb-4">
									<div className="card-body">
										<span className="bg-primary" style={planstyle}>{item.account}</span>
										<h5 className="card-title">{item.title}</h5>
										<p className="card-text" style={{fontSize: "0.75rem"}}>
										{ item.created &&
											<span>
												{t("created") + " "} 
												<Moment format="DD.MM.YYYY">{item.created.$date}</Moment>,
											</span>
										}
										{ item.modified &&
											<span>
												{" " + t("edited") + " "}
												<Moment fromNow>{item.modified.$date}</Moment>
											</span>
										}  
										</p>
									</div>
									<div className="card-body">
										{ <img src={'https://www.bonsait.fi/apps/pageimg.groovy?site=' + item.id} alt={item.title} onError={handleImageError} className="img-fluid d-block" style={{ marginBottom: '15px', border: '1px solid rgba(0, 0, 0, 0.125)' }} /> }
										{ item.allowmodify !== false
											? <Link to={routes.WEBSITE_HOME + `/${item.id}`} className="btn btn-primary btn-sm mr-2 mt-1">{t("settings")}</Link>
											: <a href={loginurl} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-sm mr-2 mt-1">{t("edit")}</a>
										}
										<a href={domain} target="_blank" rel="noopener noreferrer" className="btn btn-default btn-sm mt-1">{t("preview")}</a>
									</div>
								</div>
							</div>
						);
						})
					}
				</div>
				}
			</React.Fragment>
		);

	}
    
    
}

export default WebsiteList;