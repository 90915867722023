import React from 'react';
import { withTranslation } from 'react-i18next';
import withAuthorization from '../withAuthorization';
import { API } from '../../config/config';
import { auth } from '../../firebase/firebase';
import AuthService from '../Authentication/Auth';
import Site from './Site';
import Loader from '../../includes/Loader';
import EventBus from 'eventbusjs';


const INITIAL_SITE = {
    billing: 'bill',
    billingname: '',
    billingcompany: '',
    billingemail: '',
    billingaddress: '',
    billingaddress2: '',
    city: '',
    zip: '',
    einvoice_address: '',
    einvoice_operator: '',
    sendingmethod: 'mail',
    domains: '',
    nocommission: true,
    monthoffset: '0'
}

class Website extends React.Component {
	
    constructor(props) {
        super(props);
        this.state = {
            site: null,
            loading: true,
        }
    }

	getSite = (siteid) => {
		const isAuthenticated = AuthService.isAuthenticated;
    	const authUser = AuthService.getuser();
        const { id } = authUser.bonsaituser;

        auth.currentUser.getIdToken().then(idtoken => {
            const json = { idtoken: idtoken }
            fetch(API + `/getsite?siteid=${siteid}&userid=${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(json)
            })
                .then(results => results.json())
                .then(result => {
                    if (result.id) {
                        const siteobject = Object.assign({ ...INITIAL_SITE }, result);
                        this.setState({ site: siteobject, loading: false });
                    } else {
                        this.handleErrors(result);
                    }
                })
                .catch(error => (error));
        });
    }

	componentDidMount() {
        const { siteid } = this.props.match.params;
		//console.log(siteid)
        this.getSite(siteid);

		// open side menu
		EventBus.dispatch("sidemenu-changed", true);
    }

    render() {

        const { site, loading } = this.state;
        const { t } = this.props;

        const authUser = AuthService.getuser();

		if (loading) {
            return (
                <div className="text-center mt-4">
                    <Loader size="3x" classes="text-primary" />
                </div>
            );
        }
        
        if (site) {
            return <Site item={site} />
        }

    }
}

export default withAuthorization(withTranslation("Dashboard")(Website));
