import React from 'react';
import * as routes from '../../config/routes';
import { withTranslation } from "react-i18next";
import Loader  from '../../includes/Loader';
import { Link } from 'react-router-dom';

const SignInForm = (props) => {
    const { t } = props;

    const isDisabled = props.loading;
    
    return (
        <div className="container mt-10">
            <h1 className="text-center mb-5">{props.t("signin")}</h1>
            <div className="signin-container">
                <div className="sign-in-with-email">
                    <form onSubmit={props.handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="email">{props.t("email_or_username")}</label>
                            <input id="email" name="email" onChange={props.handleInputChange} type="text" className="form-control" required autoFocus/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">{t("password")}</label>
                            <input id="password" name="password" onChange={props.handleInputChange} type="password" className="form-control" required/>
                        </div>
                        <button className="btn btn-primary btn-block" disabled={isDisabled} type="submit">
                            { props.loading 
                                ? <Loader size="1x" classes="text-white" />
                                : <span>{t("signin")}</span>
                            }
                        </button>
                        { props.error && 
                            <div className="alert alert-danger mt-3">{props.error.message}</div> 
                        }
                    </form>
                    <div className="text-center mt-3">
                        <Link className="text-secondary-dark" to={routes.SIGN_UP} style={{fontSize: '0.9rem'}}>{t("no_account")}</Link>
                        <div>
                            <Link className="text-secondary-dark" to={routes.PASSWORD_FORGET} style={{fontSize: '0.9rem'}}>{t("forgot_pw")}</Link>
                        </div>
                    </div>
                </div>
                <div className="divider"></div>
                <p className="text-divider d-md-none"><span className="bg-light-gray">{t("or")}</span></p>
                <div className="sign-in-social">
                    <div className="row text-center mb-4">
                        <div className="col-md-12 col-sm-6">
                            <button className="btn btn-social btn-google btn-block mb-3" onClick={props.handleGoogle} disabled={isDisabled}>
                                <div className="social-icon-google"></div>
                                {t("connect_to_google")}
                            </button>
                        </div>
                        {/* 
                        <div className="col-md-12 col-sm-6">
                            <button className="btn btn-social btn-facebook btn-block" onClick={props.handleFacebook} disabled={isDisabled}>
                                <div className="d-flex align-items-center justify-content-center">
                                    <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                                </div>
                                {t("connect_to_fb")}
                            </button>
                        </div>
                        */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation("Authentication")(SignInForm);