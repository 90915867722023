import isEmail from 'validator/lib/isEmail';
import equals from 'validator/lib/equals';

export function checkNum(num) {
    if (typeof num === 'string' || num instanceof String) {
		num = num.replace(',', '.');
	}

	num = isNaN(num) || num === '' || num === null ? 0 : num;
    return parseInt(num, 10);
}

export function stringtoFilename(s) {
	var filename = s.replace(/[^a-z0-9]/gi, '').toLowerCase();
    //filename = filename.replace(/\s/g, '').toLowerCase();
    return filename ;
}

export function checkFilename(s) {
	if (/^[a-zA-z0123456789]+$/i.test(s)) {
		return true;
	} else {
		return false;
	}
}

export function formatDomains(domains) {
    let str = "";
    for (let i = 0; i < domains.length; i++) {
        str += domains[i];
        if (domains[i + 1] != null) {
            str += ",";
        }
    }
    return str;
}

export function getExistingDomains(existingdomains) {
    let domains = "";
    if (existingdomains) {
        existingdomains.map((d, i) => {
            domains += d
            if (existingdomains[i + 1]) {
                domains += ", ";
            }
        });
    }
    return domains;
}

export function checkDefaultProfit(plan, prices) {    
    let profit = 0;

    switch (plan) {
        case 'S':
            profit = prices.DEFAULT_PROFIT_S;
            break;
        case 'M':
            profit = prices.DEFAULT_PROFIT_M;
            break;
        case 'L':
            profit = prices.DEFAULT_PROFIT_L
            break;
        case 'XL':
            profit = prices.DEFAULT_PROFIT_XL
            break;
        default:
            profit = prices.DEFAULT_PROFIT_M;
    }

    return profit;
}

export function getPlanPrice(role, plan, prices) {
    let price = 49;

    switch (plan) {
        case 'S':
            price = role === 'user' ? prices.S : prices.MANAGER_S;
            break;
        case 'M':
            price = role === 'user' ? prices.M : prices.MANAGER_M;
            break;
        case 'L':
            price = role === 'user' ? prices.L : prices.MANAGER_L;
            break;
        case 'XL':
            price = role === 'user' ? prices.XL : prices.MANAGER_XL;
            break;
        default:
            price = role === 'user' ? prices.M : prices.MANAGER_M;
    }

    return price;
}

export function setErrors(result, t) {
    let error = {};
    switch (result.message) {
        case 'Invalid username or password':
            error.message = t("error_invalidcredentials")
            break;
        case 'User already exists':
            error.message = t("error_alreadyexists")
            break;
        case 'Invite link expired':
            error.message = t("invite.expired")
            break;
        case 'Authentication failed':
            error.message = t("invite.auth_error")
            break;
        case 'Invite not found':
            error.message = t("invite.notfound")
            break;
        case 'Already published':
            error.message = t("already_published")
            break;
        case 'Unauthorized publisher':
            error.message = t("unauthorized_publishing")
            break;
        case 'Missing billing information"':
            error.message = t("missing_billing")
            break;
        case 'Domain already taken':
            error.message = t("domain_already_taken");
            error.existingdomains = result.existingdomains;
        case 'Trial expired':
            error.message = t("trial.expired")
            break;
        case 'Demo expired':
            error.message = t("trial.expired")
            break;
        case 'Unauthorized':
            error.message = t("error_unauthorized")
            break;
        default:
            error.message = t("error_invalidcredentials")
    }

    // Quick fix for firebase errors...
    if (result.code) {
        switch (result.code) {
            case 'auth/invalid-email':
                error.message = t("validations.email")
                break;
            case 'auth/user-disabled':
                error.message = t("error_userdisabled")
                break;
            case 'auth/user-not-found':
                error.message = t("error_usernotfound")
                break;
            case 'auth/wrong-password':
                error.message = t("error_invalidcredentials")
                break;
            default:
                error.message = t("error_unkown")
        }
    }

    return error;
}

export function validatePublish(validations, site, publishtype, customdomains, newdomain, existingdomain, t) {
    const { einvoice_address, einvoice_operator, billingname, billingcompany, billingemail, billingaddress, city, zip, billing, sendingmethod  } = site;
    let fields = validations;

    if (billing && billing === 'bill') {

        if (!billingname) {
            fields.billingname = { isInvalid: true }
            fields.billingname.message = t("validations.requiredfield");
        }

        if (!billingcompany) {
            fields.billingcompany = { isInvalid: true }
            fields.billingcompany.message = t("validations.requiredfield");
        }
        
        if (!billingaddress) {
            fields.billingaddress = { isInvalid: true }
            fields.billingaddress.message = t("validations.requiredfield");
        } 
        if (!city) {
            fields.city = { isInvalid: true }
            fields.city.message = t("validations.requiredfield");
        }
        if (!zip) {
            fields.zip = { isInvalid: true }
            fields.zip.message = t("validations.requiredfield");
        }
        if (sendingmethod === 'online') {
            if (!einvoice_address) {
                fields.einvoice_address = { isInvalid: true }
                fields.einvoice_address.message = t("validations.requiredfield");
            }
            if (!einvoice_operator) {
                fields.einvoice_operator = { isInvalid: true }
                fields.einvoice_operator.message = t("validations.requiredfield");
            }
        } else if (sendingmethod === 'email') {
            if (!billingemail) {
                fields.billingemail = { isInvalid: true }
                fields.billingemail.message = t("validations.requiredfield");
            }
        }
    }

    if (publishtype === 'new') {
        if (newdomain.length < 2) {
            fields.newdomain = { isInvalid: true }
            fields.newdomain.message = t("validations.requiredfield");
        }
    } else if (publishtype === 'own') {
        if (existingdomain.length < 1) {
            fields.existingdomain = { isInvalid: true }
            fields.existingdomain.message = t("validations.requiredfield");
        }
    }

    fields.isValid = valid(fields);
    return fields;
}

function valid(fields) {
    for (let i in fields) {
        if (fields[i].isInvalid === true) {
            return false;
        }
    }
    return true;
}

export function validateBilling(validations, state, t) {
    const { einvoice_address, einvoice_operator, billingname, billingcompany, billingemail, billingaddress, city, zip, billing, sendingmethod } = state;
    let fields = validations;

    if (billing === 'bill') {
        if (!billingname) {
            fields.billingname = { isInvalid: true }
            fields.billingname.message = t("validations.requiredfield");
        }

        if (!billingcompany) {
            fields.billingcompany = { isInvalid: true }
            fields.billingcompany.message = t("validations.requiredfield");
        }

        if (!billingaddress) {
            fields.billingaddress = { isInvalid: true }
            fields.billingaddress.message = t("validations.requiredfield");
        } 
        if (!city) {
            fields.city = { isInvalid: true }
            fields.city.message = t("validations.requiredfield");
        }
        if (!zip) {
            fields.zip = { isInvalid: true }
            fields.zip.message = t("validations.requiredfield");
        }
        if (sendingmethod === 'online') {
            if (!einvoice_address) {
                fields.einvoice_address = { isInvalid: true }
                fields.einvoice_address.message = t("validations.requiredfield");
            }
            if (!einvoice_operator) {
                fields.einvoice_operator = { isInvalid: true }
                fields.einvoice_operator.message = t("validations.requiredfield");
            }
        } else if (sendingmethod === 'email') {
            if (!billingemail) {
                fields.billingemail = { isInvalid: true }
                fields.billingemail.message = t("validations.requiredfield");
            }
        }
    }

    fields.isValid = valid(fields);

    return fields;
}

export function validateSignUp(validations, state, t) {
    const { fullname, email, pwd, pwd2, termsofservice } = state;
    let fields = validations;

    if (!fullname && fullname.length < 2) {
        fields.fullname = { isInvalid: true }
        fields.fullname.message = t("validations.fullname");
    }

    if (!isEmail(email)) {
        fields.email = { isInvalid: true }
        fields.email.message = t("validations.email");
    }

    if (pwd === '') {
        fields.pwd = { isInvalid: true }
        fields.pwd.message = t("validations.requiredfield");
    }

    if (pwd2 === '') {
        fields.pwd2 = { isInvalid: true }
        fields.pwd2.message = t("validations.requiredfield");
    }

    if (pwd && pwd.length < 6) {
        fields.pwd = { isInvalid: true }
        fields.pwd.message = t("validations.minsixcharacters");
    }

    if (pwd2 && pwd2.length < 6) {
        fields.pwd2 = { isInvalid: true }
        fields.pwd2.message = t("validations.minsixcharacters");
    }

    if (!equals(pwd, pwd2)) {
        fields.pwd = { isInvalid: true }
        fields.pwd2 = { isInvalid: true }
        fields.pwd.message = t("validations.passwordmatch");
        fields.pwd2.message = t("validations.passwordmatch");
    }

    if (!termsofservice) {
        fields.termsofservice = { isInvalid: true }
        fields.termsofservice.message = t("validations.terms");
    }

    fields.isValid = valid(fields);
    return fields;
}

export function validateActivateAccount(validations, state, t) {
    const { email, pwd, pwd2, termsofservice } = state;
    let fields = validations;

    if (!isEmail(email)) {
        fields.email = { isInvalid: true }
        fields.email.message = t("validations.email");
    }

    if (pwd === '') {
        fields.pwd = { isInvalid: true }
        fields.pwd.message = t("validations.requiredfield");
    }

    if (pwd2 === '') {
        fields.pwd2 = { isInvalid: true }
        fields.pwd2.message = t("validations.requiredfield");
    }

    if (pwd && pwd.length < 6) {
        fields.pwd = { isInvalid: true }
        fields.pwd.message = t("validations.minsixcharacters");
    }

    if (pwd2 && pwd2.length < 6) {
        fields.pwd2 = { isInvalid: true }
        fields.pwd2.message = t("validations.minsixcharacters");
    }

    if (!equals(pwd, pwd2)) {
        fields.pwd = { isInvalid: true }
        fields.pwd2 = { isInvalid: true }
        fields.pwd.message = t("validations.passwordmatch");
        fields.pwd2.message = t("validations.passwordmatch");
    }

    if (!termsofservice) {
        fields.termsofservice = { isInvalid: true }
        fields.termsofservice.message = t("validations.terms");
    }

    fields.isValid = valid(fields);
    return fields;
}

export function validatePasswordChange(password, password2, validations, t) {
    let fields = validations;

    if (password === '') {
        fields.password = { isInvalid: true }
        fields.password.message = t("validations.requiredfield");
    }

    if (password2 === '') {
        fields.password2 = { isInvalid: true }
        fields.password2.message = t("validations.requiredfield");
    }

    if (password && password.length < 6) {
        fields.password = { isInvalid: true }
        fields.password.message = t("validations.minsixcharacters");
    }

    if (password2 && password2.length < 6) {
        fields.password2 = { isInvalid: true }
        fields.password2.message = t("validations.minsixcharacters");
    }

    if (!equals(password, password2)) {
        fields.password = { isInvalid: true }
        fields.password2 = { isInvalid: true }
        fields.password.message = t("validations.passwordmatch");
        fields.password2.message = t("validations.passwordmatch");
    }

    fields.isValid = valid(fields);
    return fields;
}

export function validateAccountPage(pwd, pwd2, name, validations, t) {
    let fields = validations;

    if (!name) {
        fields.name = { isInvalid: true }
        fields.name.message = t("validations.mintwocharacters");
    }

    if (name && name.length < 2) {
        fields.name = { isInvalid: true }
        fields.name.message = t("validations.mintwocharacters");
    }

    if ((pwd && pwd.length > 0) || (pwd2 && pwd2.length > 0)) {
        if (pwd === '') {
            fields.pwd = { isInvalid: true }
            fields.pwd.message = t("validations.requiredfield");
        }

        if (pwd2 === '') {
            fields.pwd2 = { isInvalid: true }
            fields.pwd2.message = t("validations.requiredfield");
        }

        if (pwd && pwd.length < 6) {
            fields.pwd = { isInvalid: true }
            fields.pwd.message = t("validations.minsixcharacters");
        }

        if (pwd2 && pwd2.length < 6) {
            fields.pwd2 = { isInvalid: true }
            fields.pwd2.message = t("validations.minsixcharacters");
        }

        if (!equals(pwd, pwd2)) {
            fields.pwd = { isInvalid: true }
            fields.pwd2 = { isInvalid: true }
            fields.pwd.message = t("validations.passwordmatch");
            fields.pwd2.message = t("validations.passwordmatch");
        }
    }

    fields.isValid = valid(fields);
    return fields;
}