import React from 'react';
import { withTranslation } from "react-i18next";
import withAuthorization from '../withAuthorization';
import { API } from '../../config/config';
import { auth } from '../../firebase/firebase';
import { stringtoFilename, checkFilename } from '../../helpers/Helper';
import Loader  from '../../includes/Loader';
import Models from '../Sites/Models';

const INITIAL_STATE = {
    title: '',
    name: '',
    published: false,
    domain: '',
    validdomain: '',
    account: 'M',
    model: 'blanko',
    theme: 'bonsait',
    billing: 'bill',
    billingname: '',
    billingcompany: '',
    billingemail: '',
    billingperiod: '6',
    billingaddress: '',
    billingaddress2: '',
    city: '',
    zip: '',
    einvoice_address: '',
    einvoice_operator: '',
    monthlyprice: '',
    nocommission: true,
    error: null,
    submitDisabled: false,
    loading: false
};

class StepTwo extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE }
    }

    onCreateSite = (e) => {
        e.preventDefault();

        const { t, renderResult } = this.props;
        let error = {};
        
        let formdata = this.state;

        if (checkFilename(formdata.name)) {
            delete formdata.error;
            delete formdata.submitDisabled;
            delete formdata.validdomain;
            delete formdata.loading;

            this.setState({ loading: true });
            auth.currentUser.getIdToken().then(idtoken => {
                formdata.trial = true;
                formdata.idtoken = idtoken
                fetch(API + "/sites", {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'PUT',
                    body: JSON.stringify(formdata),
                })
                .then(results => results.json())
                .then(sitecreated => {
                    if (sitecreated.success) {
                        renderResult(formdata.domain);             
                    } else {
                        error.type = "trialused";
                        error.message = t("trialused") + " ";
                        this.setState({ error, loading: false })
                        window.scrollTo(0, 0);
                    }
                });
            });
        } else {
            error.message = t("error.checkname")
            this.setState({ error });
        }
    }

    onCheckSite = () => {
        this.setState({ error: null });

        const { t, authUser } = this.props;
        const { name, title } = this.state;

        const filename = stringtoFilename(name);
        const error = { message: t("alreadyexists") + " " }

        if (filename !== "") {
            auth.currentUser.getIdToken().then(idtoken => {
                const json = { idtoken: idtoken }
                fetch(API + "/check?name=" + filename, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(json)
                })
                .then(results => results.json())
                .then(data => {
                    if (data.site) {
                        this.setState({ error, validdomain: '', domain: '' });
                        window.scrollTo(0, 0);
                    } else {
                        const trimmed = title.replace(/\s/g,'').toLowerCase();
                        const defaultdomain = authUser.bonsaituser ? authUser.bonsaituser.defaultdomain : '.bonsait.fi';
                        const domainstr = trimmed + defaultdomain;
                        this.setState({ 
                            domain: domainstr, 
                            validdomain: trimmed,
                        });
                    }
                })
                .catch(error => this.setState({error: error}));
            });
        }
    }

    handleModel = (model) => {
        this.setState({ model: model });
    }

    handleName = (event) => {
        const targetname = event.target.name;
        const value = event.target.value;
        const trimmedvalue = stringtoFilename(value);
        this.setState({ 
            [targetname]: value,
            name: trimmedvalue
        });
    }

    render() { 

        const { t, authUser } = this.props;
        const { title, name, domain, error, validdomain, model, loading } = this.state;

        const isInvalid = 
            model === '' |
            error != null |
            title.length < 3 |
            name.length < 3 |
            domain;
        
        if (loading) {
            return (
                <div className="text-center mt-3">
                    <h3 className="mb-5">{t("creating_your_site")}</h3>
                    <Loader size="3x" classes="text-primary"/>
                </div>
            );
        }

        const alertLinkStyle = {
            fontWeight: 700,
            color: '#561b24'
        }

        return ( 
            <div>
                <form onSubmit={this.onCreateSite}>
                    <div className="form-group">
                        <label htmlFor="title">{t("sitename")}</label>
                        <input id="site-form-title" className="form-control" type="text" name="title" onChange={this.handleName} onBlur={this.onCheckSite}/>
                    </div>

                    { error &&
                        <div className="alert alert-danger mt-3">
                            { error && error.message }
                            { error && error.type === 'trialused' && 
                                <a href={"https://www.bonsait.fi/yhteys"} style={alertLinkStyle}>www.bonsait.fi/yhteys</a> 
                            }
                        </div>
                    }

                    <label htmlFor="title">{t("domain")}</label>
                    <div className="input-group mb-3">
                        <div className="input-group-append">
                            <span className="input-group-text bg-white">{validdomain}{authUser.bonsaituser.defaultdomain}</span>
                        </div>
                    </div>

                    <Models handleModel={this.handleModel} model={model} t={t} />

                    { error &&
                        <div className="alert alert-danger mt-3 d-md-none">
                            { error.message }
                            { error && error.type === 'trialused' && 
                                <a href={"https://www.bonsait.fi/yhteys"} style={alertLinkStyle}>www.bonsait.fi/yhteys</a> 
                            }
                        </div>
                    }

                    <button className="btn btn-primary mr-2" type="submit" disabled={isInvalid}>{t("create")}</button>
                </form>
            </div>    
        );
    }
}
 
export default withAuthorization(withTranslation("Sites")(StepTwo));