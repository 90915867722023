import React from 'react';
import defPageImg from '../../images/default.jpg';
import emptyPageImg from '../../images/model-empty.png';
import blankoPageImg from '../../images/model-blanko.png';

const getPreviewURL = (domains) => domains ? "http://" + domains.split(",")[0] : '';


const Models = (props) => {
    const { t } = props;
    return (
        <div className="form-group">
            <label htmlFor="title">{t("model")}</label>
            <div className="row">
                { props.sitecopy &&
                    <div className="col-lg-3">
                        <div className={props.model === props.sitecopy.copyname ? "frame-browser selected" : "frame-browser"} onClick={() => props.handleModel(props.sitecopy.copyname)}>
                            <div className="frame">
                                <span className="red"></span> 
                                <span className="yellow"></span> 
                                <span className="green"></span>
                            </div>
                            <img src={"https://www.bonsait.fi/apps/pageimg.groovy?site=" + props.sitecopy.copyid} onError={(e) => e.target.src = defPageImg} className="img-fluid d-block" alt={props.sitecopy.copyname} />
                        </div>
                        <a href={getPreviewURL(props.sitecopy.copydomains)} rel="noopener noreferrer" className="btn btn-default btn-sm btn-block mt-2" target="_blank">{t("actions.preview")}</a>
                    </div>       
                }
                <div className="col-lg-3">
                    <div className={props.model === "empty" ? "frame-browser selected" : "frame-browser"} onClick={() => props.handleModel("empty")}>
                        <div className="frame">
                            <span className="red"></span> <span className="yellow"></span> <span className="green"></span>
                        </div>
                        <img src={emptyPageImg} alt="empty" className="img-fluid" />
                    </div>
                    <a href="http://empty.bonsait.fi" rel="noopener noreferrer" className="btn btn-default btn-sm btn-block mt-2" target="_blank">{t("actions.preview")}</a>
                </div>
                <div className="col-lg-3">
                    <div className={props.model === "blanko" ? "frame-browser selected" : "frame-browser"} onClick={() => props.handleModel("blanko")}>
                        <div className="frame">
                            <span className="red"></span> <span className="yellow"></span> <span className="green"></span>
                        </div>
                        <img src={blankoPageImg} alt="blanko" className="img-fluid" />
                    </div>
                    <a href="http://blanko.bonsait.fi" rel="noopener noreferrer" className="btn btn-default btn-sm btn-block mt-2" target="_blank">{t("actions.preview")}</a>
                </div>
            </div>
        </div>
    );
}

export default Models;