import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import Site from './Site';

const ConfirmModal = (props) => {
    return (
        <Modal size="md" isOpen={props.showconfirm} toggle={props.toggleConfirm}>
            <ModalBody className="mb-3">
                {props.t("confirmsite")} <strong>{props.sitemodel.title}?</strong>
            </ModalBody>
            <ModalFooter className="d-flex align-items-center justify-content-between">
                <button className="btn btn-danger mr-1" onClick={props.onDeleteSite}>{props.t("delete")}</button>
                <button className="btn btn-link" onClick={props.toggleConfirm}>{props.t("cancel")}</button>
            </ModalFooter>
        </Modal>
    );
}

export default ConfirmModal;