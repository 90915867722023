export const DOMAINS = [
	{tld: "fi", price: 60.0},
	{tld: "com", price: 60.0},
	{tld: "net", price: 60.0},
	{tld: "org", price: 60.0},
	{tld: "info", price: 60.0},
	{tld: "eu", price: 60.0},
	{tld: "io", price: 60.0},
	{tld: "online", price: 60.0},
	{tld: "site", price: 60.0},
	{tld: "store", price: 60.0},
	{tld: "se", price: 60.0},
	{tld: "me", price: 60.0},
	{tld: "name", price: 60.0},
	{tld: "pro", price: 60.0},
	{tld: "vip", price: 60.0},
	{tld: "cloud", price: 60.0},
	{tld: "art", price: 60.0}
]