import React from 'react';
import { withRouter, Redirect } from 'react-router-dom'
import * as routes from '../../config/routes';
import { withTranslation } from "react-i18next";
import AuthService from './Auth';
import SignInForm from './SignInForm';
import isEmail from 'validator/lib/isEmail';
import i18next from 'i18next';


const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
    loading: false
};

export class SignInPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    handleInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({ [name]: value });
    }

    handleErrors = (result) => {
        const { t } = this.props;
        let error = {};
        switch (result.message) {
            case 'Invalid username or password':
                error.message = t("error_invalidcredentials")
                break;
            default:
                error.message = t("error_unkown")
        }

        // Quick fix for firebase errors...
        if (result.code) {
            switch (result.code) {
                case 'auth/invalid-email':
                    error.message = t("validations.email")
                    break;
                case 'auth/user-disabled':
                    error.message = t("error_userdisabled")
                    break;
                case 'auth/user-not-found':
                    error.message = t("error_usernotfound")
                    break;
                case 'auth/wrong-password':
                    error.message = t("error_invalidcredentials")
                    break;
                default:
                    error.message = t("error_unkown")
            }
        }

        this.setState({ error, loading: false })
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({ error: null, loading: true });

        const { email, password } = this.state;
        const validEmail = isEmail(email);
        
        let credentials = {
            email: email,
            password: password,
            credentialtype: '',
            lang: i18next.lang
        }

        if (validEmail) {
            credentials.credentialtype = 'email'
        }

        AuthService.login(credentials).catch(error => {
            this.handleErrors(error);
        });
    }

    // Not used at the moment
    signInAndLink = () => {
        AuthService.signInAndLink(this.state.password).catch(error => {
            console.log(error);
        });
    }

    handleGoogle = () => {
        this.setState({ ...INITIAL_STATE, loading: true });
        AuthService.authenticateGoogle().catch(error => { 
            this.handleErrors(error);
        });
    }

    handleFacebook = () => {
        this.setState({ ...INITIAL_STATE, loading: true });
        AuthService.authenticateFacebook().catch(error => { 
            this.handleErrors(error);
        });
    }

    render() {

        const { email, password, error, loading } = this.state;

        let from = routes.WEBSITES;
        if (this.props.location.state) {
            from = this.props.location.state.redirectto;
        }

        if (AuthService.redirectToReferer) {
            return <Redirect to={{ 
                pathname: routes.ACTIVATE_ACCOUNT, 
                state: {
                    redirectto: routes.WEBSITES
                } }} />
        }

        if (AuthService.isAuthenticated) {
            return <Redirect to={from} />
        }

        // if (promptUserForPassword) {
        //     return (
        //         <PasswordPrompt handleInputChange={this.handleInputChange} password={password} signInAndLink={this.signInAndLink} />
        //     );
        // }

        return (
            <SignInForm
                handleFacebook={this.handleFacebook}
                handleGoogle={this.handleGoogle}
                handleSubmit={this.handleSubmit}
                handleInputChange={this.handleInputChange}
                loading={loading}
                error={error}
                password={password}
                email={email}
            />
        );
    }
}

export default withTranslation("Authentication")(withRouter(SignInPage));
