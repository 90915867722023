import React from 'react';

const EmployeeList = (props) => {

    const { employees, tempusers, t } = props;
    
    const delIconStyle = {
        position: 'absolute',
        right: '0',
        top: '0',
        padding: '6px 13px',
        cursor: 'pointer'
    }

    return (
        <React.Fragment>
            { employees && employees.length > 0 &&
                <div className="row">
                    <div className="col-md-12">
                        <h5 className="mt-3 mb-1">{t("employees")}</h5>
                    </div>
                    <div className="col-md-12">
                    {
                        employees.map((employee, index) => {
                            return (
                                <div key={index} className="card slideanim slide my-3">
                                    <div className="card-body py-1">
                                        <div className="row px-2 py-2 user-row">
                                            <div className="col-md-4">
                                                <div className="icon-wrap">
                                                    <i className="fa-solid fa-user text-secondary-dark"></i>
                                                </div>
                                                <span style={{fontSize: '0.9em'}}>{employee.name}</span>
                                            </div>
                                            <div className="col-md-7">
                                                <span style={{fontSize: '0.9em'}}>{employee.email}</span>
                                            </div>
                                            <div className="col-md-1">
                                                <span className="span-link" onClick={() => props.delete(employee.id, index)}>
                                                    <i className="fa-solid fa-xmark pull-right removable-item-icon text-danger" style={{marginTop: '0.3rem'}}></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                    </div>
                </div>
            }
            { tempusers && tempusers.length > 0 &&
                <div className="row">
                    <div className="col-md-12">
                        <h5 className="mt-3 mb-1">{t("invited_users")}</h5>
                    </div>
                    <div className="col-md-12">
                    { 
                        tempusers.map((tempuser, index) => {
                            return (
                                <div key={index} className="card slideanim slide my-3">
                                    <div className="card-body py-1">
                                        <div className="row px-2 py-2 user-row">
                                            <div className="col-md-11">
                                                <div className="icon-wrap">
                                                    <i className="fa-solid fa-envelope text-secondary"></i>
                                                </div>
                                                <span style={{fontSize: '0.9em'}}>{tempuser.email}</span>
                                            </div>
                                            <div className="col-md-1">
                                                <span className="span-link" onClick={() => props.delete(tempuser.id, index)}>
                                                    <i className="fa-solid fa-xmark pull-right removable-item-icon text-danger" style={{marginTop: '0.3rem'}}></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                    </div>
                </div>
            }
        </React.Fragment>
    );
}

export default EmployeeList;
