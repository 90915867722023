import React from 'react';
import * as routes from '../config/routes';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import BlankProfile from '../images/blank-profile.svg';
import AuthService from '../components/Authentication/Auth';
import EventBus from 'eventbusjs';

class Header extends React.Component {
    constructor() {
        super();
        this.timeout = null;
        this.state = {
            dropdownOpen: false,
            keywordfilter: ''
        };

        this.toggle = this.toggle.bind(this);
        this.search = this.search.bind(this);
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }

    search() {  
        const { keywordfilter } = this.state;
        const { history } = this.props;
        
        if (this.timeout) {
            clearTimeout(this.timeout);
        } 

        this.timeout = setTimeout(() => {
            EventBus.dispatch("keyword-search", keywordfilter);
            if (this.props.location.pathname !== routes.WEBSITES) {
                history.push(routes.WEBSITES);
            }
        }, 600);
    }

    handleInputChange = (e) => {
        this.setState({ 
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value }, 
        () => this.search());
    }

	handleClearSearch = (e) => {
		this.setState({
            keywordfilter: ''
        })
        EventBus.dispatch("keyword-search", '');
    }

    render() {
        const { t } = this.props;
        const { keywordfilter } = this.state;
        const authUser = AuthService.getuser();

        const siteauth = this.props.location.pathname === routes.SITEAUTH;

        if (siteauth) {
            return <div></div>
        }

        return (
            <div className="container-fluid d-flex bg-white" style={headerstyle}>
                <div className="mr-3 d-flex flex-row flex-grow-1 align-items-center">
                    <div className="btn btn-menutoggle d-flex align-items-center justify-content-center" onClick={this.props.toggleMenu} style={togglerStyle}>
                        <i className="fa-solid fa-bars"></i>
                    </div>
                    { authUser &&
                        <div className="d-flex flex-grow-1 align-items-center">
                            <div className="input-group nav-search-input-group pl-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <i className="fa-solid fa-magnifying-glass text-tertiary"></i>
                                    </span>
                                </div>
                                <input type="text" className="form-control" placeholder={t("search")} name="keywordfilter" onChange={this.handleInputChange} value={keywordfilter}/>
								{ keywordfilter &&
									<button className="btn btn-default btn-transparent btn-rounded" onClick={this.handleClearSearch}><i className="fa-solid fa-xmark text-tertiary"></i></button>
								}
                            </div>
                        </div>
                    }
                </div>
                <Link to={routes.ACCOUNT} className="d-none d-sm-none d-md-block">
                { authUser &&
                    <div style={accountStyle} className="pl-4 pr-4 d-flex align-items-center">
                        { authUser.photoURL
                            ? <img className="rounded-circle" src={authUser.photoURL} alt="user" width="25px"/>
                            : <img className="rounded-circle" src={BlankProfile} alt="blank" width="25px"/>
                        }
                        <span className="pl-2 text-small">{authUser.displayName}</span>
                    </div>
                }  
                </Link>
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="top-nav-dropdown">
                    <DropdownToggle color="default" caret>
                    {t("languages.select")}
                    </DropdownToggle>
                    <DropdownMenu end>
                        <DropdownItem onClick={() => this.props.changeLanguage('en')}>
                            {t("languages.english")}
                        </DropdownItem>
                        <DropdownItem onClick={() => this.props.changeLanguage('fi')}>
                            {t("languages.finnish")}
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
        );
    }
    
}

const headerstyle = {
    padding: '0',
    borderBottom: '1px solid #ecedf1',
    height: '60px'
}

const togglerStyle = {
    height: '100%',
    width: '65px',
    borderRight: '1px solid #ecedf1',
    cursor: 'pointer'
}

const accountStyle = {
    height: '100%',
    borderLeft: '1px solid #ecedf1'
}

export default withRouter(withTranslation("Navigation")(Header));
