import React, {useState, useEffect} from 'react';
import { withRouter, Redirect } from 'react-router-dom'
import { API } from '../../config/config';
import AuthService from '../Authentication/Auth';
import { withTranslation } from 'react-i18next';
import { auth } from '../../firebase/firebase';
import EventBus from 'eventbusjs';

import SubHeader from '../../includes/SubHeader';
import BillingForm from '../Sites/BillingForm';
import Users from '../Sites/Users';


const INITIAL_VALIDATORS = {
    billingname: { isInvalid: false, message: '' },
    billingcompany: { isInvalid: false, message: '' },
    billingemail: { isInvalid: false, message: '' },
    billingaddress: { isInvalid: false, message: '' },
    city: { isInvalid: false, message: '' },
    zip: { isInvalid: false, message: '' },
    einvoice_address: { isInvalid: false, message: '' },
    einvoice_operator: { isInvalid: false, message: '' },
    isValid: true
}

const INITIAL_SITE = {
    billing: 'bill',
    billingname: '',
    billingcompany: '',
    billingemail: '',
    billingaddress: '',
    billingaddress2: '',
    city: '',
    zip: '',
    reference: '',
    einvoice_address: '',
    einvoice_operator: '',
    sendingmethod: 'mail'
}

const UpdateBilling = (props) => {

    const [site, setSite] = React.useState(null);
    const [noSite, setNosite] = React.useState(false);
    const [updated, setUpdated] = React.useState(false);
    const [error, setError] = React.useState(false);

    const validations = { ...INITIAL_VALIDATORS };

    const { t } = props;

    const getUrlParameter = (sParam) => {
        var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'), sParameterName, i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : sParameterName[1];
            }
        }
    }

    const handleInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        setSite({
            ...site,
            [name]: value
        })
    }

    const onSave = () => {
        setError(false)
        setUpdated(false)

        let token = getUrlParameter("token");
        const json = { 
            ...site, 
            token: token 
        }
        fetch(API + "/sites/updatebilling", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(json)
        })
        .then(results => results.json())
        .then(data => {
            if (data.success) {
                setUpdated(true)
            } else {
                setError(true)
            }
        });
    }

    

    useEffect(() => {
		if (!site) {
            let token = getUrlParameter("token");
            const json = { token: token }
            fetch(API + "/sites/sitebytoken", {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify(json)
            })
            .then(results => results.json())
            .then(data => {
                if (data.success) {
                    setSite(data.site)
				} else {
                    setNosite(true)
                }
            });
		}
	}, [])

    return (
        <div className="container mt-4">
            {site ?
                <>
                <div className="container mt-4">
                    <div className="card">

                        {updated 
                        ? (
                            <>
                            <div className="card-body">
                                <h3>{site.title}</h3>
                                <p>{t('billing.updatebilling_updated')}</p>
                            </div>
                            <div className="card-footer bg-white">
                            </div>
                            </>
                        )
                        : (
                            <>
                            <div className="card-body">
                            <form>
                                <h3>{site.title}</h3>
                                <p>{t('billing.updatebilling_info')}</p>
                                <div>
                                    <BillingForm
                                        t={t}
                                        site={site}
                                        handleInputChange={handleInputChange}
                                        error={error}
                                        validations={validations}
                                    />
                                </div>
                            </form>

                            </div>
                            <div className="card-footer bg-white">
                                <button className="btn btn-secondary btn-lg mr-2" onClick={onSave}>{t("save")}</button>
                            </div>
                            </>
                        )
                        }


                    </div>
                </div>
                </>
            : <p>
                {noSite &&
                    <>{t('billing.updatebilling_notfound')}</>
                }
                </p>
            }
            
        </div>
    );
}

export default withRouter(withTranslation("Sites")(UpdateBilling));