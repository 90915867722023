import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { API } from '../../config/config';

class ForgottenPwd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            success: false,
            email: "",
            loading: false
        };
    } 

    handleInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({ [name]: value });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ error: false, loading: true });
        const json = {
            email: this.state.email
        }

        fetch(API + `/forgottenpassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(json)
        })
        .then(results => results.json())
        .then(result => {
            if (result.success) {
                this.setState({ success: true, loading: false });
            } else {
                const { t } = this.props;
                const error = { message: t("error_unkown") }
                this.setState({ error, email: '', loading: false });
            }
        })
        .catch(error => this.setState({ loading: false }));
    }

    render() {

        const { email, error, success, loading } = this.state
        const { t } = this.props;

        return (
            <div className="container mt-4">
                <div className="mx-auto" style={{maxWidth: '350px'}}>
                    <div className="card">
                        <div className="card-header bg-secondary-dark text-white highlight">{t("reset_pw")}</div>
                        <div className="card-body">
                            <form onSubmit={this.handleSubmit} >
                                <div className="form-group">
                                    <input className="form-control" type="email" name="email" id="userEmail" placeholder={t("email")} value={email} onChange={this.handleInputChange} autoFocus required />
                                </div>
                                { error &&
                                    <div className="alert alert-danger">
                                        {error.message}
                                    </div>                            
                                }
                                { success &&
                                    <div className="alert alert-success">
                                        {t("password_reset_success")}
                                    </div>                            
                                }
                                <button className="btn btn-primary btn-block" disabled={loading}>{t("send_pw")}</button>
                                <div className="mt-3">
                                    <span className="span-link text-secondary-dark" onClick={() => this.props.history.goBack()} style={{fontSize: '0.9rem'}}>{t("back")}</span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>                                      
        );
    }
}

export default withTranslation("Authentication")(ForgottenPwd);