import React from 'react';
import { Link } from 'react-router-dom';
import * as routes from '../config/routes';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

const SubHeader = props => {

    const { t, keywordfilter, total, totalunpublished ,found, site, authUser, domain, publishsite, sitename, newdomain } = props;
    let leftcontent;
    let rightcontent;
	let maincontent;

    if (site) {
        leftcontent = 
        <div>
            <h2 style={{ lineHeight: '1em', marginBottom: '0' }}>{site.title}</h2>
                <div>
                    { authUser && authUser.bonsaituser.role === 'admin' &&
                        <div style={{ display: 'inline-block' }}>
                            <small className="text-medium-gray">{site.name}</small>
                        </div>
                    }
                    { site.created &&
                        <div style={{ display: 'inline-block' }}>
                            <span className="text-medium-gray ml-2 mr-2" style={{ verticalAlign: 'middle' }}>&bull;</span>
                            <small className="text-medium-gray">{t("created")} </small>
                            <small className="text-medium-gray">
                                <Moment format="DD.MM.YYYY">{site.created.$date}</Moment> 
                            </small>
                        </div>
                    }
                    { site.lastlogin &&
                        <div style={{ display: 'inline-block' }}>
                            <span className="text-medium-gray ml-2 mr-2" style={{ verticalAlign: 'middle' }}>&bull;</span>
                            <small className="text-medium-gray">{t("sites.lastlogin")} </small>
                            <small className="text-medium-gray">
                                <Moment format="DD.MM.YYYY">{site.created.$date}</Moment> 
                            </small>
                        </div>
                    }
                    { site.trialexpires &&
                        <div style={{ display: 'inline-block' }}>
                            <span className="text-medium-gray ml-2 mr-2" style={{ verticalAlign: 'middle' }}>&bull;</span>
                            <small className="text-medium-gray">{t("sites.trialexpires")} </small>
                            <small className="text-medium-gray">{site.trialexpires}</small>
                        </div>
                    }

                </div>
        </div>

        if (site.published === "true" || site.published === true) {
            rightcontent = 
            <div>
                <div className="d-flex justify-content-end align-items-center">
                    <span className="mr-2 bg-success dot-success"></span> 
                    <span>{t("sites.published")}</span>
                </div>
                <div>
                { site.publishedAt &&
                    <small className="text-medium-gray d-block">
                        <Moment format="DD.MM.YYYY">{site.publishedAt.$date}</Moment> 
                    </small>
                }
                </div>
            </div>
        } else {
            rightcontent = 
            <div>
                <div className="d-flex d-flex justify-content-end align-items-center">
                    <span className="mr-2 bg-warning dot-success"></span> 
                    <span>{t("sites.statustxt")}</span>
                </div>
                <div>
                    <Link className="btn btn-primary btn-sm" to={routes.PUBLISH_HOME + `/${site.id}`}> {t("sites.publish") + " " + t("sites.here")}</Link>
                </div>
            </div>
        }

		maincontent = 
		<div className="row">
			<div className="col-sm-6">
				{ leftcontent }
			</div>
			<div className="col-sm-6 text-right">
				{ rightcontent }
			</div>
		</div>

    } else if (!site && keywordfilter) {
        leftcontent = 
        <div>
            <h2 style={{ lineHeight: '1em', marginBottom: '0' }}>{t("sites.sitestxt") + " " + t("sites.bykeyword") + " '" + keywordfilter + "'"}</h2>
            <small className="text-medium-gray">{t("sites.found").charAt(0).toUpperCase() + t("sites.found").slice(1) + " " + found}</small>
            <span className="text-medium-gray ml-2 mr-2" style={{ verticalAlign: 'middle' }}>&bull;</span>
            <small className="text-medium-gray">{t("sites.total").charAt(0).toUpperCase() + t("sites.total").slice(1)} {total}</small>
        </div>

        rightcontent =
        <Link to={routes.NEW_WEBSITE} className="btn btn-default btn-sm">
            {t("new_website")}
        </Link>

		maincontent = 
		<div className="row">
			<div className="col-sm-6">
				{ leftcontent }
			</div>
			<div className="col-sm-6 text-right">
				{ rightcontent }
			</div>
		</div>


    } else if (publishsite) {
        leftcontent = 
        <div>
            <h3 style={{ lineHeight: '1em', marginBottom: '0' }}>{t("website_to_publish")}: { sitename }</h3>
            <small className="text-medium-gray">{ domain }</small>
            <span className="text-medium-gray ml-2 mr-2" style={{ verticalAlign: 'middle' }}>&bull;</span>
            <small className="text-medium-gray">
                <Moment format="DD.MM.YYYY">{new Date()}</Moment> 
            </small>
        </div>

		maincontent = 
		<div className="row">
			<div className="col-sm-12">
				{ leftcontent }
			</div>
		</div>
	} else if (newdomain) {
        leftcontent = 
        <div>
            <h3 style={{ lineHeight: '1em', marginBottom: '0' }}>{t("website_to_add_domain")}: { sitename }</h3>
            <small className="text-medium-gray">{ domain }</small>
            <span className="text-medium-gray ml-2 mr-2" style={{ verticalAlign: 'middle' }}>&bull;</span>
            <small className="text-medium-gray">
                <Moment format="DD.MM.YYYY">{new Date()}</Moment> 
            </small>
        </div>

		maincontent = 
		<div className="row">
			<div className="col-sm-12">
				{ leftcontent }
			</div>
		</div>

    } else {
        leftcontent = 
        <div>
            <h2 style={{ lineHeight: '1em', marginBottom: '0' }}>{t("sites.allsites")}</h2>
            <small className="text-medium-gray">{t("sites.total").charAt(0).toUpperCase() + t("sites.total").slice(1)} {total}</small>
            { authUser && authUser.bonsaituser.role === 'admin' &&
                <span>
                    <span className="text-medium-gray ml-2 mr-2" style={{ verticalAlign: 'middle' }}>&bull;</span>
                    <small className="text-medium-gray">{t("sites.totalunpublished") + " " + totalunpublished }</small>
                </span>
            }
        </div>

        rightcontent =
        <Link to={routes.NEW_WEBSITE} id="newsite" className="btn btn-default btn-sm">
            {t("new_website")}
        </Link>

		maincontent = 
		<div className="row">
			<div className="col-sm-6">
				{ leftcontent }
			</div>
			<div className="col-sm-6 text-right">
				{ rightcontent }
			</div>
		</div>
    }

    return (
        <div className="bg-white pt-4 pb-4 mb-4">
            <div className="container">
                { maincontent }
            </div>
        </div>
    );
}

SubHeader.propTypes = {
    total: PropTypes.number,
    allowpublish: PropTypes.bool,
    keywordfilter: PropTypes.string
};

export default withTranslation("Dashboard")(SubHeader);