// For development
//export const API = "http://localhost:9000/api/v1";
// For production
//export const API = "https://account.bonsait.fi/api/v1";
let apipath = "/api/v1"
if (window.location.port && window.location.port.indexOf('3000')>-1) {
    apipath = "http://localhost:9000/api/v1"
}

export const API = apipath;
export const SERVER_IP = "188.117.29.249";
export const SERVER_CNAME = "s2871.bonsait.net";
