import EventBus from 'eventbusjs';
import { auth, firebaseapp } from '../../firebase/firebase';
import { API } from '../../config/config';
import i18n from 'i18next';

class AuthServiceImpl {
	constructor() {
        this.isAuthenticated = false;
        this.redirectToReferer = false;
        this.userdata = null;
        this.olduser = null;
        this.invitetoken = '';
        this.pwdtemp = '';
    }
    
	authenticate() {
        let json = {};
        auth.currentUser.getIdToken().then(idtoken => {
            const { lang } = this.userdata || '';

            json.idtoken = idtoken;
            json.invite = this.invitetoken;
            json.bonsaituser = this.olduser ? this.olduser.id : '';
            json.email = this.olduser ? this.olduser.email : '';
            json.pwd = this.pwdtemp;

            json.lang = i18n.language;
            if (lang) {
                json.lang = lang.substring(0, 2);
            }

            return fetch(API + "/authenticate", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(json)
            })
        })
        .then(results => results.json())
        .then(userdata =>  {
            if (userdata.id) {
                this.invitetoken = '';
                this.setuser(auth.currentUser, userdata);
                this.isAuthenticated = true;
            }
            EventBus.dispatch("auth-changed", this.isAuthenticated);
            this.pwdtemp = '';
        })
        .catch(error => {
            EventBus.dispatch("auth-changed", this.isAuthenticated);
            this.pwdtemp = '';
        });
    }

	authenticateGoogle() {
        const provider = new firebaseapp.auth.GoogleAuthProvider();
		return auth.signInWithPopup(provider);
    }

    authenticateFacebook() {
        const fbprovider = new firebaseapp.auth.FacebookAuthProvider();
        return auth.signInWithPopup(fbprovider);
    }

    linkWithGoogle() {
        const provider = new firebaseapp.auth.GoogleAuthProvider();
        return auth.currentUser.linkWithPopup(provider);
    }

    linkWithFacebook() {
        const fbprovider = new firebaseapp.auth.FacebookAuthProvider();
        return auth.currentUser.linkWithPopup(fbprovider);
    }

    fetchSignInMethods() {
        return auth.fetchSignInMethodsForEmail(this.currentEmail);
    }

    signInAndLink(password) {
        return auth.signInWithEmailAndPassword(this.currentEmail, password).then(user => {
            return user.linkWithCredential(this.pendingCred);
        }).then(() => {
            EventBus.dispatch("userdata-changed", true);
        });
    }
    
    siteauth(authdata) {
        return auth.currentUser.getIdToken().then(idtoken => { 
            authdata.idtoken = idtoken;
            return fetch(API + "/siteauth", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(authdata),
            })
        })
        .then(results => results.json())
        .then(response => {
            if (response.success === true) {
                window.location.href = response.referer;
            } else {
                throw Error(response.message);
            }
        });
    }

    login(credentials) {
        return fetch(API + "/login", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
        .then(results => results.json())
        .then(response =>  {
            if (response.registered === true) {
                return auth.signInWithEmailAndPassword(credentials.email, credentials.password);
            } else if (response.registered === false) {
                this.redirectToReferer = true;
                this.setolduser(response);
                EventBus.dispatch("should-redirect", this.redirectToReferer);
            } else {
                throw Error(response.error);
            }
        })
    }
    
	register(formdata) {
        formdata.invite = this.invitetoken;
        return fetch(API + "/users", {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify(formdata),
        })
        .then(results => results.json())
        .then(result => {
            if (result.error) {
                throw Error(result.error);
            } else {
                return auth.createUserWithEmailAndPassword(formdata.email, formdata.passwd);
            }
        })
        .then(() => {
            const currentUser = auth.currentUser;
            currentUser.updateProfile({
                displayName: formdata.name
            }).then(() => {
                this.userdata.displayName = currentUser.displayName;
                EventBus.dispatch("userdata-changed", true);
            });
        })
    }

    updateToFirebase(credentials) {
        this.pwdtemp = credentials.pwd;
        return auth.createUserWithEmailAndPassword(credentials.email, credentials.pwd)
        .then(() => {
            const currentUser = auth.currentUser;
            currentUser.updateProfile({
                displayName: credentials.name
            }).then(() => {
                this.userdata.displayName = currentUser.displayName;
                EventBus.dispatch("userdata-changed", true);
            });
        })
    }
    
	signout() {
		this.isAuthenticated = false;
		this.userdata = null;
		auth.signOut();
        EventBus.dispatch("auth-changed", this.isAuthenticated);
        //localStorage.clear();
    }
    
	getuser() {
		return this.userdata;
    }

    getolduser() {
        return this.olduser;
    }

    setCurrentEmail(currentEmail) {
        this.currentEmail = currentEmail
    }

    setPendingCred(pendingCred) {
        this.pendingCred = pendingCred
    }
    
	setuser(authuser, bonsaituser) {
		this.userdata = {
			displayName : authuser.displayName,
			email : authuser.email,
			emailVerified : authuser.emailVerified,
			photoURL : authuser.photoURL,
			isAnonymous : authuser.isAnonymous,
			uid : authuser.uid,
            providerData : authuser.providerData,
            bonsaituser: bonsaituser,
            lang: bonsaituser.lang || ''
		};
    }
    
    setolduser(olduser) {
        this.olduser = {
            id: olduser.id,
            email: olduser.email,
            name: olduser.name,
            usersites: olduser.usersites
        }
    }
}

auth.onAuthStateChanged(user => {
    if (user) {
        AuthService.authenticate();
    } else {
        AuthService.signout();
        EventBus.dispatch("loading-changed", false);
    }
});

const AuthService = new AuthServiceImpl();
export default AuthService;