import React from 'react';
import i18n from 'i18next';
import { db, messaging } from '../firebase';
import Moment from 'react-moment';
import AuthService from './Authentication/Auth';
import { auth } from '../firebase/firebase';
import EventBus from 'eventbusjs';
import { API } from '../config/config';
import 'moment/locale/fi.js';

const appHOC = (Component) =>
    class AppHOC extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                menuOpen: false,
                isAuthenticated: AuthService.isAuthenticated,
                isLoading: true,
                redirectToReferer: AuthService.redirectToReferer,
                userdatachanged: false,
                keyword: ''
            };

            this.authHandler = this.authHandler.bind(this);
            this.loadHandler = this.loadHandler.bind(this);
            this.redirectHandler = this.redirectHandler.bind(this);
            this.keywordHandler = this.keywordHandler.bind(this);
			this.userdataHandler = this.userdataHandler.bind(this);
			this.menuHandler = this.menuHandler.bind(this);
        }

        toggle = () => {
            this.setState({ menuOpen: !this.state.menuOpen });
		}
		
        changeLanguage = (lng) => {
            i18n.changeLanguage(lng);
            Moment.globalLocale = lng;

            let langData = {
                lang: lng
            }
            if (AuthService.userdata) {
                auth.currentUser.getIdToken().then(idtoken => {
                    langData.idtoken = idtoken;
                    langData.id = AuthService.userdata.bonsaituser.id;
                    fetch(API + "/changelang", {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(langData)
                    })
                    .then(results => results.json())
                    .then(response =>  {
                        console.log(response)
                    })
                });

            }
           
        }

        initMessaging = (authUser) => {
             // Notifications - check if is supported for IE Crash
             if (messaging) {
                messaging.requestPermission()
                .then(() => {
                    console.log('Notification permission granted.');
                    return messaging.getToken();
                })
                .then(token => {
                    let fcm = {
                        fcmToken: token
                    }
                    db.edit("users", authUser.uid, fcm);
                })
                .catch(err => {
                    console.log('Unable to get permission to notify.', err);
                });
                
                messaging.onMessage(payload => {
                    console.log("onMessage", payload);
                });
                
                // Callback fired if Instance ID token is updated.
                messaging.onTokenRefresh(() => {
                    messaging.getToken().then(refreshedToken => {
                        console.log('Token refreshed.', refreshedToken);
                        let fcm = {
                            fcmToken: refreshedToken
                        }
                        db.edit("users", authUser.uid, fcm);
                    })
                    .catch(error => {
                        console.log('Unable to retrieve refreshed token ', error);
                    });
                });
            }
        }

        authHandler(auth) {
            this.setState({ 
                isAuthenticated: auth,
				isLoading: false
            }, () => {
                //const authUser = AuthService.getuser();
                //this.initMessaging(authUser);
            });
        }

        redirectHandler(shouldredirect) {
            this.setState({ redirectToReferer: shouldredirect });
        }

        keywordHandler(keyword) {
            this.setState({ keyword: keyword.target });
        }

        userdataHandler(userdatachanged) {
            this.setState({ userdatachanged: userdatachanged });
        }

        loadHandler(state) {
            this.setState({ isLoading: state.target });
		}
		
		menuHandler(menustate) {
            this.setState({ menuOpen: menustate });
        }

        componentDidMount() {
            EventBus.addEventListener('auth-changed', this.authHandler);
            EventBus.addEventListener('loading-changed', this.loadHandler);
            EventBus.addEventListener('should-redirect', this.redirectHandler);
            EventBus.addEventListener('keyword-search', this.keywordHandler);
			EventBus.addEventListener('userdata-changed', this.userdataHandler);
			EventBus.addEventListener('sidemenu-changed', this.menuHandler);
        }

        componentWillUnmount() {
            EventBus.removeEventListener('auth-changed', this.authHandler);
            EventBus.removeEventListener('loading-changed', this.loadHandler);
            EventBus.removeEventListener('should-redirect', this.redirectHandler);
            EventBus.removeEventListener('keyword-search', this.keywordHandler);
			EventBus.removeEventListener('userdata-changed', this.userdataHandler);
			EventBus.removeEventListener('sidemenu-changed', this.menuHandler);
        }
        
        render() {
            const { menuOpen, keyword, isAuthenticated, isLoading } = this.state;

            return (
                <Component menuOpen={menuOpen} toggleMenu={this.toggle} changeLanguage={this.changeLanguage} keyword={keyword} isAuthenticated={isAuthenticated} isLoading={isLoading}/>
            );
        }
    }

export default appHOC;
