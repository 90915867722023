// User prices
export const XS = 16;
export const S = 29;
export const M = 49;
export const L = 79;
export const XL = 129;

// Manager prices - 20% discount
export const MANAGER_XS = 13;
export const MANAGER_S = 23;
export const MANAGER_M = 38;
export const MANAGER_L = 68;
export const MANAGER_XL = 99;

// Default profits
export const DEFAULT_PROFIT_XS = 45;
export const DEFAULT_PROFIT_S = 69;
export const DEFAULT_PROFIT_M = 117;
export const DEFAULT_PROFIT_L = 189;
export const DEFAULT_PROFIT_XL = 309;