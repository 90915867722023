import React from 'react';
import { NavLink, Link, withRouter } from 'react-router-dom';
import BonsaitLogo from '../images/logo-white-vert-cropped.png';
import AuthService from '../components/Authentication/Auth';
import * as routes from '../config/routes';
import { withTranslation } from "react-i18next";

const SideNav = (props) => {
    const authUser = AuthService.getuser();
    return (
        <div>
            <nav className={props.menuOpen ? "sidenav bg-secondary-dark fixed-top" : "sidenav bg-secondary-dark fixed-top sidenav-active"}>
                <ul className="text-center sideprofile" style={{ borderTop: 'none' }}>
                    <li>
                        <img className="img-fluid" src={BonsaitLogo} alt="bonsait" width="100"/>
                        <span className="account-title">Account</span>
                    </li>
                </ul>
                { authUser 
                    ? <NavigationAuth user={authUser} t={props.t}/>
                    : <NavigationNonAuth {...props} />
                }
            </nav>
        </div>
    );
}

const NavigationAuth = (props) =>
    <div>
        <ul className="navitems">
            <li>
                <a href={routes.WEBSITES}>
                    <div className="icon-wrap text-primary text-center">
                        <i className="fa-solid fa-table-columns"></i>
                    </div>
                    <span>{props.t("websites")}</span>
                </a>
            </li>
            <li className="sidenav-subli">
                <NavLink to={routes.WEBSITES} className="pl-5 sidenav-sublink">
                    <span className="sidenav-subitem">{props.t("all_websites")}</span>
                </NavLink>
            </li>
            <li className="sidenav-subli">
                <NavLink to={routes.NEW_WEBSITE} className="pl-5 sidenav-sublink">
                    <span className="sidenav-subitem">{props.t("new_website")}</span>
                </NavLink>
            </li>
            {/* 
            <li>
                <NavLink to={routes.DOMAINS}>
                    <div className="icon-wrap text-primary text-center">
                        <i className="fa-solid fa-globe"></i>
                    </div>
                    <span>{props.t("domains")}</span>
                </NavLink>
            </li>
            */}
            { props.user.bonsaituser && props.user.bonsaituser.role === 'manager' &&
            <React.Fragment>
                <li>
                    <a href={routes.RESELLER}>
                        <div className="icon-wrap text-primary text-center">
                            <i className="fa-solid fa-users"></i>
                        </div>
                        <span>{props.t("employees")}</span>
                    </a>
                </li>
                <li className="sidenav-subli">
                    <NavLink exact to={routes.RESELLER} className="pl-5 sidenav-sublink">
                        <span className="sidenav-subitem">{props.t("employees_list")}</span>
                    </NavLink>
                </li>
            </React.Fragment>
			}
			{ props.user.bonsaituser && props.user.bonsaituser.role === 'admin' &&
			<React.Fragment>
				<li>
                    <a href={routes.CONTRACTS}>
						<div className="icon-wrap text-primary text-center">
                            <i className="fa-solid fa-file-contract"></i>
						</div>
						<span>{props.t("contracts")}</span>
					</a>
				</li>
                <li className="sidenav-subli">
					<NavLink exact to={routes.CONTRACTS} className="pl-5 sidenav-sublink">
						<span className="sidenav-subitem">{props.t("contracts_all")}</span>
					</NavLink>
				</li>
                <li className="sidenav-subli">
					<NavLink to={routes.CONTRACTS_STATS} className="pl-5 sidenav-sublink">
						<span className="sidenav-subitem">{props.t("contracts_stats")}</span>
					</NavLink>
				</li>
                <li className="sidenav-subli">
					<NavLink to={routes.CONTRACTS_CUSTOM} className="pl-5 sidenav-sublink">
						<span className="sidenav-subitem">{props.t("contracts_custom")}</span>
					</NavLink>
				</li>
                <li className="sidenav-subli">
					<NavLink to={routes.CONTRACTS_FREEBIRDS} className="pl-5 sidenav-sublink">
						<span className="sidenav-subitem">{props.t("contracts_freebirds")}</span>
					</NavLink>
				</li>
                
                {/* 
                <li className="sidenav-subli">
					<NavLink to={routes.CONTRACTS_SMS} className="pl-5 sidenav-sublink">
						<span className="sidenav-subitem">{props.t("contracts_sms")}</span>
					</NavLink>
				</li>

                */}
                {/* 
				<li className="sidenav-subli">
					<NavLink to={routes.CONTRACTS_PROVISION} className="pl-5 sidenav-sublink">
						<span className="sidenav-subitem">{props.t("contracts_provision")}</span>
					</NavLink>
				</li>
                */}
                
			</React.Fragment>
			}
            <li>
                <a href={routes.ACCOUNT}>
                    <div className="icon-wrap text-primary text-center">
                        <i className="fa-solid fa-user"></i>
                    </div>
                    <span>{props.t("account")}</span>
                </a>
            </li>
            <li className="sidenav-subli">
                <NavLink exact to={routes.ACCOUNT} className="pl-5 sidenav-sublink">
                    <span className="sidenav-subitem">{props.t("account_myinfo")}</span>
                </NavLink>
            </li>

            <li className="nav-signout mt-5">
                <NavLink to={routes.SIGN_IN} onClick={() => AuthService.signout()}>
                    <div className="icon-wrap text-primary text-center">
                        <i className="fa-solid fa-arrow-right-from-bracket"></i>
                    </div>
                    <span>{props.t("sign_out")}</span>
                </NavLink>
            </li>
        </ul>
        <ul className="navitems bottom-items">
            <li>
                <a href={props.t("supporturl")} target="_blank" rel="noopener noreferrer">
                    <div className="icon-wrap text-primary text-center">
                    <i className="fa-solid fa-circle-question"></i>
                    </div>
                    <span>{props.t("support")}</span>
                </a>
            </li>
        </ul>
    </div>

const NavigationNonAuth = (props) =>
    <div>
        <ul className="navitems">
            <li>
                <NavLink to={routes.SIGN_IN}>
                    <div className="icon-wrap text-primary text-center">
                        <i className="fa-solid fa-arrow-right-to-bracket"></i>
                    </div>
                    <span>{props.t("sign_in")}</span>
                </NavLink>
            </li>
        </ul>
        <ul className="navitems bottom-items">
            <li>
                <a href={props.t("supporturl")} target="_blank" rel="noopener noreferrer">
                    <div className="icon-wrap text-primary text-center">
                        <i className="fa-solid fa-circle-question"></i>
                    </div>
                    <span>{props.t("support")}</span>
                </a>
            </li>
        </ul>
    </div>
    
export default withTranslation("Navigation")(SideNav);
