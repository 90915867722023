import React from 'react';
import { Redirect, withRouter } from 'react-router-dom'
import { withTranslation } from "react-i18next";
import { API } from '../../config/config';
import { DOMAINS } from '../../config/domains';
import { auth } from '../../firebase/firebase';
import EventBus from 'eventbusjs';
import Loader from '../../includes/Loader';
import withAuthorization from '../withAuthorization';
import AuthService from '../Authentication/Auth';
import SubHeader from '../../includes/SubHeader';
import punycode from 'punycode/';


class NewDomain extends React.Component {

	constructor(props) {
        super(props);
        this.state = {
            site: null,
            loading: true,
			error: null,
			searchdomain: '',
			searcheddomain: '',
			domainresult: [],
			transferdomain: null,
			transferdomainavailable: false
		}
    }

	checkDomain = (domain, istransfer) => {
		const authUser = AuthService.getuser();
        const { id } = authUser.bonsaituser;

        auth.currentUser.getIdToken().then(idtoken => {

			let searchabledomain = domain.punycode;

            const json = { idtoken: idtoken }
            fetch(API + `/searchdomain?search=${searchabledomain}&userid=${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(json)
            })
                .then(results => results.json())
                .then(result => {
                    if (result) {

						if (istransfer) {
							let tdomain = this.state.transferdomain
							if (result.availability) {
								tdomain.availability = 'free'
							} else {
								tdomain.availability = 'reserved'
							}
							this.setState({ transferdomain: tdomain, transferdomainavailable: true });

						} else {
							let domains = this.state.domainresult
							for (let i = 0; i < domains.length; i++) {
								if (domains[i].punycode===result.domain) {
									if (result.availability) {
										domains[i].availability = 'free'
									} else {
										domains[i].availability = 'reserved'
									}
								}
							}
							this.setState({ domainresult: domains });
						}
						
                    } else {
                        this.handleErrors(result);
                    }
                })
                .catch(error => (error));
        });
    }

	getSite = (siteid) => {
		const authUser = AuthService.getuser();
        const { id } = authUser.bonsaituser;

        auth.currentUser.getIdToken().then(idtoken => {
            const json = { idtoken: idtoken }
            fetch(API + `/getsite?siteid=${siteid}&userid=${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(json)
            })
			.then(results => results.json())
			.then(result => {
				if (result.id) {
					this.missingbilling = result.missingbilling
					let defaultsite = { ...this.state.site };
					
					const siteobject = Object.assign({ ...defaultsite }, result);
					this.setState({ site: siteobject, loading: false });
				} else {
					this.handleErrors(result);
				}
			})
			.catch(error => (error));
        });
    }

	handleErrors = (result) => {
        const { t } = this.props;
        let error = {};

        switch (result.message) {
            case 'Already published':
                error.message = t("already_published")
                break;
            case 'Unauthorized publisher':
                error.message = t("unauthorized_publishing")
                break;
            case 'Missing billing information"':
                error.message = t("missing_billing")
                break;
            default:
                error.message = t("error.unknown")
        }

        this.setState({ loading: false, error });
    }

	extractHostname = (url) => {
		var hostname;
		//find & remove protocol (http, ftp, etc.) and get hostname
		if (url.indexOf("//") > -1) {
			hostname = url.split('/')[2];
		}
		else {
			hostname = url.split('/')[0];
		}
		//find & remove port number
		hostname = hostname.split(':')[0];
		//find & remove "?"
		hostname = hostname.split('?')[0];
		return hostname;
	}

	extractRootDomain = (url, withtld) => {
		var domain = this.extractHostname(url.toLowerCase()),
			splitArr = domain.split('.'),
			arrLen = splitArr.length;
		//extracting the root domain here
		//if there is a subdomain 
		if (arrLen > 1) {
			if (withtld) {
				domain = splitArr[arrLen - 2] + '.' + splitArr[arrLen - 1];
			} else {
				domain = splitArr[arrLen - 2];
			}
			
			//check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
			if (splitArr[arrLen - 2].length == 2 && splitArr[arrLen - 1].length == 2) {
				//this is using a ccTLD
				//domain = splitArr[arrLen - 3] + '.' + domain;
				domain = splitArr[arrLen - 3];
			}
		}
		return domain;
	}

	extractTld = (url) => {
		var domain = this.extractHostname(url.toLowerCase()),
			splitArr = domain.split('.'),
			arrLen = splitArr.length;
		//extracting the root domain here
		//if there is a subdomain 
		if (arrLen > 1) {
			domain = splitArr[arrLen - 1];
			
			//check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
			if (splitArr[arrLen - 2].length == 2 && splitArr[arrLen - 1].length == 2) {
				//this is using a ccTLD
				//domain = splitArr[arrLen - 3] + '.' + domain;
				domain = splitArr[arrLen - 3];
			}
		}
		return domain;
	}

	onSearchDomain = () => {
		// check domain for transfer
		let transferdomainsearch = this.extractRootDomain(this.state.searchdomain, true);
		let transfertldsearch = this.extractTld(transferdomainsearch);
		let transferdomainprice = 120.0;
		for (let i = 0; i < DOMAINS.length; i++) {
			if (DOMAINS[i].tld === transfertldsearch) {
				transferdomainprice = DOMAINS[i].price
			}
		}
		let transferdomain = {
			name: transferdomainsearch,
			tld: transfertldsearch,
			punycode: punycode.toASCII(transferdomainsearch),
			availability: 'pending',
			price: transferdomainprice
		};

		// check domains for register
		let realsearch = this.extractRootDomain(this.state.searchdomain);
		let domainlist = []
		for (let i = 0; i < DOMAINS.length; i++) {
			let listdomain = {
				name: realsearch+'.'+DOMAINS[i].tld,
				tld: DOMAINS[i].tld,
				punycode: punycode.toASCII(realsearch+'.'+DOMAINS[i].tld),
				availability: 'pending',
				price: DOMAINS[i].price
			};
			domainlist.push(listdomain);
		}

		this.setState({ searcheddomain: realsearch, domainresult: domainlist, transferdomain: transferdomain }, () => {
			this.checkTransferDomain();
            this.checkDomainAvailability();
        });
	}

	handleKeyPress = (event) => {
		if (event.key === 'Enter'){
			this.onSearchDomain();	
		}
	}

	checkTransferDomain = () => {
		let transferdomain = this.state.transferdomain
		// Only check for transfer if is domain wih tld
		if (transferdomain.name.indexOf('.')>-1) {
			this.checkDomain(transferdomain, true);
		}
	}

	checkDomainAvailability = () => {
		let domainlist = this.state.domainresult
		for (let i = 0; i < domainlist.length; i++) {
			let d = domainlist[i];
			this.checkDomain(d);
		}
	}

	onReserveDomain = (index) => {
	}

	onTransferDomain = (index) => {
	}

	handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
			searchdomain: value
		});

    }

	componentDidMount() {
        const { siteid } = this.props.match.params;
        this.getSite(siteid);

		// open side menu
		EventBus.dispatch("sidemenu-changed", true);
    }

	render() {

		const authUser = AuthService.getuser();
		const { site, loading, error, searchdomain, searcheddomain, domainresult, transferdomain, transferdomainavailable } = this.state
		const { t } = this.props;

		if (loading) {
            return (
                <div className="text-center mt-4">
                    <Loader size="3x" classes="text-primary" />
                </div>
            );
        }

		if (error) {
            return (
                <div className="text-center mt-4">
					<i className="fa-solid fa-exclamation-circle fa-4x mb-4 text-danger"></i>
                    <h4>{error.message}</h4>
                </div>
            );
        }

		return (
			<React.Fragment>
				<SubHeader sitename={site.title} authUser={authUser} newdomain={true}/>
				<div className="container">
					<div className="card mt-4">
						<div className="card-header bg-secondary-dark highlight text-white">{t("domain.newdomaintitle")}</div>
						<div className="card-body">
							<div className="form-group">
								<label htmlFor="searchdomain">{t("domain.searchlabel")}</label>
								<div className="input-group mb-4">
									<input id="searchdomain" name="searchdomain" value={searchdomain} onChange={this.handleInputChange} onKeyPress={this.handleKeyPress} type="text" className="form-control" />
									<div className="input-group-append">
										<button className="btn btn-secondary" type="button" onClick={this.onSearchDomain}>{t("domain.searchbtn")}</button>
									</div>
								</div>
							</div>

							{searcheddomain &&
							<React.Fragment>

								{(transferdomain && transferdomain.availability==='reserved') &&
									<React.Fragment>
										<div className="card mb-4 bg-light-gray">
											<div className="card-body">
												<div className="row">
													<div className="col-md-8"><p className="card-text">{t("domain.searchresult_title_transfer_start")} <strong>'{transferdomain.name}'</strong> {t("domain.searchresult_title_transfer_end")}</p></div>
													<div className="col-md-4 text-right"><button type="button" className="btn btn-secondary btn-sm" onClick={() => this.onTransferDomain(transferdomain)}>{t("domain.searchresult_action_transfer")}</button></div>
												</div>
											</div>
										</div>
									</React.Fragment>
								}

								<h4>{t("domain.searchresult_title")} <strong>'{searcheddomain}'</strong></h4>

								<div className="row mb-1 mt-3">
									<div className="col-md-4">{t("domain.searchresult_title_domain")}</div>
									<div className="col-md-2">{t("domain.searchresult_title_availability")}</div>
									<div className="col-md-2">{t("domain.searchresult_title_price")}</div>
									<div className="col-md-4">{t("domain.searchresult_title_action")}</div>
								</div>
								<hr />

								{domainresult.map((domain, index) => {
									return (
										<div key={"additionaldomain" + index} className="row mb-2">
											<div className="col-md-4">{domain.name}</div>
											<div className="col-md-2">
												{domain.availability==='free' &&
													<span className="badge badge-success">{t("domain.searchresult_availability_free")}</span>
												}
												{domain.availability==='reserved' &&
													<span className="badge badge-warning">{t("domain.searchresult_availability_reserved")}</span>
												}
												{domain.availability==='pending' &&
													<span className="badge badge-info">{t("domain.searchresult_availability_loading")}</span>
												}
											</div>
											<div className="col-md-2">
												{domain.price} &euro; / {t("domain.searchresult_title_price_year")}
											</div>
											<div className="col-md-4">
												{domain.availability==='free' &&
													<button type="button" className="btn btn-default btn-sm" onClick={() => this.onReserveDomain(domain)}>{t("domain.searchresult_action_reserve")}</button>
												}
												{domain.availability==='reserved' &&
													<button type="button" className="btn btn-link text-secondary btn-sm" onClick={() => this.onTransferDomain(domain)}>{t("domain.searchresult_action_transfer")}</button>
												}
												
											</div>
										</div>
									)
								})}

							</React.Fragment>
							}

						</div>
						<div className="card-footer bg-white">
                            <button className="btn btn-link float-right" onClick={() => this.props.history.goBack(-1)}>{t("cancel")}</button>
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withAuthorization(withRouter(withTranslation("Sites")(NewDomain)));