import React from 'react';
import { withRouter, Redirect } from 'react-router-dom'
import * as routes from '../../config/routes';
import { API } from '../../config/config';
import { validateAccountPage } from '../../helpers/Helper';
import AuthService from '../Authentication/Auth';
import withAuthorization from '../withAuthorization';
import { withTranslation } from 'react-i18next';
import { auth } from '../../firebase/firebase';
import EventBus from 'eventbusjs';

const AccountPage = (props) => {
    const authUser = AuthService.getuser();
    return (
        <div className="container mt-4">
            <Account {...props} authUser={authUser}/>
        </div>
    );
}

const INITIAL_VALIDATORS = {
    name: { isInvalid: false, message: '' },
    pwd: { isInvalid: false, message: '' },
    pwd2: { isInvalid: false, message: '' },
    isValid: true
}
   
class Account extends React.Component {

    constructor(props) {
        super(props);
        this.showgoogle = true;
        this.showfb = true;
        this.showpwds = true;
        this.validations = { ...INITIAL_VALIDATORS };
        this.submitted = false;
        this.state = {
            name: this.props.authUser.displayName || '',
            lang: this.props.authUser.lang || '',
            pwd: '',
            pwd2: '',
            error: null,
            success: false,
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value }, () => {
            if (this.submitted) {
                this.validateForm();
            }
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true, error: null });
        this.submitted = true;

        const { name, pwd, lang } = this.state;
        const isValid = this.validateForm();

        if (isValid) {
            const userinfo = {
                name: name,
                pwd: pwd,
                lang: lang
            }
            
            auth.currentUser.getIdToken().then(idtoken => {
                userinfo.idtoken = idtoken;
                return fetch(API + "/users", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(userinfo)
                })
            })
            .then(() => {
                AuthService.userdata.displayName = name;
                AuthService.userdata.lang = lang;
                EventBus.dispatch("userdata-changed", true);
            })
            .then(() => {
                this.props.history.push(routes.WEBSITES);
            })
            .catch(error => {
                this.setState({ error, loading: false });
            });
        } else {
            this.setState({ loading: false });
        }
    }

    connectGoogle = () => {
        const { t } = this.props;
        this.setState({ error: null });
        AuthService.linkWithGoogle().then(() => {
            this.showgoogle = false;
            const success = {
                message: t("social_link_success")
            }
            this.setState({ success });
        })
        .catch(error => {
            this.setState({ error });
        });
    }

    connectFacebook = () => {
        const { t } = this.props;
        this.setState({ error: null });
        AuthService.linkWithFacebook().then(() => {
            this.showfb = false;
            const success = {
                message: t("social_link_success")
            }
            this.setState({ success });
        })
        .catch(error => {
            this.setState({ error });
        });
    }

    validateForm = () => {
        const { pwd, pwd2, name } = this.state;
        const { t } = this.props;
        let validators = { ...INITIAL_VALIDATORS }
        const validations = validateAccountPage(pwd, pwd2, name, validators, t);
        this.validations = validations;
        this.setState({ validated: true });
        return validations.isValid;
    }

    componentWillMount() {

        // open side menu
		EventBus.dispatch("sidemenu-changed", true);


        const { authUser } = this.props;
        if (authUser) {
            authUser.providerData.forEach(element => {
                if (element.providerId === 'google.com') {
                    this.showgoogle = false;
                    this.showpwds = false;
                }
                if (element.providerId === 'facebook.com') {
                    this.showfb = false;
                    this.showpwds = false;
                }
            });            
        }
    }

    render() {

        const { pwd, pwd2, error, success, name, lang, loading } = this.state;
        const { t } = this.props;

        return (
            <div className="card">
                <div className="card-header bg-secondary-dark highlight text-white">
                    {t("edit_account_info")}
                </div>
                <div className="card-body">
                    { this.showgoogle &&
                        <button className="btn btn-social btn-google btn-sm mr-2" onClick={this.connectGoogle} disabled={loading}>
                            <div className="social-icon-google"></div>
                            {t("connect_google_account")}
                        </button>
                    }
                    { /*this.showfb &&
                        <button className="btn btn-social btn-facebook btn-sm" onClick={this.connectFacebook} disabled={loading}>
                            <div className="d-flex align-items-center justify-content-center">
                                <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                            </div>
                            {t("connect_fb_account")}
                        </button>
                    */}
                    <hr/>
                    <form onSubmit={this.onSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">{t("name")}</label>
                            <input id="name" name="name" onChange={this.handleInputChange} type="text" className="form-control" value={name} />
                            { this.validations.name.isInvalid  && <span className="srv-validation-message slideanim slide">{this.validations.name.message}</span> }
                        </div>

                        <div className="form-group">
                            <label htmlFor="lang">{t("defaultlang")}</label>
                            <select id="lang" className="custom-select" name="lang" onChange={this.handleInputChange} value={lang}>
                                <option value="fi">{t("finnish")}</option>
                                <option value="en">{t("english")}</option>
                            </select>
                        </div>
                        
                        { this.showpwds &&
                            <div>
                                <h4>{t("change_password")}</h4>
                                <div className="form-group">
                                    <label htmlFor="pwd">{t("password")}</label>
                                    <input id="pwd" name="pwd" onChange={this.handleInputChange} type="password" className="form-control" value={pwd} />
                                    { this.validations.pwd.isInvalid  && <span className="srv-validation-message slideanim slide">{this.validations.pwd.message}</span> }
                                </div>
                                <div className="form-group">
                                    <label htmlFor="pwd2">{t("password_again")}</label>
                                    <input id="pwd2" name="pwd2" onChange={this.handleInputChange} type="password" className="form-control" value={pwd2} />
                                    { this.validations.pwd2.isInvalid  && <span className="srv-validation-message slideanim slide">{this.validations.pwd2.message}</span> }
                                    <small className="form-text"><strong>{t("note")} </strong> {t("password_info")}</small>
                                </div>
                            </div>
                        }   
                        { error && 
                            <div className="alert alert-danger mt-3">{error.message}</div> 
                        }
                        { success &&
                            <div className="alert alert-success mt-3">{success.message}</div> 
                        }
						<hr />
                        <button className="btn btn-secondary btn-lg mr-1" disabled={loading}>{t("save")}</button>
                        <button className="btn btn-link" type="button" style={{float: "right"}} onClick={() => this.props.history.push(routes.WEBSITES)}>{t("cancel")}</button> 
                    </form>
                </div>
            </div>
        );
    }
}

export default withAuthorization(withRouter(withTranslation("Authentication")(AccountPage)));