import React from 'react';
import AuthService from '../components/Authentication/Auth';
import { Redirect } from 'react-router-dom';
import * as routes from '../config/routes';

const withAuthorization = (Component) => {
    return function WithAuthorization({ ...props }) {
        const authUser = AuthService.getuser();
        
        if (AuthService.isAuthenticated && authUser) {
            return <Component {...props} />
        }
        
        return <Redirect to={routes.SIGN_IN} />
    }
}

export default withAuthorization;