import React from 'react';
import { Link, withRouter } from 'react-router-dom'
import BillingForm from './BillingForm';
import * as routes from '../../config/routes';
import * as prices from '../../config/prices';
import { API } from '../../config/config';
import { auth } from '../../firebase/firebase';
import Users from './Users';
import ConfirmModal from './ConfirmModal';
import SubHeader from '../../includes/SubHeader';
import defPageImg from '../../images/default.jpg';
import withAuthorization from '../withAuthorization';
import AuthService from '../Authentication/Auth';
import { withTranslation } from 'react-i18next';
import { checkNum, getPlanPrice, getExistingDomains } from '../../helpers/Helper';
import Plans from './Plans';
import DatePicker from 'react-date-picker';
import punycode from 'punycode/';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SendingDomain from './SendingDomain';

const INITIAL_VALIDATORS = {
	billingname: { isInvalid: false, message: '' },
	billingcompany: { isInvalid: false, message: '' },
	billingemail: { isInvalid: false, message: '' },
	billingaddress: { isInvalid: false, message: '' },
	city: { isInvalid: false, message: '' },
	zip: { isInvalid: false, message: '' },
	einvoice_address: { isInvalid: false, message: '' },
	einvoice_operator: { isInvalid: false, message: '' },
	maindomain: { isInvalid: false, message: '' },
	newdomain: { isInvalid: false, message: '' },
	customdomains: { isInvalid: false, message: '' },
	isValid: true
}

// Set default values for new fields
const DEFAULT_SITE = {
	title: '', 
	name: '',
	expires: null,
	published: false,
	domain: '',
	pendingdomains: '',
	maindomain: '',
	model: 'blanko',
	theme: '',
	manager: '',
	account: 'M',
	billing: 'bill',
	billingmonth: '',
	billingname: '',
	billingcompany: '',
	billingemail: '',
	billingperiod: '12',
	sendingmethod: 'online',
	billingaddress: '',
	billingaddress2: '',
	city: '',
	zip: '',
	reference: '',
	einvoice_address: '',
	einvoice_operator: '',
	monthlyprice: prices.DEFAULT_PROFIT_M,
	useagreedprice: false,
	agreedprice: 0,
	nocommission: true,
	linkedsite: null
}

class Site extends React.Component {

	constructor(props) {
		super(props);
		this.validations = { ...INITIAL_VALIDATORS };
		this.submitted = true;
		this.allowcopy = false;
		this.state = {     
			site: { ...DEFAULT_SITE },
			tags: this.props.item.domains.split(",") || [],
			planprice: 0,
			totalprice: 0,
			langs: [],
			users: {},
			error: null,
			success: null,
			showaccessform: false,            
			confirm: false,
			loading: false,
			tab: 1,
			actionsDropdown: false,
			sendrequestemail: '',
			isEmailInvalid: false,
			isEmailSended: false
			
		}
	}

	onSubmit = (e) => {
		e.preventDefault();
		this.setState({ loading: true });
		this.submitted = true;

		let error = {};
		let success = {};
		const { t } = this.props;
		const valid = this.validateForm();
		if (valid) {
			let { site } = this.state;
			error.message = t("error.unknown")
			success.message = t("saveok")

			auth.currentUser.getIdToken().then(idtoken => {
				site.idtoken = idtoken;
				site.userid = AuthService.userdata.bonsaituser.id;
				fetch(API + "/sites/update?id=" + site.id, {
					headers:{
						'Content-Type': 'application/json',
					},
					method: 'POST',
					body: JSON.stringify(site),
				})
				.then(results => results.json())
				.then(data => {
					if (data.success) {
						this.setState({ success, loading: false })
					} else {
						this.handleErrors(data);
					}
				})
				.catch(() => {
					this.setState({ error, loading: false })
				});
			});
		} else {
			error.message = t("error.validate");
			this.setState({ error, loading: false });
		}
	}

	onActivateDomains = () => {
		auth.currentUser.getIdToken().then(idtoken => {
			const json = { 
				idtoken: idtoken,
				siteid: this.state.site.id
			};
			fetch(API + "/sites/activatedomains", {
				headers: {
					'Content-Type': 'application/json',
				},
				method: 'POST',
				body: JSON.stringify(json)
			})
			.then(results => results.json())
			.then(data => {
				if (data.success) {
				} else {
					this.handleErrors(data);
				}
			})
		});
	}

	onCopySite = (e) => {
		let { id, name, title, domains } = this.props.item;
		const { history } = this.props;
		
		history.push({ 
			pathname: routes.NEW_WEBSITE,
			state: {
				copyid: id,
				copyname: name,
				copytitle: title,
				copydomains: domains
			}
		});
	}

	onDeleteSite = () => {

		this.setState({ confirm: !this.state.confirm });

		const { t } = this.props;
		const { id } = this.state.site;
		
		auth.currentUser.getIdToken().then(idtoken => {
			const json = { idtoken: idtoken }
			fetch(API + "/sites?id=" + id, {
				method: 'DELETE',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(json)
			})
			.then(results => results.json())
			.then(sitedeleted => {
				if (sitedeleted.success) {
					this.props.history.push(routes.WEBSITES);
				} else {
					let error = {};
					error.message = t("error.unknown");
					this.setState({ error });
				}
			});
		});
	}


	fetchLangs = (siteid) => {
		// fetch site langs
		auth.currentUser.getIdToken().then(idtoken => {
			const json = { idtoken: idtoken }
			fetch(API + "/listlangs?id=" + siteid, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(json)
			})
			.then(results => results.json())
			.then(response => this.setState({ langs: response.langs }))
			.catch(error => console.log(error));
		});
	}

	countLetter = (str, letter) => {
		var letter_Count = 0;
		for (var position = 0; position < str.length; position++) {
			if (str.charAt(position) == letter) {
				letter_Count += 1;
			}
		}
		return letter_Count;
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		let copy = {...this.state.site};
		copy[name] = value;

		if (this.state.site.billing !== copy.billing || this.state.site.sendingmethod !== copy.sendingmethod) {
			this.resetValidators();
		}

		// if billingmonth change period also
		if (name=='billingmonth') {
			if (this.countLetter(value, '/')==0) {
				copy.billingperiod = '12'
			} else if (this.countLetter(value, '/')==1) {
				copy.billingperiod = '6'
			} else if (this.countLetter(value, '/')==3) {
				copy.billingperiod = '3'
			} else if (this.countLetter(value, '/')>4) {
				copy.billingperiod = '1'
			}
		}

		this.setState({ site: copy }, () => {
			if (this.submitted) {
				this.validateForm();
			}
			if (name === 'monthlyprice') {
				this.handleMonthlyPrice(copy.account);
			}
			/*
			if (name === 'nocommission') {
				copy["monthlyprice"] = checkDefaultProfit(copy.account, prices);
				this.setState({ site: copy });
				this.handleMonthlyPrice(copy.account);
			}
			*/
		});
	}

	handleAgreedPriceInputChange = (event) => {
		const target = event.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		let copy = {...this.state.site};

		value = checkNum(value);

		copy[name] = value;

		this.setState({ site: copy }, () => {
		});
	}

	handleAnyPriceInputChange = (event) => {
		const target = event.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		let copy = {...this.state.site};
		//value = checkNum(value);
		copy[name] = value;
		this.setState({ site: copy }, () => {
		});
	}

	handleDomainInputChange = (event, index) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		let copy = {...this.state.site};
		//console.log(copy['additionaldomains'][index])
		let ad = copy['additionaldomains'][index]
		if (ad) {
			copy['additionaldomains'][index][name] = value;
		}

		this.setState({ site: copy }, () => {
		});
	}

	onRemoveDomains = (index) => {
		let copy = {...this.state.site};
		let ad = copy['additionaldomains'][index]

		const { t } = this.props;
		if (window.confirm(t("domain.deleteconfirm") + ' ' + ad.domain + '?')) {

			const authUser = AuthService.getuser();
			const { id } = authUser.bonsaituser;

			auth.currentUser.getIdToken().then(idtoken => {
				const json = { 
					idtoken: idtoken,
					siteid: this.state.site.id,
					removedomain: ad.domain
				}
				fetch(API + `/sites/removedomain?siteid=${this.state.site.id}&userid=${id}`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(json)
				})
				.then(results => results.json())
				.then(result => {
					console.log(result)
					if (ad) {
						//copy['additionaldomains'].splice(index, 1)
					}
					copy['additionaldomains'] = result.additionaldomains
					this.setState({ site: copy }, () => {
					});
				})
				.catch(error => (error));
			});


			
		}
	}

	changeTab = (e, tabindex) => {
		e.preventDefault();
		this.setState({ tab: tabindex });
	}

	handlePlan = (plan) => {
		//const authUser = AuthService.getuser();
		//const role = authUser.bonsaituser.role;
		
		let copy = {...this.state.site};
		copy["account"] = plan;
		//copy["monthlyprice"] = checkDefaultProfit(plan, prices);
		//copy["agreedprice"] = getPlanPrice(role, plan, prices);

		this.setState({ site: copy }, () => {
			this.handleMonthlyPrice(plan);
		});
	}


	handleExpire = value => {
		let copy = { ...this.state.site };
		copy["expires"] = value;
		this.setState({ site: copy });
	}

	handleMonthlyPrice = (plan) => {
		const authUser = AuthService.getuser();
		const role = authUser.bonsaituser.role;
		const { monthlyprice } = this.state.site;

		let price = getPlanPrice(role, plan, prices);
		let total = 0;

		total = price + checkNum(monthlyprice);
		this.setState({ 
			planprice: price,
			totalprice: total
		});
	}

	handleTags = (domains) => {
		let trimmeddomains = [];

		domains.forEach(element => {
			const trimmed = element.replace(/\s/g,'');
			trimmeddomains.push(trimmed);
		});

		this.setState({ tags: trimmeddomains }, () => {
			let domainstr = "";
			for (let i = 0; i < trimmeddomains.length; i++) {
				domainstr += trimmeddomains[i];
				if (trimmeddomains[i + 1] != null) {
					domainstr += ",";
				}
			}
	
			let copy = {...this.state.site};
			copy["domains"] = domainstr;
			this.setState({ site: copy });
		});
	}

	 
	handleErrors = (result) => {
		const { t } = this.props;
		let error = {};

		switch (result.message) {
			case 'Domain already taken':
				error.message = t("domain_already_taken");
				error.existingdomains = result.existingdomains;
				break;
			default:
				error.message = t("error.unknown")
		}

		this.setState({ loading: false, error });
	}
	
	validateForm = () => {
		let validators = { ...INITIAL_VALIDATORS }
		const { t } = this.props;
		//const validations = validateBilling(validators, this.state.site, t);
		//this.validations = validations;
		const validations = { ...INITIAL_VALIDATORS }; 
		this.validations = validations;
		this.setState({ validated: true });
		return validations.isValid;
	}

	resetValidators = () => {
		this.validations = { ...INITIAL_VALIDATORS };
		this.setState({ error: null });
	}

	componentDidMount() {
		this.submitted = false;
		let defaultsite = { ...this.state.site };

		defaultsite["billingemail"] = AuthService.userdata.email || '';
		defaultsite["billingname"] = AuthService.userdata.displayName || '';
		defaultsite["theme"] = AuthService.userdata.bonsaituser.themes[0].code || '';
		
		let siteobject = Object.assign({...defaultsite }, this.props.item);
		this.setState({ site: siteobject }, () => {
			const { id, account } = this.state.site;
			this.fetchLangs(id);
			this.handleMonthlyPrice(account);
			
		});
	}

	componentWillMount() {
		const authUser = AuthService.getuser();
		if (AuthService.isAuthenticated && (authUser.bonsaituser.role === 'admin' || authUser.bonsaituser.role === 'manager')) {
			this.allowcopy = true;
		}
	}

	// Togglers
	toggleAccessForm = () => {
		this.setState(({ showaccessform }) => ({
			showaccessform: !showaccessform
		}));
	}

	toggleConfirm = () => {
		this.setState(({ confirm }) => ({
			confirm: !confirm
		}));
	}

	setDropdownOpen = (e) => {
		this.setState(({ actionsDropdown }) => ({
			actionsDropdown: !actionsDropdown
		}));
	}

	getActiveDomain = (site) => {
		let d = ""
		if (site.maindomain) {
			d = site.maindomain
		} else {
			if (site.domains) {
				let dlist = site.domains.split(",")
				d = dlist[dlist.length-1]
				if (d) {
					d = d.replace("www.", "")
				}
			}
		}
		
		return d
	}

	onPreviewUrl = (url) => {
		window.open(url, "_blank")
	}

	onToggleDouble = (e) => {
		let copy = {...this.state.site};
		if (copy.isdouble===undefined) {
			copy.isdouble = true;
		} else {
			copy.isdouble = !copy.isdouble;
		}

		auth.currentUser.getIdToken().then(idtoken => {
			const json = { 
				idtoken: idtoken,
				siteid: this.state.site.id,
				isdouble: copy.isdouble 
			}
			fetch(API + "/sites/setdoublesite?id=" + this.state.site.id, {
				headers:{
					'Content-Type': 'application/json',
				},
				method: 'POST',
				body: JSON.stringify(json),
			})
			.then(results => results.json())
			.then(data => {
				if (data.success) {
					this.setState({ site: copy });
				}
			})
			.catch(() => {
				//this.setState({ error, loading: false })
			});
		});
		
	}

	handleImageError = (e) => {
		return e.target.src = defPageImg;
	}

	handleRequestEmailInputChange = (event) => {
		const target = event.target;
		this.setState({ sendrequestemail: target.value });
	}

	onSendBillingInfoRequest = (e) => {
		e.preventDefault();
        const { sendrequestemail, isEmailInvalid, site } = this.state;

		let isvalid = true;
		
		if (!sendrequestemail) {
			this.setState({ isEmailInvalid: true });
			isvalid = false;
		}

		const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (sendrequestemail && !mailformat.test(sendrequestemail)) {
			this.setState({ isEmailInvalid: true });
			isvalid = false;
		}

		if (isvalid) {
			auth.currentUser.getIdToken().then(idtoken => {
				const formData = {
					'email': sendrequestemail,
					'idtoken': idtoken,
					'userid': AuthService.userdata.bonsaituser.id
				}

				fetch(API + "/sites/sendbillingrequest?id=" + site.id, {
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					method: 'POST',
					body: JSON.stringify(formData)
				})
				.then(results => results.json())
				.then(result => { 
					if (result.success) {
						this.setState({ 
							sendrequestemail: '',
							isEmailInvalid: false,
							isEmailSended: true
						});
					}
				})
				.catch(error => console.log(error));
			});
		}
	}

	render() {

		if(!this.state.site.id) {
			return <div></div>
		}

		const authUser = AuthService.getuser();
		const { site, error, success, loading, tags, showaccessform, langs, confirm, domainconfirm, planprice, totalprice, tab, adddomainconfirm, actionsDropdown, sendrequestemail, isEmailInvalid, isEmailSended, isDomainInvalid, senderdomain} = this.state
		const { t } = this.props;
		//const inputprops = { placeholder: t("add_domains") }

		const isInvalid = 
			site.title === '' || 
			site.title.length < 3 ||
			site.account === '' || 
			site.account === null ||
			tags.length === 0 ||
			loading;

		return (
			<React.Fragment>
				<ConfirmModal sitemodel={site} toggleConfirm={this.toggleConfirm} onDeleteSite={this.onDeleteSite} showconfirm={confirm} t={t}/>
				
				<SubHeader site={this.props.item} authUser={authUser} />
				<div className="container">

					<div className="row">
						<div className="col-md-12">
							<div className="card mb-4">
								<div className="card-body">
									<div className="row ">
										<div className="col-md-3">
											
											<div className="frame-browser mb-4">
												<div className="frame">
													<span className="red"></span>
													<span className="yellow"></span>
													<span className="green"></span>
												</div>
												<img src={"https://www.bonsait.fi/apps/pageimg.groovy?site=" + site.id} onError={this.handleImageError} onClick={() => this.onPreviewUrl('http://' + site.maindomain)} className="img-fluid d-block" alt={site.name} />
											</div>
											
										</div>
										<div className="col-md-7">
											{ site.isdouble
												? <h3>Bonsait-{site.account} <span className="info-text">{t("isdouble")}</span></h3>								
												: <h3>Bonsait-{site.account}</h3>
											}
											<p>{this.getActiveDomain(site)}</p>
											<div className="py-1">
												<button className="btn btn-outline-primary mr-2" onClick={() => this.onPreviewUrl('http://' + site.maindomain)}>{t("actions.preview")}</button>
												<button className="btn btn-outline-primary mr-2" onClick={this.onCopySite}>{t("actions.copy")}</button>
												{(site.isdouble)
													? <button className="btn btn-outline-primary" onClick={this.onToggleDouble}>{t("actions.undouble")}</button>
													: <button className="btn btn-outline-primary" onClick={this.onToggleDouble}>{t("actions.markdouble")}</button>
												}
												
											</div>
											
										</div>
										<div className="col-md-2">
											<div className="mb-4 text-right">
												<button className="btn btn-outline-danger" onClick={this.toggleConfirm}>{t("delete")}</button>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-md-12">
							
						</div>
					</div>

					<div className="row">
						<div className="col-md-12">
							<div className="card site-card mb-4">
								<div className="card-header">
									<ul className="nav nav-pills nav-fill card-header-pills">
										<li className="nav-item">
											<a className={tab===1 ? "nav-link active bg-secondary-dark text-white highlight" : "nav-link bg-white2 text-secondary-dark"} href="#" onClick={(e) => this.changeTab(e, 1)}>{t("sitemenu.general")}</a>
										</li>
										<li className="nav-item">
											<a className={tab===2 ? "nav-link active bg-secondary-dark text-white highlight" : "nav-link bg-white2 text-secondary-dark"} href="#" onClick={(e) => this.changeTab(e, 2)}>{t("sitemenu.plan")}</a>
										</li>
										<li className="nav-item">
											<a className={tab===3 ? "nav-link active bg-secondary-dark text-white highlight" : "nav-link bg-white2 text-secondary-dark"} href="#" onClick={(e) => this.changeTab(e, 3)}>{t("sitemenu.billing")}</a>
										</li>
										<li className="nav-item">
											<a className={tab===4 ? "nav-link active bg-secondary-dark text-white highlight" : "nav-link bg-white2 text-secondary-dark"} href="#" onClick={(e) => this.changeTab(e, 4)}>{t("sitemenu.email")}</a>
										</li>
										<li className="nav-item">
											<a className={tab===5 ? "nav-link active bg-secondary-dark text-white highlight" : "nav-link bg-white2 text-secondary-dark"} href="#" onClick={(e) => this.changeTab(e, 5)}>{t("sitemenu.users")}</a>
										</li>
									</ul>						
								</div>
								<div className="card-body">
								
									<form className="site-admin-form" onSubmit={this.onSubmit}>

										{ error &&
											<div className="alert alert-danger mt-3">
												{error.message + " "}
												{getExistingDomains(error.existingdomains)}
											</div>
										}

										{ success &&
											<div className="alert alert-success mt-3">
												{success.message + " "}
											</div>
										}

										{tab===1 &&

											<React.Fragment>

												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label htmlFor="title">{t("sitename")}</label>
															<input id="title" name="title" value={site.title} onChange={this.handleInputChange} type="text" className="form-control" />
														</div>
													</div>
													<div className="col-md-6 d-flex align-items-center">
														<p className="d-block pt-4">{t("sitenameinfo")}</p>
													</div>
												</div>

												<h4 className="mt-4">{t("domain.title")}</h4>

												{(site.published==="true" || site.published) ?
													<React.Fragment>
														<div className="form-group">
															<label htmlFor="maindomain">{t("domain.main")}</label>
															<div className="input-group mb-3">
																<div className="input-group-prepend">
																	<span className="input-group-text" id="maindomain-addon-url">https://</span>
																</div>
																<input id="maindomain" name="maindomain" value={site.maindomain} onChange={this.handleInputChange} type="text" className="form-control" />
															</div>
															
														</div>
														<p>{t("domain.additionalsinfo")}</p>

														{(site.additionaldomains && site.additionaldomains.length > 0) &&
															<div className="additionaldomains">

																<div className="row mb-1 ">
																	<div className="col-md-3">{t("domain.additionalslabel")}</div>
																	<div className="col-md-1">{t("domain.additionalsstatus")}</div>
																	<div className="col-md-3">{t("domain.additionalsaction")}</div>
																	<div className="col-md-4">{t("domain.additionalstarget")}</div>
																	<div className="col-md-1">{t("domain.additionalsdelete")}</div>
																</div>
																<hr />
																{site.additionaldomains.map((ad, index) => {
																	return (
																		<div key={"additionaldomain" + index} className="row mb-2">
																			<div className="col-md-3">
																				{(ad.domain===punycode.toUnicode(ad.domain)) 
																				? <>{ad.domain}</>
																				: <>{punycode.toUnicode(ad.domain)} <span className="text-black-50 font-italic">{ad.domain}</span></>
																				}
																				</div>
																			<div className="col-md-1">
																				{ad.status == 'active'
																					? <span className="badge badge-secondary">{t("domain.additionalsstatus_active")}</span>
																					: <span className="badge badge-warning">{t("domain.additionalsstatus_pending")}</span>
																				}
																			</div>
																			<div className="col-md-3">
																				<select id={"site-form-domain-action-" + index} className="custom-select" name="action" value={ad.action} onChange={(e) => this.handleDomainInputChange(e, index)}>
																					<option value="main">{t("domain.additionalsaction_redirecttomain")}</option>
																					<option value="url">{t("domain.additionalsaction_redirect")}</option>
																					<option value="site">{t("domain.additionalsaction_site")}</option>
																				</select>
																			</div>
																			<div className="col-md-4">
																				<div className="form-group">
																					{ad.action == 'main'
																						? <input name="to" value={"https://" + site.maindomain} onChange={(e) => this.handleDomainInputChange(e, index)} type="text" readOnly className="form-control-plaintext" />
																						: ad.action == 'site'
																							? <input name="to" value={t("domain.additionalsaction_site_to")} type="text" readOnly className="form-control-plaintext" />
																						: <input name="to" value={ad.to} onChange={(e) => this.handleDomainInputChange(e, index)} type="text" className="form-control" />
																					}
																				</div>
																			</div>
																			<div className="col-md-1"><button type="button" className="btn btn-default btn-sm" onClick={() => this.onRemoveDomains(index)}>{t("delete")}</button></div>
																		</div>
																	)
																})}
															</div>
														}

														{/* <button type="button" className="btn btn-default btn-sm mt-3" onClick={this.toggleAddDomainConfirm}>{t("domain.add")}</button> */}
														<Link exact="true" to={routes.CONNECT_DOMAIN_PLAIN + `/${site.id}`} className="btn btn-default btn-sm mt-3">{t("domain.add")}</Link>

													</React.Fragment>
												:
													<React.Fragment>
														<p>{t("domain.unpublished")}</p>
														<div className="bg-light-gray px-3 py-3 mb-3">
															<p className="mb-0">https://{this.getActiveDomain(site)}</p>
														</div>
													</React.Fragment>
												}

												{(authUser.bonsaituser.role && authUser.bonsaituser.role !== "user" || authUser.bonsaituser.manager && authUser.bonsaituser.manager!=="" ) &&
												<React.Fragment>
													<hr />
													<div className="alert alert-warning mt-4" role="alert">
														<h3>{t("adminthemestitle")}</h3>
														{ authUser.bonsaituser.themes &&
															<React.Fragment>
																<div className="form-group">
																	<label htmlFor="theme">{t("admintheme")}</label>
																	<select id="site-form-admintheme" className="custom-select" name="theme" value={site.theme} onChange={this.handleInputChange}>
																		{ authUser.bonsaituser.themes &&
																			authUser.bonsaituser.themes.map((theme, index) => {
																				return (
																					<option key={index} value={theme.name}>{theme.name}</option>
																				)
																			})
																		}
																	</select>
																</div>
															</React.Fragment>
														}

														{ authUser.bonsaituser.managers &&
															<div className="form-group">
																<label htmlFor="manager">{t("manager")}</label>
																<select id="site-form-manager" className="custom-select" name="manager" value={site.manager || ''} onChange={this.handleInputChange}>
																	<option value="">{t("notchosen")}</option>
																	{ authUser.bonsaituser.managers.map((manager, index) => {
																			return (
																				<option key={index} value={manager.id}>{manager.company}</option>
																			)
																		})
																	}
																</select>
															</div>
														}
													</div>
												</React.Fragment>
												}
												
											</React.Fragment>

										}

										{tab===2 &&
											<React.Fragment>

												<h4>{t("plan.title")}</h4>

												{(authUser.bonsaituser.role && authUser.bonsaituser.role !== "user" || authUser.bonsaituser.manager && authUser.bonsaituser.manager!=="" ) &&
												<div className="alert alert-warning mt-4" role="alert">
													<div className="form-group">
														<label htmlFor="publishedstart">{t("expires")}</label>
														<DatePicker onChange={this.handleExpire} className="bonsait-datepicker" locale="fi-FI" value={site.expire} tileClassName="bonsait-datepicker-tile" />
													</div>
												</div>
												}

												<Plans handlePlan={this.handlePlan} account={site.account} authUser={authUser} t={t} prices={prices} />
												
												{authUser.bonsaituser.role && authUser.bonsaituser.role !== "user" &&
												<div className="alert alert-warning mb-4" role="alert">

													<div className="form-group">
														<div className="custom-control custom-checkbox">
															<input type="checkbox" className="custom-control-input" id="useagreedprice" name="useagreedprice" checked={site.useagreedprice} onChange={this.handleInputChange} />
															<label className="custom-control-label" htmlFor="useagreedprice">{t("billing.useagreedprice")}</label>
														</div>
													</div>

													{site.useagreedprice &&
														<div className="form-group">
															<label htmlFor="title">{t("billing.agreedprice")}</label>
															<div className="row">
																<div className="col-md-4 col-sm-4">
																	<div className="input-group">
																		<div className="input-group-prepend">
																			<span className="input-group-text bg-light-gray">€</span>
																		</div>
																		<input id="agreedprice" className="form-control" type="text" value={site.agreedprice} name="agreedprice" onChange={this.handleAgreedPriceInputChange} />
																		<div className="input-group-append">
																			<span className="input-group-text bg-light-gray">{t("billing.agreedprice_kk")}</span>
																		</div>
																	</div>
																</div>
																<div className="col-md-8 col-sm-8">
																</div>
															</div>
															<small className="form-text text-muted">{t("billing.agreedprice_info")}</small>
														</div>
													}
												</div>
												}
											</React.Fragment>
										}

										{tab===3 &&
											<React.Fragment>

												{(authUser.bonsaituser.role && authUser.bonsaituser.role !== "user" || authUser.bonsaituser.manager && authUser.bonsaituser.manager!=="" ) &&
												<div className="alert alert-warning mb-4" role="alert">

													<p>{t('billing.send_request_title')}</p>

													<div className="form-group">
														<div className="input-group">
															<input type="email" className={isEmailInvalid ? "form-control is-invalid" : "form-control"} autoComplete="off" name="sendrequestemail" onChange={this.handleRequestEmailInputChange} value={sendrequestemail} />
															<div className="input-group-append">
																<button className="btn btn-primary btn-sm" type="button" onClick={this.onSendBillingInfoRequest}>{t("billing.send_request_btn")}</button>
															</div>
														</div>
														<small className="form-text text-muted">{t("billing.send_request_desc")}</small>
													</div>

													{isEmailSended &&
														<p>{t('billing.send_request_sent')}</p>
													}
													{/* 													<div className="form-group mb-4">
														<label htmlFor="linkedsite">{t("linkedsite")}</label>
														<select id="admin-form-linkedsite" className="custom-select" name="linkedsite" value={site.linkedsite} onChange={this.handleInputChange}>
															<option value="" disabled hidden>{t("nolinkedsite")}</option>
															<option value="1">{t("contractbilling.january")}</option>
															<option value="2">{t("contractbilling.february")}</option>
															<option value="3">{t("contractbilling.march")}</option>
															<option value="4">{t("contractbilling.april")}</option>
															<option value="5">{t("contractbilling.may")}</option>
															<option value="6">{t("contractbilling.june")}</option>
															<option value="7">{t("contractbilling.july")}</option>
															<option value="8">{t("contractbilling.august")}</option>
															<option value="9">{t("contractbilling.september")}</option>
															<option value="10">{t("contractbilling.october")}</option>
															<option value="11">{t("contractbilling.november")}</option>
															<option value="12">{t("contractbilling.december")}</option>
														</select>
														<small className="form-text text-muted">{t("linkedsite_desc")}</small>
													</div>
													*/}

												</div>
												}

												<BillingForm 
													t={t} 
													site={site}
													handleInputChange={this.handleInputChange}
													handleAnyPriceInputChange={this.handleAnyPriceInputChange}
													error={error}
													authUser={authUser}
													validations={this.validations}
													planprice={planprice}
													totalprice={totalprice}
													prices={prices}
												/>
											</React.Fragment>
										}

										{tab===4 &&
											<>
												<SendingDomain 
													siteid={site.id} 
													domains={site.senderdomains}
													t={t} 
													authUser={authUser} />
											</>
										}

										{tab===5 &&
											<React.Fragment>
												<h4>{t("admins.title")}</h4>
												<Users 
													toggleAccessForm={this.toggleAccessForm} 
													showaccessform={showaccessform} 
													siteid={site.id} t={t} 
													authUser={authUser}
												/>
											</React.Fragment>
										}
									
										<div>
											<hr/>
											<button className="btn btn-secondary btn-lg mr-2" type="submit" disabled={isInvalid}>{t("save")}</button>
											<Link exact="true" to={routes.WEBSITES} className="btn btn-link" style={{float: "right"}}>{t("cancel")}</Link>
										</div>

										{ error &&
											<div className="alert alert-danger mt-3">
												{error.message + " "}
												{getExistingDomains(error.existingdomains)}
											</div>
										}

										{ success &&
											<div className="alert alert-success mt-3">
												{success.message + " "}
											</div>
										}

									</form>

								</div>
							</div>
						</div>

					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withAuthorization(withRouter(withTranslation("Sites")(Site)));
